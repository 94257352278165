import React, { useState } from "react";
import { Grid, Box, Typography, Divider, Menu, MenuItem } from "@mui/material";
import styles from "./styles.module.css";
import {
  Attribution,
  // FavoriteBorder,
  // Language,
  // Restore,
  Segment,
} from "@mui/icons-material";

const SayfayaNavBar = () => {
  const navItems = [
    // { name: "Recently Viewed ", logo: <Restore sx={{ fontSize: "1rem" }} /> },
    // { name: "Favorites", logo: <FavoriteBorder sx={{ fontSize: "1rem" }} /> },
    // { name: "EN.₹ ", logo: <Language sx={{ fontSize: "1rem" }} /> },
    { name: "Login", logo: <Attribution sx={{ fontSize: "1rem" }} /> },
    { name: "Menu ", logo: <Segment sx={{ fontSize: "1rem" }} /> },
  ];

  // State to manage the dropdown menu
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box className={styles["home-container"]}>
        <Grid container spacing={2} justifyContent="flex-start">
          {/* First 2 empty grid columns */}
          <Grid item md={1} sx={{ marginLeft: 5 }}></Grid>

          {/* Logo column */}
          <Grid item md={1} sx={{ padding: "0 !important" }}>
            <img
              src="https://sayfaya.com/wp-content/uploads/2023/04/Sayfaya-crop.png"
              alt="logo"
            />
          </Grid>

          {/* 2 empty grid columns */}
          <Grid item md={7}></Grid>

          {/* 5 auto-width columns for names with logos */}
          {navItems.map((item, index) => (
            <Grid item key={index} md="auto">
              <Box display="flex" className={styles["nav_bar"]}>
                <Box mr={1} className={styles["nav_icon"]}>
                  {item.logo}
                </Box>
                {/* For the fifth item, add a button with a dropdown */}
                {index === 4 ? (
                  <>
                    <Box
                      onClick={handleClick}
                      className={styles["dropdown_btn"]}
                    >
                      {item.name}
                    </Box>
                    <Menu
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        // horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        // horizontal: "center",
                      }}
                    >
                      <MenuItem onClick={handleClose}>Sign In</MenuItem>
                      <MenuItem onClick={handleClose}>Sign Up</MenuItem>
                    </Menu>
                  </>
                ) : (
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "13px", color: "#171717" }}
                  >
                    {item.name}
                  </Typography>
                )}
              </Box>
            </Grid>
          ))}

          {/* Remaining space on the right side */}
          {/* <Grid item md></Grid> */}
        </Grid>
      </Box>

      {/* Divider below the grid */}
      <Divider style={{ marginTop: "20px" }} />
    </>
  );
};

export default SayfayaNavBar;
