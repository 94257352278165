import React from 'react';
import { Grid, Typography, Container, Divider } from '@mui/material';

const Detailing = () => {
  return (
    <Container >
      {/* "What is it?" Section */}
      <Grid container alignItems="center">
        {/* Image Section */}
        <Grid item xs={12}>
          <Typography variant="h3" gutterBottom>
            Detailing
          </Typography>
          <Divider gutterBottom/>
          <Typography mt={2} variant="h4" gutterBottom>
            Coming Soon !
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Detailing;
