import React from "react";
import Header from "src/components/header";
import styles from "./style.module.css";
import { Box } from "@mui/material";
import CleanerStepper from "./component/cleaner-stepper";

const RapidAutoCare = () => {
  return (
    <>
      <Header transparent={false} darkBackground={true} landingPage = {false}/>
      <Box sx={{ pt: 1 }}>
        <CleanerStepper styles={styles} />
      </Box>
    </>
  );
};

export default RapidAutoCare;
