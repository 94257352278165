import React, { useState } from "react";
import { TextField, Button } from "@mui/material";
import { authorizedPost } from "src/api/base";
import { ToastContainer } from "react-toastify";
import { showNotification } from "src/components/notification";

const ContactForm = ({ styles }) => {
  // State for form fields
  const [formData, setFormData] = useState({});

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission
  const handleSubmit = async(e) => {
    e.preventDefault();
    console.log("Form Data:", formData);

    try {
      await authorizedPost("/save-contact-us", formData);

      setFormData({
        "name":null,
        "email":null,
        "subject":null,
        "message":null
      });

      showNotification("Success", "Service Booking Successfully Registered.");
    } catch (error) {
      showNotification(
        "Error",
        error?.response?.data?.message ?? "Failed to send contact details.!"
      );
      console.log(error);
    }

   
  };


  return (
    <>      <ToastContainer />

    <div className={styles["formContainer"]}>
      <h1 className={styles["headerText"]}>Send Us a message</h1>
      <p className={styles["infoText"]} style={{ marginBottom: "50px" }}>
        Have a question or need help? Use the form below to contact us. We'll
        get back to you as soon as possible. Be sure to provide as much
        information as you can so we can assist you more effectively
      </p>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Name"
          variant="outlined"
          className={styles["inputField"]}
          name="name"
          value={formData.name}
          onChange={handleInputChange}
        />
        <TextField
          label="Email"
          variant="outlined"
          className={styles["inputField"]}
          name="email"
          value={formData.email}
          onChange={handleInputChange}
        />
        <TextField
          label="Subject"
          variant="outlined"
          className={styles["inputField"]}
          name="subject"
          value={formData.subject}
          onChange={handleInputChange}
        />
        <TextField
          label="Message"
          variant="outlined"
          multiline
          rows={4}
          className={styles["inputField"]}
          name="message"
          value={formData.message}
          onChange={handleInputChange}
        />
        <Button
          type="submit"
          variant="contained"
          style={{width: 150}}
        >
          Submit
        </Button>
      </form>
    </div>
    </>
  );
};

export default ContactForm;
