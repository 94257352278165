import * as React from "react";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import {
  calculateTotalPrice,
  getCarDataByType,
  getMatTypeDataByValue,
} from "../helpers";

const FormGrid = styled(Grid)(({ theme }) => ({
  margin: theme.spacing(2, 0),
}));

export default function ConfirmationScreen({
  data,
  setData,
  setIsPaymentDone,
  paymentInput,
}) {
  const { totalPrice, totalTip } = calculateTotalPrice(data);
  const { carType, carPrice } = getCarDataByType(data.selectedCar);

  React.useEffect(() => {
    setData((prevData) => ({
      ...prevData,
      total_amount: totalPrice,
      car_type: carType,
    }));
  }, []); // eslint-disable-line

  const handlePaymentMethodChange = (event) => {
    if (event.target.value === "pay_later") {
      setIsPaymentDone(true);
    } else {
      setIsPaymentDone(false);
    }
    setData((prevData) => ({
      ...prevData,
      paymentMethod: event.target.value,
    }));
  };

  return (
    <Stack spacing={2} mb={5}>
      <List disablePadding>
        {/* Car Type */}
        <ListItem sx={{ py: 1, px: 0 }}>
          <ListItemText primary="Car Type" secondary={carType} />
          <Typography variant="body2">$ {carPrice || "N/A"}</Typography>
        </ListItem>
        {/* Both Mats */}
        {data?.mats === "both" && (
          <ListItem sx={{ py: 1, px: 0 }}>
            <ListItemText
              primary={getMatTypeDataByValue(data?.mats).displayName}
            />
            <Typography variant="body2">
              $ {getMatTypeDataByValue(data?.mats).price}
            </Typography>
          </ListItem>
        )}

        {/* Conditionally render add-ons if any are selected */}
        {data.addOns.trunkCleaning || data.addOns.petHairRemoval ? (
          <>
            {data.addOns.petHairRemoval && (
              <ListItem sx={{ py: 1, px: 0 }}>
                <ListItemText primary="Pet Hair Removal" />
                <Typography variant="body2">$ 19.99</Typography>
              </ListItem>
            )}
            {data.addOns.trunkCleaning && (
              <ListItem sx={{ py: 1, px: 0 }}>
                <ListItemText primary="Trunk + Rear Glass (interior & exterior) Cleaning" />
                <Typography variant="body2">$ 9.99</Typography>
              </ListItem>
            )}
          </>
        ) : null}

        {/* Tip and Total */}
        {data?.tips && (
          <ListItem sx={{ py: 1, px: 0 }}>
            {data?.tips !== "custom" ? (
              <ListItemText primary={`Tip Percentage [${data.tips || ""}]`} />
            ) : (
              <ListItemText primary={`Tip Amount`} />
            )}
            <Typography variant="body2">$ {totalTip}</Typography>
          </ListItem>
        )}

        <ListItem sx={{ py: 1, px: 0 }}>
          <ListItemText primary="Total" />
          <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
            $ {totalPrice}
          </Typography>
        </ListItem>
      </List>
      <Divider />

      {/* Shipment and Payment Details */}
      <Stack
        direction="column"
        divider={<Divider flexItem />}
        spacing={2}
        sx={{ my: 2 }}
      >
        <div>
          <Typography variant="subtitle2" gutterBottom>
            Your details
          </Typography>
          <Typography
            gutterBottom
          >{`${data.firstName} ${data.lastName}`}</Typography>
          <Typography gutterBottom sx={{ color: "text.secondary" }}>
            {`${data.address1}, ${data.address2 ? `${data.address2} ` : ""}`}
          </Typography>
        </div>
        <div>
          <Typography variant="subtitle2" gutterBottom>
            Car and Add-on Details
          </Typography>
          <Grid container>
            <Stack
              direction="row"
              spacing={1}
              useFlexGap
              sx={{ width: "100%", mb: 1 }}
            >
              <Typography variant="body1" sx={{ color: "text.secondary" }}>
                Car Model:
              </Typography>
              <Typography variant="body2">{data.carMakeModel}</Typography>
            </Stack>
            <Stack
              direction="row"
              spacing={1}
              useFlexGap
              sx={{ width: "100%", mb: 1 }}
            >
              <Typography variant="body1" sx={{ color: "text.secondary" }}>
                Power Source:
              </Typography>
              <Typography variant="body2">
                {data.powerSource === "yes" ? "Available" : "Not Available"}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              spacing={1}
              useFlexGap
              sx={{ width: "100%", mb: 1 }}
            >
              <Typography variant="body1" sx={{ color: "text.secondary" }}>
                Mats Type:
              </Typography>
              <Typography variant="body2">
                {getMatTypeDataByValue(data?.mats).displayName}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              spacing={1}
              useFlexGap
              sx={{ width: "100%", mb: 1 }}
            >
              <Typography variant="body1" sx={{ color: "text.secondary" }}>
                Additional Info:
              </Typography>
              <Typography variant="body2">{data.additionalInfo}</Typography>
            </Stack>
          </Grid>
        </div>
        <div>
          <Typography variant="subtitle2" gutterBottom>
            Payment Method Detail
          </Typography>
          <Grid container>
            <FormGrid item xs={12}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="carCondition"
                  name="carCondition"
                  value={data.paymentMethod}
                  onChange={handlePaymentMethodChange}
                  row
                >
                  <FormControlLabel
                    value="pay_later"
                    control={<Radio />}
                    label="Pay Later"
                  />
                  <FormControlLabel
                    value="online"
                    control={<Radio />}
                    label="Pay Now"
                  />
                </RadioGroup>
              </FormControl>
            </FormGrid>
            {paymentInput}
          </Grid>
        </div>
      </Stack>
    </Stack>
  );
}
