import * as React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import { Facebook, Instagram } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
// import SitemarkIcon from './SitemarkIcon';

function Copyright() {
  return (
    <Typography variant="body2" sx={{ color: "text.secondary", mt: 1 }}>
      © 2018 - 2024 | Sayfaya | All Rights Reserved &nbsp;
      {new Date().getFullYear()}
    </Typography>
  );
}

export default function FooterSection() {
  const navigate = useNavigate();

  const handleFaqClick = (e) => {
    e.preventDefault();
    navigate("/about", { replace: true });
    setTimeout(() => {
      const faqElement = document.getElementById("faq-section");
      if (faqElement) {
        faqElement.scrollIntoView({ behavior: "smooth" });
      }
    }, 0); // Ensure it runs after navigation
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 4, sm: 8 },
        py: { xs: 8, sm: 10 },
        textAlign: { sm: "center", md: "left" },
        width: "86%",
        margin: "auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 4,
            minWidth: { xs: "100%", sm: "36%" },
          }}
        >
          <Box
            sx={{ width: { xs: "100%", sm: "100%" }, cursor: "pointer" }}
            onClick={() => navigate("/")}
          >
            <img
              src="/assets/final_logo.png"
              alt="logo"
              style={{ width: "120px", marginRight: "40px" }}
            />
            {/* <Typography
              variant="body2"
              gutterBottom
              sx={{ fontWeight: 600, mt: 2 }}
            >
              Join the newsletter
            </Typography> */}
            <Typography variant="body2" sx={{ color: "text.secondary", mb: 2 }}>
              Empowering and connecting our community
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: { xs: "none", sm: "flex" },
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: "medium" }}>
            Helpful Links
          </Typography>
          <Link color="text.secondary" variant="body2" href="/">
            Home
          </Link>

          <Link color="text.secondary" variant="body2" href="/about">
            FAQs
          </Link>
          <Link color="text.secondary" variant="body2" href="/contact-us">
            Contact us
          </Link>
          <Link color="text.secondary" variant="body2" onClick={handleFaqClick}>
            FAQs
          </Link>
        </Box>

        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="body2" sx={{ fontWeight: "medium", mb: 1 }}>
            Contact Us
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
            <Link
              target="_tab"
              href="https://www.google.com/maps/place/Halifax+Apartments+%E2%80%93+The+Plaza/@44.6491695,-63.5802127,17z/data=!4m6!3m5!1s0x4b5a232f8f4afedb:0x18ca0defa439a3c1!8m2!3d44.6491657!4d-63.5776378!16s%2Fg%2F11tfbtx0ld?entry=ttu"
            >
              1881 Brunswick street, PHI, Halifax, B3J 3L8
            </Link>
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
            Phone:{" "}
            <Link target="_tab" href="tel:+19029894724" color="inherit">
              (902) 989-4724
            </Link>
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
            E-Mail:{" "}
            <Link
              target="_tab"
              href="mailto:support@sayfaya.com"
              color="inherit"
            >
              support@sayfaya.com
            </Link>
          </Typography>
        </Grid>
        <Box
          sx={{
            display: { xs: "none", sm: "flex" },
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: "medium" }}>
            Company
          </Typography>

          <Link color="text.secondary" variant="body2" href="/">
            Book a service
          </Link>
          <Link
            color="text.secondary"
            variant="body2"
            href="/services/rapid-auto-care"
          >
            Rapid Auto Care
          </Link>
          <Link color="text.secondary" variant="body2" href="/become-cleaner">
            Become a partner
          </Link>
        </Box>
        {/* <Grid item xs={12} sm={6} md={3}>
          <Typography variant="body2" sx={{ fontWeight: "medium", mb: 1 }}>
            Get Our Mobile App
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Link href="#" sx={{ mb: 2 }}>
              <img
                src="https://sayfaya.com/wp-content/uploads/2024/06/apple-1024x304.png"
                alt="App Store"
                style={{ width: "140px" }}
              />
            </Link>
            <Link href="#">
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/78/Google_Play_Store_badge_EN.svg/1280px-Google_Play_Store_badge_EN.svg.png"
                alt="Google Play"
                style={{ width: "140px" }}
              />
            </Link>
          </Box>
        </Grid> */}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          pt: { xs: 4, sm: 8 },
          width: "100%",
          borderTop: "1px solid",
          borderColor: "divider",
        }}
      >
        <div>
          <Link color="text.secondary" variant="body2" href="/privacy-policy">
            Privacy Policy
          </Link>
          <Typography sx={{ display: "inline", mx: 0.5, opacity: 0.5 }}>
            &nbsp;•&nbsp;
          </Typography>
          <Link
            color="text.secondary"
            variant="body2"
            href="/terms-and-conditions"
          >
            Terms of Service
          </Link>
          <Copyright />
        </div>
        <Stack
          direction="row"
          spacing={1}
          useFlexGap
          sx={{ justifyContent: "left", color: "text.secondary" }}
        >
          {/* <IconButton
            color="inherit"
            size="small"
            href="https://www.facebook.com/profile.php?id=100091875851989"
            aria-label="GitHub"
            sx={{ alignSelf: "center" }}
          >
            <FacebookIcon />
          </IconButton> */}
          <IconButton
            color="inherit"
            size="small"
            href="https://www.instagram.com/sayfaya.co/"
            aria-label="Instagram"
            sx={{ alignSelf: "center" }}
          >
            <Instagram />
          </IconButton>
          <IconButton
            color="inherit"
            size="small"
            href="https://www.facebook.com/profile.php?id=100091875851989"
            aria-label="Facebook"
            sx={{ alignSelf: "center" }}
          >
            <Facebook />
          </IconButton>
        </Stack>
      </Box>
    </Box>
  );
}
