import React from "react";
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  Grid,
  Dialog,
  DialogContent,
} from "@mui/material";
import styles from "../style.module.css";
import { useNavigate } from "react-router-dom";

const BookingSuccessful = ({ bookingData, open, setShowConfirmation }) => {
  const navigate = useNavigate();

  const {
    slot_date,
    slot_time,
    bill_address_1,
    bill_phone,
    bill_email,
    car_type,
    car_model,
    total_amount,
    payment_method,
  } = bookingData;
  return (
    <Dialog open={open}>
      <DialogContent>
        <Box className={styles["confirmation-container"]}>
          <Box
            component="video"
            className={styles["check-gif"]}
            autoPlay
            loop
            muted
          >
            <source src="/assets/cleaner/Success.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </Box>

          <Typography className={styles["order-confirmation"]}>
            Your Booking has been confirmed
          </Typography>
          <Typography className={styles["thank-you"]}>
            Thank you for your Booking!
          </Typography>
          <Typography className={styles["order-info"]}>
            {/* Your booking id is <strong>#2372747</strong>.<br /> */}
            You'll get an email confirmation for your booking details.
          </Typography>

          <Card className={styles["order-details-card"]}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography variant="body2">
                    <strong>Slot Date:</strong> {slot_date}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2">
                    <strong>Slot Time:</strong> {slot_time}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2">
                    <strong>Billing Address 1:</strong> {bill_address_1}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2">
                    <strong>Phone:</strong> {bill_phone}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2">
                    <strong>Email:</strong> {bill_email}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2">
                    <strong>Car Type:</strong> {car_type}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2">
                    <strong>Car Model:</strong> {car_model}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2">
                    <strong>Total Amount:</strong> ${total_amount}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2">
                    <strong>Payment Method:</strong>{" "}
                    {payment_method === "pay_later"
                      ? "Pay Later"
                      : "Paid Online"}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <Button
            className={styles["go-home-btn"]}
            variant="contained"
            onClick={() => {
              navigate("/");
              setShowConfirmation(false);
            }}
          >
            Go to Home
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default BookingSuccessful;
