import React from "react";
import { Box, Grid, Divider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import MapLoader from "../../../components/mapinput/MapLoader";
import styles from "../../../components/search/style.module.css";
import { Search, CalendarMonth } from "@mui/icons-material";
import {getMinDate} from 'src/utils/helpers';

const FilterComponent = ({
  filters,
  handleLocationChange,
  handleDateChange,
}) => {
  const handleLocChange = (location) => {
    handleLocationChange(location);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Grid container spacing={2} alignItems="center">
        {/* First Grid: Search */}
        <Grid item xs={12} md={6}>
          <Box
            className={styles["searchBox"]}
            style={{ borderBottom: "1px solid #e3e3e3" }}
          >
            <Search className={styles["searchIcon"]} />
            <Box sx={{ width: "100%" }}>
              <Box className={styles["locationText"]}>Location</Box>
              <MapLoader
                className={styles["textField"]}
                style={{ border: "0", padding: "10px" }}
                placeholder="Halifax"
                defaultValue={filters?.filterLocation || ""}
                onChange={handleLocChange}
              />
            </Box>
          </Box>
        </Grid>

        {/* Second Grid: Date Picker */}
        <Grid item xs={12} md={6}>
          <Box className={styles[""]}>
            <Divider orientation="vertical" flexItem />
            <Box className={styles["peopleBox"]}>
              <CalendarMonth className={styles["peopleIcon"]} />

              <Box style={{ width: "100%" }}>
                <Box className={styles["peopleText"]}>Date </Box>
                <input
                  type="date"
                  name="date"
                  onClick={(e) => e.currentTarget.showPicker()}
                  value={filters?.filterDate || ""}
                  style={{
                    border: "none",
                    outline: "none",
                    padding: "10px",
                    width: "100%",
                  }}
                  onChange={handleDateChange}
                  min={getMinDate()}
                />
              </Box>
            </Box>
          </Box>
        </Grid>

        {/* Submit Button */}
        {/* <Grid item xs={12} md={2}>
          <Button
            variant="contained"
            color="primary"
            size="medium"  
            onClick={handleSubmit}
            fullWidth
            style={{marginTop:"15px"}}
          >
            Search
          </Button>
        </Grid> */}
      </Grid>
    </LocalizationProvider>
  );
};

export default FilterComponent;
