import React from "react";
import styles from "./styles.module.css";
import FooterSection from "src/pages/homepage/components/footer";
import BookingSection from "./components/bookingsection";
import SearchBar from "src/components/search";
import { Box, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Header from "src/components/header";
import { CAR_TYPE_DATA } from "src/pages/rapid-auto-care-landing/helpers";

const RapidAutoCare = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const selectedService = location.pathname.toLowerCase().includes("detailing")
    ? "detailing"
    : "rapid_auto_care";

  const scrollToPricingSection = () => {
    const pricingSection = document.getElementById("pricing-section");
    if (pricingSection) {
      pricingSection.scrollIntoView({
        behavior: "smooth", // Smooth scrolling effect
      });
    }
  };

  return (
    <>
      <Box
        className={styles["rapid-care-landing"]}
        sx={{
          height: { xs: "auto", sm: "auto", md: "calc(100vh - 56px)" },
          py: { xs: 4, md: 0 },
        }}
      >
        <Header darkBackground={true} />

        {/* Black Overlay */}
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 1,
          }}
        />

        {/* Content */}
        <Box
          sx={{
            position: "relative",
            zIndex: 2,
            px: { xs: 2, sm: 4 },
            textAlign: "center",
            height: { xs: "650px", sm: "auto" },
          }}
        >
          <Typography
            variant="h4"
            sx={{
              mb: 2,
              color: "white",
              fontWeight: "600",
              fontSize: { xs: "1.5rem", sm: "2rem", md: "2.5rem" },
            }}
          >
            Convenient and hassle-free car cleaning at your doorstep.
          </Typography>
          <Typography
            variant="h6"
            sx={{
              mb: 4,
              color: "white",
              fontSize: { xs: "1rem", sm: "1.25rem" },
              opacity: "0.65",
              fontWeight: "normal",
            }}
          >
            No time for cleaning your car? Let our platform connect you with
            local mobile cleaners.
          </Typography>

          <SearchBar selectedService={selectedService} />
          <Typography
            variant="h5"
            sx={{
              mb: 2,
              color: "#fafafa",
              fontWeight: "600",
            }}
          >
            Starts from <strike>{CAR_TYPE_DATA[0].originalPrice}</strike> → {CAR_TYPE_DATA[0].carPrice}
          </Typography>
          <Box
            className={styles["see-price-div"]}
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row", md: "row" },
            }}
          >
            <Box
              className={styles["see-price-btn"]}
              sx={{
                marginTop: { xs: "70px !important", sm: "40px !important" },
                marginLeft: { xs: "20px", md: 0 },
              }}
              onClick={scrollToPricingSection}
            >
              Check Pricing
            </Box>
            <Box
              className={styles["see-price-btn"]}
              sx={{
                marginLeft: "20px",
                marginTop: { xs: "10px !important", sm: "40px !important" },
              }}
              onClick={() => navigate("/rapid-auto-care-info")}
            >
              What's Included?
            </Box>
          </Box>
        </Box>
        <Box></Box>
      </Box>
      <BookingSection styles={styles} selectedService={selectedService} />
      <FooterSection styles={styles} />
      <ToastContainer />
    </>
  );
};

export default RapidAutoCare;
