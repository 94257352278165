import React from "react";
import { Grid, Box, Typography, Button, Card, CardContent } from "@mui/material";
import EventIcon from "@mui/icons-material/Event";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import LocalCarWashIcon from "@mui/icons-material/LocalCarWash";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const HowWeWork = () => {
  const icons = [
    <LocationOnIcon sx={{ color: "#016ABC", fontSize: "25px" }} />,
    <EventIcon sx={{ color: "#016ABC", fontSize: "25px" }} />,
    <LocalCarWashIcon sx={{ color: "#016ABC", fontSize: "25px" }} />,
    <DirectionsCarIcon sx={{ color: "#016ABC", fontSize: "25px" }} />,
  ];

  const stepsData = [
    {
      stepTitle: " Select Location",
      description: "Select the location where you'd like the car wash service.",
    },
    {
      stepTitle: " Book Your Service",
      description: "Pick a time slot that suits your schedule.",
    },
    {
      stepTitle: " Service in Progress",
      description: "Our cleaners will provide high-quality service to your car.",
    },
    {
      stepTitle: " Cleaning Complete",
      description: "Your car is now sparkling clean and ready to go!",
    },
  ];

  return (
    <Box sx={{ width: "90%", maxWidth: "1200px", margin: "auto", mt: 5 }}>
      <Grid container spacing={4}>
        {/* First Grid - Left Content */}
        <Grid item xs={12} md={5}>
          <Box>
            <Typography fontSize="14px" color="#016ABC" fontWeight={800}>
              WORKING
            </Typography>
            <Typography
              fontSize={{ xs: "32px", sm: "40px" }}
              fontWeight="700"
              color="#171717"
              mt={2}
            >
              How We Work!
            </Typography>
            <Typography
              fontSize="14px"
              color="grey"
              mt={2}
              width={{ xs: "100%", sm: "80%" }}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent
              vitae eros eget tellus tristique bibendum. Donec rutrum sed sem
              quis venenatis.
            </Typography>
            <Button
              variant="contained"
              sx={{
                mt: 4,
                borderRadius: "5px",
                padding: "10px 20px",
                fontSize: "13px",
                backgroundColor: "#016ABC",
              }}
            >
              Get Started
            </Button>
          </Box>
        </Grid>

        {/* Second Grid - Right Content (Cards) */}
        <Grid item xs={12} md={7}>
          <Grid container spacing={3}>
            {icons.map((icon, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <Card
                  sx={{
                    border: "1px solid #F3F4F6",
                    borderRadius: "8px",
                    padding: "15px",
                    height: "100%",
                  }}
                >
                  <CardContent>
                    <Box
                      sx={{
                        padding: "8px 10px",
                        backgroundColor: "#F0F5FF",
                        borderRadius: "3px",
                        display: "inline-block",
                        marginBottom: "20px",
                      }}
                    >
                      {icon}
                    </Box>
                    <Typography
                      sx={{
                        fontSize: "20px",
                        fontWeight: "700",
                        marginTop: "8px",
                      }}
                    >
                      {stepsData[index].stepTitle}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "13px",
                        color: "grey",
                        marginTop: "14px",
                        width: "90%",
                      }}
                    >
                      {stepsData[index].description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HowWeWork;
