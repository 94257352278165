import React from "react";
import { Grid } from "@mui/material";

const PersonalInfo = ({
  styles,
  userData,
  editing,
  errors,
  handleInputChange,
}) => {
  return (
    <Grid container spacing={3}>
      {/* Name Fields */}
      <Grid item xs={12} lg={6}>
        <label htmlFor="name" className={styles["input-label"]}>
          Full Name
        </label>
        <input
          type="text"
          name="name"
          id="name"
          placeholder={editing ? "Full Name" : "--------"}
          onChange={handleInputChange}
          value={userData?.name}
          className={styles["text-input"]}
          disabled={!editing}
          style={{
            border: editing ? "1px solid #e5e5e5" : "1px solid transparent",
          }}
          required
        />
        {errors && errors?.name}
      </Grid>
      {/* <Grid item xs={12} lg={6}>
        <label htmlFor="last_name" className={styles["input-label"]}>
          Last Name
        </label>
        <input
          type="text"
          name="last_name"
          id="last_name"
          placeholder={editing ? "Last Name" : "--------"}
          onChange={handleInputChange}
          value={userData?.last_name}
          disabled={!editing}
          style={{
            border: editing ? "1px solid #e5e5e5" : "1px solid transparent",
          }}
          className={styles["text-input"]}
          required
        />
      </Grid> */}
      <Grid item xs={12} lg={6}>
        <label htmlFor="email" className={styles["input-label"]}>
          Email Address
        </label>
        <input
          type="email"
          name="email"
          id="email"
          placeholder={editing ? "Email Address" : "--------"}
          onChange={handleInputChange}
          value={userData?.email}
          disabled={!editing}
          style={{
            border: editing ? "1px solid #e5e5e5" : "1px solid transparent",
          }}
          className={styles["text-input"]}
          required
        />
        {errors && errors?.email}
      </Grid>
      <Grid item xs={12} lg={6}>
        <label htmlFor="phone" className={styles["input-label"]}>
          Phone Number
        </label>
        <input
          type="text"
          name="phone"
          id="phone"
          placeholder={editing ? "Phone Number" : "--------"}
          onChange={handleInputChange}
          value={userData?.phone}
          disabled={!editing}
          style={{
            border: editing ? "1px solid #e5e5e5" : "1px solid transparent",
          }}
          className={styles["text-input"]}
          required
        />
        {errors && errors?.phone}
      </Grid>
    </Grid>
  );
};

export default PersonalInfo;
