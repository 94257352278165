import React from "react";
import { Box } from "@mui/material";
import MainComponent from "./mainComponent";
import PricingSection from "../pricing-section";

const BookingSection = ({ styles }) => {
  return (
    <>
      <Box
        sx={{
          width: "100%",
          backgroundColor: "#0063C3",
          color: "primary.contrastText",
          textAlign: "center",
          fontWeight: "bold",
          fontSize: "16px",
          p: 2,
          zIndex: 2,
        }}
      >
        Cleaners go through a mandatory background criminal check and every
        cleaning session is INSURED.
      </Box>
      {/* <Featured /> */}
      <PricingSection styles={styles}/>
      {/* <Pricing styles={styles} /> */}
      <MainComponent styles={styles} />
    </>
  );
};

export default BookingSection;
