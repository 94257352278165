import { getFunctions, httpsCallable } from "firebase/functions";
import { app } from "./firebase-helper";

const functions = getFunctions(app);

// Function to call Firebase Cloud Function
export async function makeCloudCall(callName, input) {
  try {
    const callableFunction = httpsCallable(functions, callName);
    const response = await callableFunction(input);
    return response.data;
  } catch (error) {
    console.error("Cloud call error:", error);
    return error;
  }
}

// Example of calling the Cloud Function
export async function processStripePayment(data) {
  const callName = "initStripeTestPayment";
  const response = await makeCloudCall(callName, data);
  return response;
}
