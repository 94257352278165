import React from "react";
import { Box } from "@mui/material";
import ImageHeader from "../../components/image-header";
import RapidAutoCare from "../landing/components/rapid-auto-info";

const RapidAutoCareInfo = () => {
  return (
    <>
      <ImageHeader heading={"Rapid Auto Care"} subheading={""} />
      <Box sx={{ p: 4, marginBottom: "20px" }} mb={10}>
        <RapidAutoCare fromInfo={true} />
      </Box>
    </>
  );
};

export default RapidAutoCareInfo;
