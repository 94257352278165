// src/components/MapLoader.js
import React from "react";
import { useLoadScript } from "@react-google-maps/api";
import PlaceAutocomplete from "./PlaceAutocomplete";

const libraries = ["places"]; // Specify the libraries you need

const MapLoader = (props) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY, // Ensure your API key is stored securely
    libraries,
  });

  if (!isLoaded) return <div>Loading...</div>;
  return <PlaceAutocomplete {...props} />;
};

export default MapLoader;
