import React from "react";
import styles from "./styles.module.css";
import FooterSection from "src/pages/homepage/components/footer";
import BookingSection from "./components/bookingsection";
import SearchBar from "src/components/search";
import { Box, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Header from "src/components/header";

const Detailing = () => {
  const location = useLocation();
  const selectedService = location.pathname.toLowerCase().includes("detailing")
    ? "detailing"
    : "rapid_auto_care";

  return (
    <>
      <Box
        className={styles["rapid-care-landing"]}
        sx={{
          height: { xs: "auto", md: "calc(100vh - 56px)" },
          py: { xs: 4, md: 0 },
        }}
      >
        <Header darkBackground={true} />

        {/* Black Overlay */}
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.3)",
            zIndex: 1,
          }}
        />

        {/* Content */}
        <Box
          sx={{
            position: "relative",
            zIndex: 2,
            px: { xs: 2, sm: 4 },
            textAlign: "center",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              mb: 2,
              color: "white",
              fontWeight: "600",
              fontSize: { xs: "1.5rem", sm: "2rem", md: "2.5rem" },
            }}
          >
            Convenient and hassle-free car cleaning at your doorstep.
          </Typography>
          <Typography
            variant="h6"
            sx={{
              mb: 4,
              color: "white",
              fontSize: { xs: "1rem", sm: "1.25rem" },
              opacity: "0.65",
              fontWeight: "normal",
            }}
          >
            No time for cleaning your car? Let our platform connect you with
            local mobile cleaners.
          </Typography>

          <SearchBar selectedService={selectedService} />
        </Box>
        <Box></Box>
      </Box>
      <BookingSection styles={styles} selectedService={selectedService} />
      <FooterSection styles={styles} />
      <ToastContainer />
    </>
  );
};

export default Detailing;
