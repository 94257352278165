import React, { useEffect, useState } from "react";
import styles from "../style.module.css";
import CustomInput from "src/components/inputs/custom-input";
import { showNotification } from "src/components/notification";
import { Box, Grid, Typography, Button, Link } from "@mui/material";
import { ToastContainer } from "react-toastify";
import Header from "src/components/header";
import { post } from "src/api/base";
import { useNavigate } from "react-router-dom";

const CustomerLogin = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (window.localStorage.getItem("user") !== null) {
      showNotification(
        "warning",
        "You're already logged in. Please Sign out first!!"
      );
      setTimeout(() => {
        navigate("/dashboard/customer/profile");
      }, 2000);
    }
  }, []); // eslint-disable-line

  const validateForm = () => {
    const newErrors = {};
    if (!formData.email) newErrors.email = "Email is required.";
    if (!formData.password) newErrors.password = "Password is required.";

    return newErrors;
  };

  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    setFormData({
      ...formData,
      [name]:
        type === "checkbox" ? checked : type === "file" ? files[0] : value,
    });
  };

  const handleClick = async () => {
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      showNotification("Error", "Please enter valid data in all fields.");
      return;
    }

    try {
      const response = await post("/user-login", formData);
      console.log(response);
      localStorage.setItem("user", JSON.stringify(response?.data));
      showNotification("Success", "User Logged in successfully.");
      setTimeout(() => {
        navigate("/dashboard/customer/profile");
      }, 1000);
    } catch (error) {
      showNotification(
        "Error",
        error?.response?.data?.message ?? "User Login unsuccessful!"
      );
      console.log(error);
    }
  };

  return (
    <>
      <ToastContainer />
      <Box className={styles["customer-login-section"]}>
        <Box className={styles["image-overlay"]}>
          <Header />
          <Box className={styles["customer-login"]}>
            <Box className={styles["customer-login-form"]}>
              <Typography variant="h4" className={styles["form-title"]}>
                Customer Login
              </Typography>

              <Typography className={styles["form-sub-title"]}>
                Glad to see you again. Login to your account below.
              </Typography>

              <Grid container spacing={2}>
                {/* Sign up with Google button with icon */}
                {/* <Grid item xs={12} lg={12}>
                  <Button
                    fullWidth
                    variant="outlined"
                    className={styles["button-outlined"]}
                    sx={{
                      width: "100% !important",
                    }}
                    onClick={() => showNotification("Info", "Google login")}
                    startIcon={
                      <img
                        src="/assets/cleaner/google.svg"
                        alt="Google Icon"
                        style={{ height: "25px", width: "25px" }}
                      />
                    }
                  >
                    Sign up with Google
                  </Button>
                </Grid> */}

                {/* Email field */}
                <Grid item xs={12} lg={12}>
                  <CustomInput
                    label="Email"
                    placeholder={"your.email@example.com"}
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    error={errors.email}
                  />
                </Grid>

                {/* Password field */}
                <Grid item xs={12} lg={12}>
                  <CustomInput
                    label="Password"
                    placeholder={"••••••"}
                    name="password"
                    type="password"
                    value={formData.password}
                    onChange={handleChange}
                    error={errors.password}
                  />
                </Grid>

                {/* Submit Button */}
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={styles["button"]}
                    onClick={handleClick}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>

              {/* Bottom text */}
              <Typography sx={{ textAlign: "center", marginTop: 2 }}>
                Don't have an account?{" "}
                <Link href="/auth/register-customer">Sign up here</Link>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default CustomerLogin;
