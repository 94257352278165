import React, { useState } from "react";
import { Box, Button, IconButton, Drawer, Grid } from "@mui/material";
import { Info } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import RapidAutoCare from "./rapid-auto-info";
import Detailing from "./detailing";
import CloseIcon from '@mui/icons-material/Close';

const BottomButtons = ({ styles }) => {
  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedService, setSelectedService] = useState("");

  const handleInfoClick = (service) => {
    setSelectedService(service);
    setOpenDrawer(true);
  };

  const handleClose = () => {
    setOpenDrawer(false);
  };

  return (
    <Box className={styles["bottom-buttons"]} p={2} display="flex" justifyContent="center" sx={{marginBottom:{xs:"6rem", sm:"6rem", md:"2rem",lg:"2rem"}}}>
      <Grid container spacing={2} justifyContent="center" maxWidth={700} width="100%">
        {/* Button and Info icon for Rapid Auto Care */}
        <Grid item xs={12} md={6} display="flex" justifyContent="center" alignItems="center">
          <Button
            variant="contained"
            className={styles["button"]}
            onClick={() => navigate("/services/rapid-auto-care")}
            style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0, flexGrow: 1 }}
          >
            Rapid Auto Care
          </Button>

          <IconButton
            aria-label="info"
            onClick={() => handleInfoClick("rapid_auto_care")}
            style={{
              backgroundColor: "#016ABC",
              borderRadius: "0",
              borderTopRightRadius: "4px",
              borderBottomRightRadius: "4px",
            }}
          >
            <Info style={{ color: "#fafafa" }} />
          </IconButton>
        </Grid>

        {/* Button and Info icon for Detailing */}
        <Grid item xs={12} md={6} display="flex" justifyContent="center" alignItems="center">
          <Button
            variant="contained"
            className={styles["button-white"]}
            onClick={() => handleInfoClick("detailing")}
            style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0, flexGrow: 1 }}
          >
            Detailing
          </Button>

          <IconButton
            aria-label="info"
            onClick={() => handleInfoClick("detailing")}
            style={{
              backgroundColor: "white",
              borderRadius: "0",
              borderTopRightRadius: "4px",
              borderBottomRightRadius: "4px",
            }}
          >
            <Info />
          </IconButton>
        </Grid>
      </Grid>

      {/* Drawer for showing service info */}
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={handleClose}
        sx={{ '& .MuiDrawer-paper': { width: {xs : "80%" , sm:"80%" , md :'60%'} } }} // Adjust width for responsiveness
      >
        <IconButton 
          onClick={handleClose} 
          sx={{ position: 'absolute', top: 8, right: 8 }}
        >
          <CloseIcon />
        </IconButton>
        <Box mt={3} p={1}>
          {selectedService === "rapid_auto_care" ? <RapidAutoCare /> : <Detailing />}
        </Box>
      </Drawer>
    </Box>
  );
};

export default BottomButtons;
