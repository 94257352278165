// src/components/PlaceAutocomplete.js
import React, { useRef, useCallback, useEffect } from "react";

const PlaceAutocomplete = (props) => {
  const inputRef = useRef(null);

  const setupAutocomplete = useCallback(() => {
    if (!inputRef.current) return;

    const autocomplete = new window.google.maps.places.Autocomplete(
      inputRef.current,
      { componentRestrictions: { country: "CA" }, types: ["address"] }
    );
    autocomplete.setFields([
      "address_components",
      "formatted_address",
      "geometry",
    ]); // Set the data you need

    // Function to extract specific address components
    const extractAddressComponent = (components, type) => {
      const component = components.find((item) => item.types.includes(type));
      return component ? component.long_name : "N/A";
    };

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      const addressComponents = place.address_components || [];

      const vendor_address = place.formatted_address || "N/A";
      const vendor_latitude = place.geometry?.location?.lat() || "N/A";
      const vendor_longitude = place.geometry?.location?.lng() || "N/A";
      const city = extractAddressComponent(addressComponents, "locality"); // City
      const province = extractAddressComponent(
        addressComponents,
        "administrative_area_level_1"
      ); // Province/State
      const country = extractAddressComponent(addressComponents, "country"); // Country

      const selectedPlace = {
        address: vendor_address,
        latitude: vendor_latitude,
        longitude: vendor_longitude,
        city,
        province,
        country,
        vendor_unit_number: "N/A",
      };
      // Call the onChange prop function and pass the selected place data
      if (props.onChange) {
        props.onChange(selectedPlace);
      }
    });
  }, []); // eslint-disable-line

  useEffect(() => {
    if (window.google) {
      setupAutocomplete();
    }
  }, [setupAutocomplete]);

  return <input ref={inputRef} type="text" {...props} />;
};

export default PlaceAutocomplete;
