import React from 'react';
import { Grid,Accordion, AccordionSummary, AccordionDetails, Typography, List, ListItem, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const faqData = {
  faqSections: [
    {
      heading: 'General',
      faqs: [
        {
          question: 'What is Sayfaya?',
          answer:"Sayfaya is an online marketplace that connects car users with mobile washers for on-demand service at any location."
        },
        {
          question: 'Who are the Sayfaya vendors?',
          answer: 'Sayfaya vendors are background-checked and insured cleaning professionals. Anyone can be a Sayfaya cleaner as long as they follow the onboarding process.',
        },
        {
            question: 'Do I need to provide my keys to Sayfaya?',
            answer: 'Yes, the cleaner will be in touch with you to collect and return your keys.',
        },
        {
        question: 'Do I need to provide any products?',
        answer: 'No, Sayfaya vendors will bring all the necessary supplies. However, you may need to provide access to power/water outlets depending on the service requested.',
        },
        {
            question: 'Do service providers clean baby or child car seats?',
            answer: 'Sayfaya service providers do not clean baby or child safety seats.',
        },
      ],
    },
    {
      heading: 'Cleaner Related',
      faqs: [
        {
          question: 'Contact Cleaner',
          answer: 'To contact the cleaner, please send us a message through the contact us form and we will ask the vendor to call you asap.',
        },
        {
          question: 'Does Sayfaya keep a percentage of Vendor tips?',
          answer: 'No, 100% of any added tip amount goes straight to your Sayfaya cleaner!',
        },
      ],
    },
    {
        heading: 'Complaints',
        faqs: [
          {
            question: 'What happens if something is damaged or missing?',
            answer: 'Sayfaya service providers are independent contractors who are insured in the event that anything goes wrong during service. If there is damage done to a vehicle by a service provider, it is the customer’s responsibility to notify the service provider immediately of the damage following the service. Once the service provider leaves the premises and you opt not to inspect the vehicle, all rights to file a liability claim are waived. You are responsible for negotiating and exchanging insurance information. Sayfaya is not liable for incidental, special, exemplary, punitive, or consequential damages, liability, lost property, personal injury, or property damage arising from the usage of our platform. Sayfaya is not responsible for any belongings that may be lost or otherwise disposed of while performing services on your vehicle. We strongly recommend that you remove any belongings you consider valuable before service.',
          },
        ],
    },

    {
        heading: 'Cancellation',
        faqs: [
          {
            question: 'What if I need to cancel my appointment?',
            answer: 'You can cancel an appointment free of charge up to 24 hours prior to the scheduled service time. If you cancel within 24 hours of the scheduled start, Sayfaya may apply a $10 cancellation fee to cover the costs of rerouting our vendors.',
          },
          {
            question: 'How can I cancel my appointment?',
            answer: <span>You can cancel by contacting us at +1 902-989-4724 or +1 902-402-1121. You can also send an email to us at <a href="mailto:support@sayfaya.com">support@sayfaya.com</a></span>,
          },
        ],
      },
  ],
};

const FAQDrawerInfo = () => {
  return (
    <div>
         <Grid item xs={12}>
          {/* <Typography variant="h3" gutterBottom>
            Frequently Asked Questions
          </Typography> */}
          </Grid>
      {faqData.faqSections.map((section, sectionIndex) => (
        <Box key={sectionIndex} mt={2}>
          <Typography variant="h4" gutterBottom>{section.heading}</Typography>

          {section.faqs.map((faq, faqIndex) => (
            <Accordion key={faqIndex} sx={{backgroundColor:"#F9FAFB"}}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="body1">{faq.question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {faq.steps ? (
                  <div>
                    <Typography variant="body2" gutterBottom>{faq.steps[0]}</Typography>
                    <List>
                      {faq.details.map((detail, detailIndex) => (
                        <ListItem key={detailIndex}>
                          <Typography variant="body2">{detail}</Typography>
                        </ListItem>
                      ))}
                    </List>
                  </div>
                ) : (
                  <Typography variant="body2">{faq.answer}</Typography>
                )}
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      ))}
    </div>
  );
};

export default FAQDrawerInfo;
