import React from "react";
import { Box, Button, Grid } from "@mui/material";
import { LocalCarWashOutlined, Menu } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import styles from "./style.module.css";
import AppDrawer from "./components/drawer";

const Header = ({ darkBackground, transparent, fromLanding }) => {
  const navigate = useNavigate();
  // const [menuOpen, setMenuOpen] = useState(false);

  const [open, setOpen] = React.useState(false);

  // const toggleMenu = () => {
  //   setMenuOpen(!menuOpen);
  // };

  const toggleMenu = () => {
    setOpen(!open);
  };

  const pages = [
    { name: "FAQs", link: "/about" },
    { name: "Rapid Auto Care", link: "/services/rapid-auto-care" },
    { name: "Become a Partner", link: "/become-cleaner" },
    { name: "Contact Us", link: "/contact-us" },
    { name: "Privacy Policy", link: "/privacy-policy" },
    { name: "Terms and Condition", link: "/terms-and-conditions" },
  ];

  return (
    <Box
      className={
        transparent === false ? styles["header"] : styles["headerTransparent"]
      }
    >
      <Box className={styles["toolbar"]}>
        <Box className={styles["logo"]} sx={{ marginTop: "15px" }}>
          <Link to="/">
            <Box
              component="img"
              src={
                darkBackground
                  ? "/assets/final_logo_white.png"
                  : "/assets/final_logo.png"
              }
              width={"140px"}
              // height="80px"
            />
          </Link>
        </Box>
        {/* Menu Icon for Mobile */}
        <Box className={styles["menu-icon"]}>
          <Menu onClick={toggleMenu} />
        </Box>
        <Box className={`${styles["menu"]}`}>
          <Box
            className={styles[darkBackground ? "menu-item-dark" : "menu-item"]}
          >
            <Link to={"/"}>Home</Link>
          </Box>
          <Box
            className={styles[darkBackground ? "menu-item-dark" : "menu-item"]}
          >
            <Link
              to={
                fromLanding
                  ? "/services/rapid-auto-care"
                  : "/services/rapid-auto-care"
              }
            >
              Rapid Auto Care
            </Link>
          </Box>
          <Box className={styles["menu-item-logo"]}>
            <Link
              to="https://www.bbb.org/ca/ns/halifax/profile/auto-detailing/sayfaya-0087-89879"
              target="_blank"
            >
              <Box
                component="img"
                src="/assets/images/cars/bbb-logo.svg"
                alt="BBB Accredited Business"
                width={"100%"}
                height="40px"
              />
            </Link>
          </Box>
          <Box
            className={styles[darkBackground ? "menu-item-dark" : "menu-item"]}
          >
            <Link to={"/contact-us"}>Contact us</Link>
          </Box>
          <Box
            className={styles[darkBackground ? "menu-item-dark" : "menu-item"]}
          >
            <Link to={"/about"}>FAQs</Link>
          </Box>
        </Box>{" "}
        {/* Right Side Buttons */}
        <Box className={styles.iconButtons}>
          {/* <Tooltip title="Access your Dashboard">
            <IconButton
              color="inherit"
              onClick={() => navigate("/auth/customer-login")}
            >
              <Person sx={{ color: darkBackground ? "white" : "black" }} />
            </IconButton>
          </Tooltip>*/}
          {/* <Tooltip title="Contact Us">
            <IconButton color="inherit" onClick={() => navigate("/contact-us")}>
              <SupportAgent
                sx={{ color: darkBackground ? "white" : "black" }}
              />
            </IconButton>
          </Tooltip> */}
          <Button
            variant="contained"
            className={styles["button"]}
            sx={{
              width: "fit-content !important",
              height: "35px !important",
            }}
            onClick={() => navigate("/become-cleaner")}
          >
            <LocalCarWashOutlined
              sx={{ fontSize: "16px", marginRight: "10px" }}
            />
            Become a Partner
          </Button>
        </Box>
      </Box>

      <Grid sx={{ display: { xs: "block", sm: "block", md: "none" } }}>
        <AppDrawer open={open} onClose={toggleMenu} pages={pages} />
        <Box
          display="flex"
          flexDirection="row"
          className={styles["nav_bar"]}
          onClick={toggleMenu}
        >
          {/* <Box mr={1} className={styles["nav_icon"]}>
                        {<MenuIcon sx={{ fontSize: "1.1rem" }} />}
                      </Box> */}
        </Box>
      </Grid>
    </Box>
  );
};

export default Header;
