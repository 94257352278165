import React from "react";
import Select from "react-select";

const customStyles = {
  container: (base, state) => ({
    ...base,
    textAlign: "left",
    "&:hover": {
      borderColor: "white",
      outline: "none",
    },
    "&:focus-visible": {
      border: "none",
      outline: "none",
    },
    width: "95%",
  }),
  control: (base, state) => ({
    ...base,
    border: "none",
    outline: "none",
    minHeight: "16px", // Reducing the minimum height
    height: "16px", // Reducing the fixed height
    padding: "0px", // Adjust padding as needed, might need reducing
    "&:hover": {
      border: "none !important",
      outline: "none !important",
      borderColor: state.isFocused ? "transparent" : "gray", // Maintain consistent borderColor on hover
    },
    borderColor: state.isFocused ? "transparent" : "gray",
    boxShadow: state.isFocused ? "none" : undefined, // Remove boxShadow when focused
  }),
  valueContainer: (base) => ({
    ...base,
    height: "16px", // Ensure the height matches the control height
    padding: "0px", // Adjust inner padding to vertically center the text
  }),
  input: (base) => ({
    ...base,
    margin: "0px", // Remove margin
    padding: "0px", // Remove padding to reduce extra height
    outline: "none",
  }),
  placeholder: (base) => ({
    ...base,
    margin: "0px", // Remove margin
    padding: "0px", // Remove padding to reduce extra height
  }),
  indicatorsContainer: (base) => ({
    ...base,
    height: "16px", // Adjust the height of indicator container (clear, dropdown arrows)
  }),
  dropdownIndicator: (base) => ({
    ...base,
    padding: "4px", // Reduce padding to match the height
  }),
  clearIndicator: (base) => ({
    ...base,
    padding: "4px", // Reduce padding to match the height
  }),
};

const CarPicker = (props) => (
  <Select defaultValue={"rapid_auto_care"} {...props} styles={customStyles} />
);
export default CarPicker;
