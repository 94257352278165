import React, { createContext, useContext, useEffect, useState } from "react";

const SecretContext = createContext();

const initialData = [
  {
    id: 1,
    key: "AMS_REGISTRY_PASSWORD",
    value: "1234",
    type: "variable",
    visibility: "visible",
  },
  {
    id: 2,
    key: "AMS_AWS_PASSWORD",
    value: "value",
    type: "variable",
    visibility: "masked",
  },
];

const SecretProvider = ({ children }) => {
  const [secrets, setSecrets] = useState(() => {
    const localData = localStorage.getItem("secrets");
    return localData ? JSON.parse(localData) : initialData;
  });
  const [revealValues, setRevealValues] = useState(false);

  useEffect(() => {
    localStorage.setItem("secrets", JSON.stringify(secrets));
  }, [secrets]);

  return (
    <SecretContext.Provider
      value={{ secrets, setSecrets, revealValues, setRevealValues }}
    >
      {children}
    </SecretContext.Provider>
  );
};

const useSecrets = () => {
  return useContext(SecretContext);
};

export { SecretProvider, useSecrets };
