import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import { Build, CleaningServices, LocalOffer } from "@mui/icons-material";

const cardData = [
  {
    title: "Doorstep Service",
    description:
      "Enjoy the convenience of having our cleaning service delivered right.",
    icon: (
      <HomeIcon sx={{ color: "#ffff", fontSize: { xs: "40px", md: "50px" } }} />
    ),
  },
  {
    title: "Affordable Pricing",
    description: (
      <>
        Get top-notch cleaning services at prices just at{" "}
        <span style={{ textDecoration: "line-through" }}>$49.99</span> $39.99
      </>
    ),
    icon: (
      <LocalOffer
        sx={{ color: "#ffff", fontSize: { xs: "40px", md: "50px" } }}
      />
    ),
  },
  {
    title: "Local Cleaner",
    description:
      "Support local businesses with our professional cleaners from your area.",
    icon: (
      <CleaningServices
        sx={{ color: "#ffff", fontSize: { xs: "40px", md: "50px" } }}
      />
    ),
  },
  {
    title: "Hassle-Free Cleaning",
    description:
      "Experience a stress-free cleaning process with our easy-to-use app.",
    icon: (
      <Build sx={{ color: "#ffff", fontSize: { xs: "40px", md: "50px" } }} />
    ),
  },
];

const WhyChooseUs = ({ styles }) => {
  return (
    <Box
      sx={{
        width: { xs: "95%", md: "85%" },
        margin: "auto",
        marginTop: { xs: "20px", md: "40px" },
        marginBottom: { xs: "30px", md: "50px" },
      }}
    >
      <Typography
        fontSize={{ xs: "28px", md: "40px" }}
        fontWeight="700"
        color="#171717"
        marginTop={{ xs: "30px", md: "10px" }}
        marginBottom={{ xs: "20px", md: "40px" }}
        textAlign="center"
      >
        Why Choose SayFaya
      </Typography>

      {/* Grid Container */}
      <Grid container spacing={3} sx={{ mb: 4 }}>
        {cardData.map((card, index) => (
          <Grid item xs={12} sm={12} md={3} key={index}>
            <Box
              className={styles["cookieCard"]}
              sx={{
                border: "1px solid #e0e0e0",
                borderRadius: "8px",
                padding: { xs: "12px", sm: "16px" },
                display: "flex",
                flexDirection: "column",
                alignItems: "center !important",
                justifyContent: "center",
                textAlign: "center",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                transition: "transform 0.3s",
              }}
            >
              {card.icon}
              <Typography
                className={styles["cookieHeading"]}
                sx={{
                  fontSize: { xs: "18px", md: "20px" },
                  fontWeight: "500",
                  mt: 1,
                }}
              >
                {card.title}
              </Typography>

              <Typography
                className={styles["cookieDescription"]}
                sx={{
                  fontSize: { xs: "12px", md: "14px" },
                  color: "grey",
                  mb: 2,
                  textAlign: "center",
                }}
              >
                {card.description}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default WhyChooseUs;
