import React from "react";
import NavBar from "../../components/navbar";
import styles from "./style.module.css";
// import FilterHeader from "./component/filter";
import { Box } from "@mui/material";
// import Sorting from "./component/sorting";
// import {detailingPackages} from "./component/data"; 
// import CleanerList from "./component/cleanerlist";
// import SearchBar from "src/components/search";
import PackageSelection from './component/packages'
const Landing = () => {
  return (
    <>
      <NavBar styles={styles} hideExtras = {true}/>
      {/* <SearchBar/> */}
      {/* <FilterHeader styles={styles} /> */}
      <Box sx={{ pt: 1 }}>
        {/* <Sorting styles={styles} /> */}
          <PackageSelection styles={styles}/>
      </Box>
    </>
  );
};

export default Landing;
