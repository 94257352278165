import SvgColor from "../../../components/svg-color";

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const navConfig = [
  {
    title: "profile",
    path: "/dashboard/customer/profile",
    icon: icon("user_svg"),
  },
  {
    title: "orders",
    path: "/dashboard/customer/orders",
    icon: icon("scheduler"),
  },
  {
    title: "settings",
    path: "/dashboard/customer/settings",
    icon: icon("ic_todo"),
  },
  // {
  //   title: "Scheduler",
  //   path: "/dashboard/tasks",
  //   icon: icon("scheduler"),
  // },
  // This one is rendered at bottom separately
  // {
  //   title: 'User Management',
  //   path: '/dashboard/user',
  //   icon: icon('user_svg'),
  // },
  // {
  //   title: "Chatbot",
  //   path: "/dashboard/chatbot",
  //   icon: icon("chatbot"),
  // },

  // {
  //   title: "Locker",
  //   path: "/dashboard/locker",
  //   icon: icon("secret"),
  // },
];

export default navConfig;
