import React from "react";
import {
  Grid,
  Box,
  Typography,
  Divider,
  Button,
  Container,
  AppBar,
  Toolbar,
} from "@mui/material";

import {
  ShoppingCart,
  PersonAddAlt,
  Menu as MenuIcon,
} from "@mui/icons-material";

import AppDrawer from "./drawer";

const NavBar = ({ styles, fullscreen, hideExtras }) => {
  const navItems = [
    // { name: "Recently Viewed ", logo: <Restore sx={{ fontSize: "1rem" }} /> },
    // { name: "Favorites", logo: <FavoriteBorder sx={{ fontSize: "1rem" }} /> },
    // { name: "EN.₹ ", logo: <Language sx={{ fontSize: "1rem" }} /> },
    { name: "", logo: <ShoppingCart sx={{ fontSize: "1.1rem" }} /> },
    {
      name: "Become a cleaner ",
      logo: <PersonAddAlt sx={{ fontSize: "1.1rem" }} />,
    },
  ];

  // State to manage the Drawer menu
  const [open, setOpen] = React.useState(false);

  const pages = [
    { name: "About Us", link: "about-us" },
    { name: "Book a service", link: "book-service" },
    { name: "Rapid Auto Care", link: "rapid-auto-care" },
    { name: "Become a Partner", link: "become-partner" },
    { name: "Contact Us", link: "contact-us" },
    { name: "FAQs", link: "faqs" },
  ];

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const getNavbarStyle = () => {
    if (fullscreen) {
      return {
        display: "none",
        marginTop: "20px",
      };
    }
    return {
      display: "block",
      marginTop: "20px",
    };
  };
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position={fullscreen ? "absolute" : "static"}
          style={{ backgroundColor: fullscreen ? "#08aaff" : "transparent" }}
        >
          <Toolbar style={getNavbarStyle()}>
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid
                item
                xs={3}
                md={1}
                lg={2}
                sx={{ padding: "0 !important", marginLeft: "40px" }}
              >
                <img
                  className={styles["navIcon"]}
                  src="https://sayfaya.com/wp-content/uploads/2023/04/Sayfaya-crop.png"
                  alt="logo"
                />
              </Grid>

              <Grid item md={6}></Grid>

              <Grid item>
                <Grid container>
                  {navItems.map((item) => (
                    <Box
                      display="flex"
                      className={styles["nav_bar"]}
                      flexDirection="row"
                    >
                      <Box mr={1} className={styles["nav_icon"]}>
                        {item.logo}
                      </Box>
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: "13px",
                          color: "text.primary",
                          display: { sm: "block", xs: "none", md: "block" },
                        }}
                      >
                        {item.name}
                      </Typography>
                    </Box>
                  ))}
                  <Grid
                    sx={{ display: { xs: "block", sm: "block", md: "none" } }}
                  >
                    <AppDrawer
                      open={open}
                      onClose={toggleDrawer(false)}
                      pages={pages}
                    />
                    <Box
                      display="flex"
                      flexDirection="row"
                      className={styles["nav_bar"]}
                      onClick={toggleDrawer(true)}
                    >
                      <Box mr={1} className={styles["nav_icon"]}>
                        {<MenuIcon sx={{ fontSize: "1.1rem" }} />}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Toolbar>

          {/* Divider below the grid */}
          {!hideExtras ? (
            <>
              <Divider
                style={{
                  marginLeft: "50px",
                  marginRight: "50px",
                  marginBottom: "10px",
                }}
              />

              <Box sx={{ borderBottom: 1, borderColor: "divider", py: 1 }}>
                <Container maxWidth="lg">
                  <Grid container spacing={2} justifyContent="center">
                    {/* Desktop View */}
                    <Grid
                      item
                      sx={{ display: { xs: "none", sm: "none", md: "block" } }}
                    >
                      <Grid container spacing={2}>
                        {pages.map((page, index) => (
                          <Grid item key={index}>
                            <Button
                              href={page.link}
                              sx={{
                                textTransform: "none",
                                fontWeight: "900",
                                padding: "8px 16px",
                                borderRadius: "20px",
                                color: fullscreen
                                  ? "#ffffff"
                                  : "text.primaryColor",
                                backgroundColor:
                                  index === 0
                                    ? "primary.lighter"
                                    : "transparent",
                                "&:hover": {
                                  backgroundColor:
                                    index === 0 ? "primary.main" : "grey.100",
                                  color: "white",
                                },
                              }}
                            >
                              {page.name} 
                            </Button>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  </Grid>
                </Container>
              </Box>
            </>
          ) : (
            <></>
          )}
        </AppBar>
      </Box>
    </>
  );
};

export default NavBar;
