import React, { createContext, useState, useEffect } from "react";

export const RapidCareBookingContext = createContext();

export const RapidCareBookingProvider = ({ children }) => {
  const initialData = {
    location: "",
    date: "",
    numberOfCars: 1,
    carMakeModel: "",
    bill_city:"",
    bill_country:"",
    bill_province:"",
    order_latitude:"",
    order_longitude:"",
    serviceType: { value: "detailing", label: "Detailing" },
  };

  // Initialize state from localStorage or use initialData if no data is found
  const [rapidCareBookingData, setRapidCareBookingData] = useState(() => {
    const savedData = localStorage.getItem('rapidCareBookingData');
    return savedData ? JSON.parse(savedData) : initialData;
  });

  // Persist the data to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('rapidCareBookingData', JSON.stringify(rapidCareBookingData));
  }, [rapidCareBookingData]);

  // Function to clear the context data and remove it from localStorage
  const clearRapidCareBookingData = () => {
    setRapidCareBookingData(initialData);
    localStorage.removeItem('rapidCareBookingData'); // Clear persisted data
  };

  return (
    <RapidCareBookingContext.Provider
      value={{
        rapidCareBookingData,
        setRapidCareBookingData,
        clearRapidCareBookingData,
      }}
    >
      {children}
    </RapidCareBookingContext.Provider>
  );
};
