import React, { useState, useEffect } from "react";
import Carousel from "react-material-ui-carousel";
import {
  Box,
  Typography,
  Grid,
  Button,
  Card,
  Chip,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/system";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { formatDate } from "../../../api/base";
import { LocationOn, Star } from "@mui/icons-material";

const ServiceCard = styled(Card)({
  minWidth: "300px",
  border: "1px solid #e0e0e0",
  borderRadius: "10px",
  padding: "20px",
  width: "100%",
  marginBottom: "20px",
});

// eslint-disable-next-line
const renderStars = (rating) => {
  const totalStars = 5; // Total number of stars to display
  let stars = "";

  // Generate stars based on rating
  for (let i = 0; i < totalStars; i++) {
    stars += i < rating ? "★" : "☆"; // Full star for rating, empty star otherwise
  }

  return stars;
};

function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
  const R = 6371; // Radius of the Earth in kilometers
  const dLat = deg2rad(lat2 - lat1); // Convert degrees to radians
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c; // Distance in kilometers
  return distance.toFixed(2);
}

function deg2rad(deg) {
  return deg * (Math.PI / 180);
}

const CleanerListCard = ({
  styles,
  formData,
  cleaner_list,
  selectedCleaner,
  setSelectedCleaner,
  setSelectedDates,
  selectedDates,
  selectedTimeSlot,
  setSelectedTimeSlot,
  rapidCareBookingData,
  openReviewSection,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleCleanerSelect = (cleanerId) => {
    setSelectedCleaner(cleanerId); // Update the selected cleaner
  };

  const _bookingDate = rapidCareBookingData?.date;

  const formatDateToDMY = (dateStr) => {
    let [year, month, day] = dateStr.split("-");
    return `${parseInt(day)}-${parseInt(month)}-${year}`; // Convert day and month to integer to remove leading zeros
  };

  const bookedSlotsByDate = (bookedSlots) => {
    let slotsByDate = {};
    bookedSlots.forEach((slot) => {
      let [date, time] = slot.split(" ");
      time = time.substring(0, 5); // Remove the seconds from the time

      // Reformat date to d-m-yyyy without leading zeros for day and month
      let formattedDate = formatDateToDMY(date);

      if (!slotsByDate[formattedDate]) {
        slotsByDate[formattedDate] = [];
      }

      slotsByDate[formattedDate].push(time);
    });
    return slotsByDate;
  };

  return (
    <Box sx={{ margin: "20px auto", position: "relative" }}>
      {cleaner_list.map((pkg, index) => (
        <ServiceCard
          key={`${pkg.latitude + index}`}
          onClick={() => handleCleanerSelect(pkg?.vendor_id)} // Handle selection
          sx={{
            border: selectedCleaner === index ? "2px solid #1976d2" : "none", // Highlight selected cleaner
            cursor: "pointer",
          }}
        >
          <Grid container spacing={2}>
            {/* Left side with image */}
            <Box
              sx={{ position: "absolute", top: 8, right: 8 }}
              className={styles["badge"]}
            >
              {pkg?.ratings > 0 && (
                <Chip
                  icon={<Star style={{ color: "#FFC13A" }} />}
                  label={`${parseFloat(pkg?.ratings).toFixed(1)}+`}
                  sx={{
                    border: "1px solid #454F5B",
                    backgroundColor: "white", // Green background
                    color: "#454F5B", // White text color
                    fontWeight: "bold",
                    marginRight: "10px",
                  }}
                />
              )}
              <Tooltip
                sx={{ textAlign: "center", padding: "20px" }}
                title={
                  "This vendor has undergone a thorough criminal record check and has been verified as a safe and trustworthy partner."
                }
              >
                <Chip
                  icon={<CheckCircleIcon style={{ color: "white" }} />}
                  label="Verified"
                  sx={{
                    backgroundColor: "rgb(16, 57, 149)", // Green background
                    color: "white", // White text color
                    fontWeight: "bold",
                  }}
                />
              </Tooltip>
            </Box>
            <Grid item xs={12} md={2}>
              <Box
                component="img"
                src={pkg.vendor_picture ?? pkg.profile_picture}
                alt={pkg.title}
                className={styles["cleaner-img"]}
                sx={{
                  width: "100%", // Make it responsive
                  borderRadius: "10px",
                  maxHeight: "200px",
                }}
              />
            </Grid>

            {/* Right side with text */}
            <Grid item xs={12} md={10}>
              <Box>
                <Typography variant="h6">{pkg.title}</Typography>
                <Box className={styles["badge2"]}>
                  <Tooltip
                    sx={{ textAlign: "center", padding: "20px" }}
                    title={
                      "This vendor has undergone a thorough criminal record check and has been verified as a safe and trustworthy partner."
                    }
                    open={showTooltip}
                    onOpen={() => setShowTooltip(true)}
                    onClose={() => setShowTooltip(false)}
                  >
                    <Chip
                      icon={<CheckCircleIcon style={{ color: "#103995" }} />}
                      label="Verified"
                      sx={{
                        backgroundColor: "#f5efef", // Green background
                        color: "#103995", // White text color
                        fontWeight: "bold",
                      }}
                      onClick={() => setShowTooltip(!showTooltip)}
                    />
                  </Tooltip>
                  {pkg?.ratings > 0 && (
                    <Chip
                      icon={
                        <Star style={{ color: "#FFC13A", fontSize: "16px" }} />
                      }
                      label={`${parseFloat(pkg?.ratings).toFixed(1)}+`}
                      sx={{
                        border: "1px solid #c5c5c5",
                        backgroundColor: "white", // Green background
                        color: "black", // White text color
                        fontWeight: "bold",
                        fontSize: "12px",
                        marginLeft: "10px",
                      }}
                    />
                  )}
                </Box>
                <Typography
                  variant="body2"
                  className={styles["single-info"]}
                  sx={{ fontWeight: "bold" }}
                >
                  <LocationOn sx={{ fontSize: "15px", marginRight: "5px" }} />
                  {pkg?.region ||
                    pkg?.location?.split(",").slice(-2).join(",")}{" "}
                  [
                  {getDistanceFromLatLonInKm(
                    formData?.order_latitude,
                    formData?.order_longitude,
                    pkg?.latitude,
                    pkg?.longitude
                  )}{" "}
                  KM away]
                </Typography>
                {pkg.total_number_of_review > 0 ? (
                  <Button
                    sx={{ padding: 0 }}
                    onClick={() => openReviewSection(pkg.vendor_id)}
                  >
                    <Typography
                      variant="body2"
                      className={styles["single-info"]}
                    >
                      {"Total Reviews: " + pkg.total_number_of_review}
                    </Typography>
                  </Button>
                ) : (
                  <></>
                )}

                <Typography variant="body2" className={styles["single-info"]}>
                  {"Number of cars serviced: " + pkg.number_of_booking}
                </Typography>
                {/* <Typography variant="body2">
                  Rating: {renderStars(pkg.ratings)}
                </Typography> */}
              </Box>

              <Box mt={1}>
                <TimeSlotSelector
                  key={index}
                  selectedTimeSlot={selectedTimeSlot}
                  setSelectedTimeSlot={setSelectedTimeSlot}
                  setSelectedDates={setSelectedDates}
                  selectedDates={selectedDates}
                  availableSlots={pkg.available_slots}
                  bookedSlots={bookedSlotsByDate(pkg.already_booked)}
                  cleanerId={pkg.vendor_id}
                  bookingDate={_bookingDate}
                  disabled={selectedCleaner !== index} // Disable if not selected
                />
              </Box>
            </Grid>
          </Grid>
        </ServiceCard>
      ))}
    </Box>
  );
};

const TimeSlotSelector = ({
  availableSlots,
  cleanerId,
  setSelectedDates,
  selectedDates,
  selectedTimeSlot,
  setSelectedTimeSlot,
  bookingDate,
  bookedSlots,
}) => {
  const [days, setDays] = useState([]);
  const generateNextDays = () => {
    const nextSlots = [];
    const startDate = new Date(bookingDate); // Start from the provided booking date
    const current_date = new Date(); // Clone current date to modify

    current_date.setHours(current_date.getHours() + 3); // Increment hours by 3

    for (let i = 0; i < 5; i++) {
      // Create a new date for each iteration by adding i days to the startDate
      const date = new Date(startDate);
      date.setDate(startDate.getDate() + i);

      // Format the date to match the availableSlots keys (e.g., "8-10-2024")
      const formattedDate = `${date.getDate()}-${
        date.getMonth() + 1
      }-${date.getFullYear()}`;

      // Check if slots exist for the formatted date
      if (!availableSlots[formattedDate]) {
        continue; // Skip to the next iteration if there are no slots
      }

      const slots = availableSlots[formattedDate]; // Get slots for the day
      let dummyCurrentDate = new Date(current_date.getTime());
      dummyCurrentDate = new Date(
        dummyCurrentDate.setHours(0, 0, 0, 0)
      ).getTime();

      if (new Date(date.setHours(0, 0, 0, 0)).getTime() < dummyCurrentDate) {
        nextSlots.push({
          day: date.toLocaleDateString("en-US", { weekday: "long" }), // Day of the week
          date: formattedDate, // Formatted date (e.g., "8-10-2024")
          date_display: date.toLocaleDateString("en-US", {
            day: "numeric",
            month: "short",
            year: "2-digit",
          }), // Display date (e.g., "8 Oct, 24")
          slots: [],
        });
      } else if (
        new Date(date.setHours(0, 0, 0, 0)).getTime() === dummyCurrentDate && // Check if it's the current day
        i === 0 // Check if it's the first iteration
      ) {
        const currentTimestamp = current_date.getTime(); // Get the current time as a timestamp

        const futureSlots = slots.filter((slot) => {
          // Split the slot time into hours and minutes
          const [hours, minutes] = slot.split(":").map(Number);

          // Create a new Date object with the slot's time, but on the same day as new_date
          const slotDate = new Date(current_date);
          slotDate.setHours(hours, minutes, 0, 0); // Set the time of the slot

          const slotTimestamp = slotDate.getTime(); // Get the slot time as a timestamp

          // Return true if the slot's timestamp is after the current timestamp
          return slotTimestamp > currentTimestamp;
        });

        if (futureSlots.length > 0) {
          nextSlots.push({
            day: date.toLocaleDateString("en-US", { weekday: "long" }), // Day of the week
            date: formattedDate, // Formatted date (e.g., "8-10-2024")
            date_display: date.toLocaleDateString("en-US", {
              day: "numeric",
              month: "short",
              year: "2-digit",
            }), // Display date (e.g., "8 Oct, 24")
            slots: futureSlots, // Future available slots for that date
          });
        }
      } else {
        // For all other days, just push the slots if they exist
        nextSlots.push({
          day: date.toLocaleDateString("en-US", { weekday: "long" }), // Day of the week
          date: formattedDate, // Formatted date (e.g., "8-10-2024")
          date_display: date.toLocaleDateString("en-US", {
            day: "numeric",
            month: "short",
            year: "2-digit",
          }), // Display date (e.g., "8 Oct, 24")
          slots, // Available slots for that date
        });
      }
    }

    return nextSlots;
  };

  // Function to find the first available date from the availableSlots
  const getFirstAvailableDate = () => {
    return Object.keys(availableSlots).find(
      (date) => availableSlots[date].length > 0
    );
  };
  useEffect(() => {
    setDays(generateNextDays());

    const firstAvailableDate = getFirstAvailableDate();
    if (firstAvailableDate) {
      setSelectedDates((prevState) => ({
        ...prevState,
        [cleanerId]: formatDate(bookingDate), // Set the first available date for the current cleaner
      }));
    }
  }, [availableSlots, cleanerId, setSelectedDates]); //eslint-disable-line

  const handleDateSelect = (cleanerId, date) => {
    setSelectedDates((prevState) => ({
      ...prevState,
      [cleanerId]: date, // Track selected date for the specific cleaner/service
    }));
    setSelectedTimeSlot(null); // Reset selected time slot when date changes
  };
  // Handle time slot selection for the specific cleaner/service
  const handleTimeSlotSelect = (time, cleanerId) => {
    setSelectedTimeSlot({
      cleanerId: cleanerId,
      time: time,
    });
  };

  let _slots =
    days.find((day) => day.date === selectedDates[cleanerId])?.slots || [];
  let _bookedSlots = bookedSlots?.[selectedDates[cleanerId]] || [];

  let filteredAvailableSlots = _slots.filter(
    (slot) => !_bookedSlots.includes(slot)
  );

  return (
    <Box mt={2} sx={{ position: "relative" }}>
      {/* Grid layout for larger screens */}
      <Grid
        container
        spacing={2}
        sx={{ mb: 3, display: { xs: "none", sm: "flex" } }}
      >
        {days.map((day, index) => (
          <Grid item key={`${index + day?.date + cleanerId}`}>
            <Button
              variant="contained"
              onClick={() => handleDateSelect(cleanerId, day.date)}
              sx={{
                minWidth: 120,
                backgroundColor:
                  selectedDates[cleanerId] === day.date
                    ? "rgb(16, 57, 150) !important"
                    : "primary.main !important",
                "&:hover": {
                  backgroundColor: "rgb(16, 57, 150)",
                },
                color: "primary.contrastText",
              }}
            >
              <Typography variant="body2">
                {day.date_display + "  (" + day.day.substring(0, 3) + ")"}
              </Typography>
            </Button>
          </Grid>
        ))}
      </Grid>

      {/* Carousel for small screens */}
      <Box sx={{ mb: 3, display: { xs: "block", sm: "none" } }}>
        <Carousel
          navButtonsAlwaysVisible
          cycleNavigation={false}
          autoPlay={false}
          indicators={false}
          index={days.findIndex((day) => day.date === selectedDates[cleanerId])}
          onChange={(newIndex) =>
            handleDateSelect(cleanerId, days[newIndex].date)
          }
          navButtonsProps={{
            style: {
              backgroundColor: "#5093C6",
              // borderRadius:0
            },
          }}
        >
          {days.map((day, index) => (
            <Box
              key={`${index + day?.date + cleanerId}`}
              onClick={() => handleDateSelect(cleanerId, day.date)}
              sx={{
                textAlign: "center",
                padding: "10px 0",
                color: "primary.textColor",
                cursor: "pointer",
              }}
            >
              <Typography variant="body2" sx={{ fontWeight: 900 }}>
                {day.date_display + "  (" + day.day.substring(0, 3) + ")"}
              </Typography>
            </Box>
          ))}
        </Carousel>
      </Box>

      {/* Time slots */}
      <Typography variant="subtitle1" gutterBottom>
        {selectedDates[cleanerId]
          ? `Time slots for ${selectedDates[cleanerId]}`
          : "Select a day to view slots"}
      </Typography>

      {selectedDates[cleanerId] &&
      days.find((day) => day.date === selectedDates[cleanerId]) ? (
        <Grid container spacing={2} justifyContent="flex-start">
          {filteredAvailableSlots.length > 0 ? (
            filteredAvailableSlots.map((time, index) => (
              <Grid item key={`${time + index + cleanerId}`}>
                <Button
                  variant="outlined"
                  onClick={() => handleTimeSlotSelect(time, cleanerId)}
                  sx={{
                    width: 100,
                    backgroundColor:
                      selectedTimeSlot?.["time"] === time &&
                      selectedTimeSlot?.["cleanerId"] === cleanerId
                        ? "#4caf50 !important"
                        : "transparent",
                    color:
                      selectedTimeSlot?.["time"] === time &&
                      selectedTimeSlot?.["cleanerId"] === cleanerId
                        ? "primary.contrastText"
                        : "textColor",
                  }}
                >
                  {time}
                </Button>
              </Grid>
            ))
          ) : (
            <Grid item>
              <Typography>
                No available slots for {selectedDates[cleanerId]}
              </Typography>
            </Grid>
          )}
        </Grid>
      ) : (
        selectedDates[cleanerId] && (
          <Typography>
            No available slots for {selectedDates[cleanerId]}
          </Typography>
        )
      )}
    </Box>
  );
};

export default CleanerListCard;
