import React from "react";
import { Box, Typography, Button, Grid } from "@mui/material";
import styles from "../styles.module.css";
import { Check, Close } from "@mui/icons-material";
import {
  ADD_ON_DATA,
  CAR_TYPE_DATA,
} from "src/pages/rapid-auto-care-landing/helpers";

const PricingSection = () => {
  return (
    <Box className={styles.pricingContainer} id={"pricing-section"}>
      <Typography
        variant="h2"
        align="center"
        gutterBottom
        className={styles["section-heading"]}
        p={2}
        mt={4}
        mb={2}
      >
        Pricing Plans
      </Typography>
      <Grid container spacing={4}>
        {CAR_TYPE_DATA.map((plan, index) => (
          <Grid item xs={12} sm={6} lg={3} key={index}>
            <Box className={styles.pricingCard}>
              {/* <img
                src={plan.designIcon}
                alt="design"
                className={styles["design-icon"]}
              /> */}
              <Typography variant="h5" className={styles.title}>
                {plan.carType}
              </Typography>
              <img
                src={plan.carImage}
                alt={plan.carType}
                className={styles.vehicleImage}
              />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  variant="span"
                  className={styles.servicePrice}
                  sx={{
                    fontSize: "15px !important",
                    textDecoration: "line-through",
                    margin: "0 !important",
                  }}
                >
                  {/* ${(parseFloat(plan.carPrice) + 10).toFixed(2)} */}
                  {plan.originalPrice}
                </Typography>
                Limited Time Offer
              </Box>
              <Typography variant="h2" className={styles.servicePrice}>
                <sup
                  style={{
                    fontSize: "15px",
                    color: "grey",
                    marginRight: "5px",
                    position: "absolute",
                    top: "10px",
                    left: 0,
                  }}
                >
                  $
                </sup>
                {plan.carPrice}
                <sub
                  style={{
                    fontSize: "15px",
                    color: "grey",
                    marginLeft: "5px",
                    position: "absolute",
                    bottom: "10px",
                    right: "25px",
                  }}
                >
                  /service
                </sub>
              </Typography>
              <hr style={{ marginBottom: "30px" }} />
              <Box className={styles.addOnsSection}>
                <Typography variant="h6" sx={{ marginBottom: "15px" }}>
                  Add-ons:
                </Typography>
                {ADD_ON_DATA.map((addOn, idx) => (
                  <Box
                    sx={{
                      marginBottom: "0.8rem",
                    }}
                  >
                    <Box
                      key={addOn?.name + idx}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      {plan?.carType === "Pickup Truck" &&
                      addOn?.label === "trunkCleaning" ? (
                        <Close
                          sx={{
                            color: "black",
                            fontSize: "1.1rem",
                            marginRight: "5px",
                          }}
                        />
                      ) : (
                        <Check
                          sx={{
                            color: "black",
                            fontSize: "1.1rem",
                            marginRight: "5px",
                          }}
                        />
                      )}
                      <Typography
                        className={styles.addOn}
                        sx={{ fontWeight: "bold" }}
                      >
                        {plan?.carType === "Pickup Truck" &&
                        addOn?.label === "trunkCleaning"
                          ? "Trunk Cleaning is Not Available"
                          : addOn.displayName}
                      </Typography>
                    </Box>
                    <Typography
                      className={styles.addOn}
                      sx={{ marginLeft: "22.6px" }}
                    >
                      {plan?.carType === "Pickup Truck" &&
                      addOn?.label === "trunkCleaning" ? (
                        <Box sx={{ height: "38px" }}></Box>
                      ) : (
                        `$ ${addOn.price}`
                      )}
                    </Typography>
                  </Box>
                ))}
              </Box>
              <Button
                variant="contained"
                color="primary"
                className={styles.bookButton}
                onClick={() =>
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth", // Optional for smooth scrolling
                  })
                }
              >
                Book Now
              </Button>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default PricingSection;
