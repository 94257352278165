import { initializeApp } from "firebase/app";

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAZ66IocxYFrZ2gJMSsJPlhb-B223JEq-I",
  authDomain: "sayfayaapp-4a915.firebaseapp.com",
  projectId: "sayfayaapp-4a915",
  storageBucket: "sayfayaapp-4a915.appspot.com",
  messagingSenderId: "521180542158",
  appId: "1:521180542158:web:a11496091c9c2ba6f48c9f",
};
// Initialize Firebase
export const app = initializeApp(firebaseConfig);
