import { showNotification } from "src/components/notification";
import { filter } from "lodash";
import dayjs from "dayjs";

export const handleCopyToClipboard = (text) => {
  navigator.clipboard.writeText(text).then(
    () => {
      showNotification("success", "Copied to clipboard!", 5000);
    },
    (err) => {
      console.error("Failed to copy: ", err);
    }
  );
};

export const getHiddenText = (text) => {
  return "*".repeat(8);
};

export function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.key.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export const calculateTotalBots = (botData) => {
  // Initialize the sum
  let totalNonReusableBots = 0;

  // Iterate through each bot data entry
  botData.forEach((bot) => {
    // Try to convert no_of_non_reusable_bots to an integer
    const nonReusableBots = parseInt(bot.no_of_non_reusable_bots, 10);

    // Check if the conversion was successful
    if (!isNaN(nonReusableBots)) {
      // Add to the total sum
      totalNonReusableBots += nonReusableBots;
    }
  });

  // Return the total sum
  return totalNonReusableBots;
};

export const transformBotInsightData = (data) => {
  const result = [];

  const success = parseInt(data[0].success, 10);
  const failed = parseInt(data[0].failed, 10);

  if (isNaN(success) || isNaN(failed)) {
    throw new Error("Invalid data: success or failed is not a number");
  }

  // Calculate the sum
  const sum = success + failed;

  // Iterate through the botInsightData array (assuming it has only one object)
  data.forEach((item) => {
    // Iterate through each key-value pair in the object
    for (const [key, value] of Object.entries(item)) {
      // Push the new object format into the result array
      result.push({ label: key, value: (value / sum) * 100 });
    }
  });

  return result;
};

export const formattedDate = (date) => {
  if ((date || "") === "") {
    return "";
  }

  return dayjs(date, "DD-MM-YYYY").format("MM/DD/YYYY");
};


export const getMinDate =() => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0'); // Add 1 because months are zero-indexed
  const day = String(today.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}