import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import { CameraAltOutlined, Edit, Save } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import PersonalInfo from "./components/personal-info";
import AddressInfo from "./components/address-info";
import { showNotification } from "src/components/notification";
import { authorizedPost } from "src/api/base";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";

const CustomerProfile = () => {
  const navigate = useNavigate();

  const [userData, setUserData] = useState();
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    setUserData(JSON.parse(window.localStorage.getItem("user")));
  }, []);
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    setUserData({
      ...userData,
      [name]:
        type === "checkbox" ? checked : type === "file" ? files[0] : value,
    });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!userData.name) newErrors.name = "Name is required.";
    if (!userData.email) newErrors.email = "Email is required.";
    if (!userData.phone) newErrors.phone = "Email is required.";

    return newErrors;
  };

  const handleProfileSave = async () => {
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      showNotification("Error", "Please enter valid data in all fields.");
      return;
    }

    try {
      const transformedData = {
        ...userData,
        profile_picture:
          userData?.profile_pic !== undefined
            ? userData?.profile_pic
            : userData?.profile_picture,
      };

      await authorizedPost("/user-profile-update", transformedData);
      const response = await authorizedPost("/user-profile-details", {
        user_id: userData?.user_id,
      });
      window.localStorage.setItem(
        "user",
        JSON.stringify({ ...response?.data, user_id: userData?.user_id })
      );
      setUserData({ ...response?.data, user_id: userData?.user_id });
      console.log("success");
      showNotification("Success", "Profile Data saved Successfully!!");
      setTimeout(() => {
        navigate("/dashboard/customer/profile");
      }, 10000);
    } catch (error) {
      showNotification(
        "Error",
        error?.response?.data?.message ?? "Failed to save Profile Data!"
      );
      console.log(error);
    }
  };

  return (
    <>
      <ToastContainer />
      <Box className={styles["dashboard-title"]}>Profile</Box>
      <Box className={styles["profile-container"]}>
        <Typography
          variant="p"
          sx={{ fontSize: "16px", color: "black", fontWeight: "bold" }}
        >
          My Profile
        </Typography>
        <Box className={styles["first-section"]}>
          <Box className={styles["left-content"]}>
            <Box className={styles["avatar"]}>
              <img
                src={userData?.profile_picture}
                alt="User Profile"
                style={{
                  height: "100px",
                  width: "100px",
                  borderRadius: "100%",
                  objectFit: "contain",
                }}
              />
              {editing && (
                <Box
                  className={styles["upload-profile"]}
                  onClick={() =>
                    document.getElementById("profile_picture").click()
                  }
                >
                  <Typography sx={{ color: "white" }}>
                    {userData?.profile_pic?.name}
                  </Typography>
                  <input
                    style={{
                      height: "inherit",
                      width: "100%",
                    }}
                    type="file"
                    name="profile_pic"
                    id="profile_picture"
                    multiple={false}
                    hidden
                    onChange={handleInputChange}
                  />
                  <CameraAltOutlined
                    sx={{ fontSize: "30px", color: "white" }}
                  />
                </Box>
              )}
            </Box>
            <Box className={styles["profile-info"]}>
              <Typography
                variant="p"
                sx={{
                  fontSize: "16px",
                  color: "black",
                  fontWeight: "bold",
                  display: "block",
                }}
              >
                {userData?.name}
              </Typography>
              <Typography
                variant="p"
                sx={{
                  marginTop: "7.5px",
                  fontSize: "16px",
                  color: "grey",
                  fontWeight: "bold",
                  display: "block",
                }}
              >
                {userData?.user_role}
              </Typography>
            </Box>
          </Box>
          <Box className={styles["right-content"]}>
            <Box
              className={styles[editing ? "filled-btn" : "outlined-btn"]}
              onClick={() => {
                if (editing) {
                  handleProfileSave();
                }
                setEditing(!editing);
              }}
            >
              {editing ? "Save" : "Edit"}{" "}
              {editing ? (
                <Save sx={{ fontSize: "16px", marginLeft: "5px" }} />
              ) : (
                <Edit sx={{ fontSize: "16px", marginLeft: "5px" }} />
              )}
            </Box>
          </Box>
        </Box>
        <Box className={styles["second-section"]}>
          <Typography
            variant="p"
            sx={{
              fontSize: "16px",
              color: "black",
              fontWeight: "bold",
              display: "block",
              marginBottom: "10px",
            }}
          >
            Personal Information
          </Typography>
          <PersonalInfo
            styles={styles}
            userData={userData}
            editing={editing}
            handleInputChange={handleInputChange}
            errors={errors}
          />
        </Box>
        <Box className={styles["second-section"]}>
          <Typography
            variant="p"
            sx={{
              fontSize: "16px",
              color: "black",
              fontWeight: "bold",
              display: "block",
              marginBottom: "10px",
            }}
          >
            Address Information
          </Typography>
          <AddressInfo
            styles={styles}
            userData={userData}
            editing={editing}
            handleInputChange={handleInputChange}
            errors={errors}
          />
        </Box>
      </Box>
    </>
  );
};

export default CustomerProfile;
