import React, { useState } from "react";
import {
  Box,
  Grid,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  Typography,
} from "@mui/material";
import Header from "src/components/header";
import styles from "./style.module.css";
import { showNotification } from "src/components/notification";
import { ToastContainer } from "react-toastify";
import { UploadFile } from "@mui/icons-material";
import CustomInput from "src/components/inputs/custom-input";
import { post } from "src/api/base";
import { useNavigate } from "react-router-dom";
import MapLoader from "src/components/mapinput/MapLoader";
import ReCAPTCHA from "react-google-recaptcha";
import AboutUs from "./components/about-us";
import FooterSection from "../homepage/components/footer";

const BecomeCleaner = () => {
  const navigate = useNavigate();

  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    firstName: "",
    lastName: "",
    phone: "",
    ownCar: "",
    haveCarExperience: "",
    criminalCheck: "",
    petHair: "",
    idFile: null,
    profilePicture: null,
    privacyPolicy: false,
  });

  const [errors, setErrors] = useState({});

  const onRecaptchaChange = (value) => {
    console.log("Captcha value:", value);
    setRecaptchaValue(value); // Save the reCAPTCHA token
  };

  const handleLocationChange = (location) => {
    setFormData({
      ...formData,
      vendor_address: location.address,
      vendor_latitude: location.latitude,
      vendor_longitude: location.longitude,
    });
  };

  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    setFormData({
      ...formData,
      [name]:
        type === "checkbox" ? checked : type === "file" ? files[0] : value,
    });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.email) newErrors.email = "Email is required.";
    if (!formData.password) newErrors.password = "Password is required.";
    if (formData.password !== formData.confirmPassword)
      newErrors.confirmPassword = "Passwords do not match.";
    if (!formData.firstName) newErrors.firstName = "First name is required.";
    if (!formData.lastName) newErrors.lastName = "Last name is required.";
    if (!formData.phone) newErrors.phone = "Phone number is required.";
    if (!formData.ownCar) newErrors.ownCar = "Owning a car is mandatory.";
    if (!formData.haveCarExperience)
      newErrors.haveCarExperience = "Car experience is mandatory.";
    if (!formData.petHair)
      newErrors.petHair = "Please answer the pet hair question.";
    if (!formData.criminalCheck)
      newErrors.criminalCheck = "Please answer the criminal check question.";
    if (!formData.idFile) newErrors.idFile = "ID upload is required.";
    if (!formData.profilePicture)
      newErrors.profilePicture = "Profile Picture is required.";
    if (!formData.privacyPolicy)
      newErrors.privacyPolicy = "You must agree to the Privacy Policy.";

    return newErrors;
  };

  const handleClick = async () => {
    if (!recaptchaValue) {
      showNotification("Error", "Please verify you're not a bot!");
      return;
    }

    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      showNotification("Error", "Please enter valid data in all fields.");
      return;
    }

    try {
      const transformedData = {
        email: formData.email,
        name: formData.firstName + " " + formData.lastName,
        password: formData.password,
        phone: formData.phone,
        have_car: formData.ownCar,
        have_car_experience: formData.haveCarExperience,
        remove_pet_hair: formData.petHair,
        criminal_check: formData.criminalCheck,
        profile_picture: formData.profilePicture,
        upload_id: formData.uploadId,
        vendor_address: formData.vendor_address,
        vendor_latitude: formData.vendor_latitude,
        vendor_longitude: formData.vendor_longitude,
        vendor_radius: 10,
        vendor_unit_number: 1,
      };
      await post("/cleaner-register", transformedData);
      showNotification(
        "Success",
        "Cleaner Registration successful. We'll Contact You Soon!!"
      );
      setTimeout(() => {
        navigate("/");
        handleReset();
      }, 10000);
    } catch (error) {
      showNotification(
        "Error",
        error?.response?.data?.message ?? "Cleaner Registration Failed!"
      );
      console.log(error);
    }
  };

  const handleReset = () => {
    setFormData({
      email: "",
      password: "",
      confirmPassword: "",
      firstName: "",
      lastName: "",
      address: "",
      phone: "",
      ownCar: "",
      haveCarExperience: false,
      petHair: "",
      criminalCheck: "",
      idFile: null,
      profilePicture: null,
      privacyPolicy: false,
    });
    showNotification("Success", "Form successfully cleared!");
  };

  return (
    <>
      <ToastContainer />
      <Box className={styles["landing-section"]}>
        <Box className={styles["image-overlay"]}>
          <Header darkBackground={true} />
          <Box className={styles["become-cleaner-form"]}>
            <Typography variant="h4" className={styles["form-title"]}>
              Become A Cleaner
            </Typography>

            <Typography className={styles["form-sub-title"]}>
              Just a step away to become a partner with us!
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <CustomInput
                  label="First Name"
                  placeholder={"John"}
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  error={errors.firstName}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <CustomInput
                  label="Last Name"
                  placeholder={"Doe"}
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  error={errors.lastName}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <CustomInput
                  label="Email"
                  type="email"
                  placeholder="example@example.com"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  error={errors.email}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <CustomInput
                  label="Phone"
                  placeholder={"Phone Number..."}
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  error={errors.phone}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <CustomInput
                  label="Password"
                  type="password"
                  name="password"
                  placeholder={"Password"}
                  value={formData.password}
                  onChange={handleChange}
                  error={errors.password}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <CustomInput
                  label="Confirm Password"
                  type="password"
                  name="confirmPassword"
                  placeholder={"Repeat Password"}
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  error={errors.confirmPassword}
                />
              </Grid>
              <Grid item xs={12}>
                <div className={styles.container}>
                  <label className={styles.label} htmlFor={"address"}>
                    {"Address"}
                  </label>
                  <MapLoader
                    className={styles["textField"]}
                    style={{
                      width: "100%",
                      padding: "8px",
                      border: "1px solid #EAEDEF",
                      borderRadius: "4px",
                      fontsize: "14px",
                      outline: "none",
                      transition: "border-color 0.3s, box-shadow 0.3s",
                    }}
                    placeholder="Toronto"
                    onChange={handleLocationChange}
                  />
                  {errors.address && (
                    <p className={styles.errorText}>{errors.address}</p>
                  )}
                </div>
              </Grid>
              <Grid item xs={12} lg={6}>
                <FormControl component="fieldset" error={!!errors.ownCar}>
                  <FormLabel component="legend">
                    Do you own a car? Owning a car is mandatory.
                  </FormLabel>
                  <RadioGroup
                    name="ownCar"
                    value={formData.ownCar}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                  {errors.ownCar && (
                    <p style={{ color: "red" }}>{errors.ownCar}</p>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={6}>
                <FormControl component="fieldset" error={!!errors.ownCar}>
                  <FormLabel component="legend">
                    Do you have car experience?
                  </FormLabel>
                  <RadioGroup
                    name="haveCarExperience"
                    value={formData.haveCarExperience}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                  {errors.haveCarExperience && (
                    <p style={{ color: "red" }}>{errors.haveCarExperience}</p>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={6}>
                <FormControl component="fieldset" error={!!errors.petHair}>
                  <FormLabel component="legend">
                    Do you know pet hair?
                  </FormLabel>
                  <RadioGroup
                    name="petHair"
                    value={formData.petHair}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                  {errors.petHair && (
                    <p style={{ color: "red" }}>{errors.petHair}</p>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={6}>
                <FormControl component="fieldset" error={!!errors.petHair}>
                  <FormLabel component="legend">Criminal Check Done?</FormLabel>
                  <RadioGroup
                    name="criminalCheck"
                    value={formData.criminalCheck}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                  {errors.criminalCheck && (
                    <p style={{ color: "red" }}>{errors.criminalCheck}</p>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={6} className={styles["upload-grid"]}>
                <FormLabel component="legend">Upload an ID</FormLabel>
                <Button className={styles["upload-button"]} component="label">
                  <UploadFile
                    sx={{ fontSize: "17px", color: "rgb(154, 152, 152)" }}
                  />
                  <Typography pl={1} fontSize={14} color={"grey"}>
                    Upload ID
                  </Typography>
                  <input
                    type="file"
                    name="idFile"
                    hidden
                    onChange={handleChange}
                  />
                </Button>
                {errors.idFile && (
                  <p style={{ color: "red" }}>{errors.idFile}</p>
                )}
              </Grid>
              <Grid item xs={12} lg={6} className={styles["upload-grid"]}>
                <FormLabel component="legend">Profile Picture</FormLabel>
                <Button className={styles["upload-button"]} component="label">
                  <UploadFile
                    sx={{ fontSize: "17px", color: "rgb(154, 152, 152)" }}
                  />
                  <Typography pl={1} fontSize={14} color={"grey"}>
                    Profile Picture
                  </Typography>
                  <input
                    type="file"
                    name="profilePicture"
                    hidden
                    onChange={handleChange}
                  />
                </Button>
                {errors.profilePicture && (
                  <p style={{ color: "red" }}>{errors.profilePicture}</p>
                )}
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.privacyPolicy}
                      onChange={handleChange}
                      name="privacyPolicy"
                    />
                  }
                  label="I have read and agree to the Privacy Policy."
                />
                {errors.privacyPolicy && (
                  <p style={{ color: "red" }}>{errors.privacyPolicy}</p>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                  onChange={onRecaptchaChange}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "space-around" }}
              >
                <Button
                  className={styles["button-outlined"]}
                  onClick={handleReset}
                >
                  Reset
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className={styles["button"]}
                  onClick={handleClick}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <AboutUs styles={styles} />
      <FooterSection />
    </>
  );
};

export default BecomeCleaner;
