import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, List, ListItem, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const faqData = {
  faqSections: [
    {
      heading: 'General FAQs',
      faqs: [
        {
          question: 'How does the platform work?',
          steps: [
            'Provide Car Details > Select Detailing Package > Address > Schedule a convenient time > Choose Pro > Confirm & Pay.',
          ],
          details: [
            'Car details: We believe in transparent pricing; each package is priced specifically based on car size.',
            'Select Detailing Package: Choose the package that suits you best.',
            'Enter my Address: This is a mobile service, meaning the Pro will come to you at your home or office.',
            'Schedule: Pick the most convenient time that suits you. There is a 1-hour arrival window.',
            'Choose Pro: Select from a list of the best service providers near you.',
            'Confirm and Pay: Here you can confirm all your details and pay.',
          ],
        },
        {
          question: 'What is Panda Hub?',
          answer: 'Panda Hub is a platform that connects you with top-rated car detailers near you.',
        },
      ],
    },
    {
      heading: 'Other FAQs',
      faqs: [
        {
          question: 'Can I detail my RV or boat with Panda?',
          answer: 'At this point no, subscribe to our mailing list for updates.',
        },
        {
          question: 'Is it possible to have my car detailed even if it has window tints?',
          answer: 'Yes, you can.',
        },
      ],
    },
  ],
};

const FAQ = () => {
  return (
    <div>
      {faqData.faqSections.map((section, sectionIndex) => (
        <Box key={sectionIndex} mt={2}>
          <Typography variant="h4" gutterBottom>{section.heading}</Typography>

          {section.faqs.map((faq, faqIndex) => (
            <Accordion key={faqIndex}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="body1">{faq.question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {faq.steps ? (
                  <div>
                    <Typography variant="body2" gutterBottom>{faq.steps[0]}</Typography>
                    <List>
                      {faq.details.map((detail, detailIndex) => (
                        <ListItem key={detailIndex}>
                          <Typography variant="body2">{detail}</Typography>
                        </ListItem>
                      ))}
                    </List>
                  </div>
                ) : (
                  <Typography variant="body2">{faq.answer}</Typography>
                )}
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      ))}
    </div>
  );
};

export default FAQ;
