import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import { Grid, Box, OutlinedInput, Select, MenuItem, Radio, RadioGroup, FormControl } from "@mui/material";
import { styled } from "@mui/system";

const carOptions = [
  { car: "Toyota", price: "$20,000" },
  { car: "Honda", price: "$18,000" },
  { car: "BMW", price: "$40,000" },
];

const FormGrid = styled(Grid)(({ theme }) => ({
  margin: theme.spacing(2, 0),
}));

export default function AddressForm() {
  const [checkedYes, setCheckedYes] = React.useState(false);
  const [checkedNo, setCheckedNo] = React.useState(false);
  const [value, setValue] = React.useState("");
  const [selectedCar, setSelectedCar] = React.useState("");
  const [tips, setTips] = React.useState("");
  const [mats, setMats] = React.useState("");

  return (
    <Grid container spacing={3}>
      {/* Name Fields */}
      <FormGrid item xs={12} md={6}>
        <FormLabel htmlFor="first-name" required>First Name</FormLabel>
        <OutlinedInput id="first-name" name="first-name" placeholder="John" size="small" fullWidth />
      </FormGrid>
      <FormGrid item xs={12} md={6}>
        <FormLabel htmlFor="last-name" required>Last Name</FormLabel>
        <OutlinedInput id="last-name" name="last-name" placeholder="Snow" size="small" fullWidth />
      </FormGrid>

      {/* Address Fields */}
      <FormGrid item xs={12}>
        <FormLabel htmlFor="address1" required>Address Line 1</FormLabel>
        <OutlinedInput id="address1" name="address1" placeholder="Street name and number" size="small" fullWidth />
      </FormGrid>
      <FormGrid item xs={12}>
        <FormLabel htmlFor="address2">Address Line 2</FormLabel>
        <OutlinedInput id="address2" name="address2" placeholder="Apartment, suite, unit, etc." size="small" fullWidth />
      </FormGrid>

      {/* Contact Information */}
      <FormGrid item xs={12} md={6}>
        <FormLabel htmlFor="phone" required>Phone</FormLabel>
        <OutlinedInput id="phone" name="phone" type="tel" placeholder="+91XXXXXXXXXX" size="small" fullWidth />
      </FormGrid>
      <FormGrid item xs={12} md={6}>
        <FormLabel htmlFor="email" required>Email</FormLabel>
        <OutlinedInput id="email" name="email" type="email" placeholder="abc@gmail.com" size="small" fullWidth />
      </FormGrid>

      {/* Location Details */}
      <FormGrid item xs={12} md={6}>
        <FormLabel htmlFor="city" required>City</FormLabel>
        <OutlinedInput id="city" name="city" placeholder="New York" size="small" fullWidth />
      </FormGrid>
      <FormGrid item xs={12} md={6}>
        <FormLabel htmlFor="state" required>State</FormLabel>
        <OutlinedInput id="state" name="state" placeholder="NY" size="small" fullWidth />
      </FormGrid>
      <FormGrid item xs={12} md={6}>
        <FormLabel htmlFor="zip" required>Zip / Postal Code</FormLabel>
        <OutlinedInput id="zip" name="zip" placeholder="12345" size="small" fullWidth />
      </FormGrid>
      <FormGrid item xs={12} md={6}>
        <FormLabel htmlFor="country" required>Country</FormLabel>
        <OutlinedInput id="country" name="country" placeholder="United States" size="small" fullWidth />
      </FormGrid>

      {/* Car & Model */}
      <FormGrid item xs={12} md={6}>
        <FormLabel htmlFor="car-price-select" required>Car & Price</FormLabel>
        <FormControl fullWidth>
          <Select
            id="car-price-select"
            value={selectedCar}
            onChange={(e) => setSelectedCar(e.target.value)}
            input={<OutlinedInput />}
            size="small"
          >
            {carOptions.map((option, index) => (
              <MenuItem key={index} value={option.car}>
                <Box display="flex" justifyContent="space-between" width="100%">
                  <span>{option.car}</span>
                  <span>{option.price}</span>
                </Box>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </FormGrid>
      <FormGrid item xs={12} md={6}>
        <FormLabel htmlFor="car-model" required>Car Model</FormLabel>
        <OutlinedInput id="car-model" name="car-model" placeholder="Car Model" size="small" fullWidth />
      </FormGrid>

      {/* Radio Buttons */}
      <FormGrid item xs={12}>
        <FormControl component="fieldset">
          <FormLabel component="legend" required>Is there a power source available at the location?</FormLabel>
          <RadioGroup
            aria-label="power-source"
            name="power-source"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            row
          >
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
      </FormGrid>

      <FormGrid item xs={12}>
        <FormControl component="fieldset">
          <FormLabel component="legend" required>Is the car in an unsanitary state?</FormLabel>
          <RadioGroup
            aria-label="car-condition"
            name="car-condition"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            row
          >
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
      </FormGrid>

      {/* Checkboxes */}
      <FormGrid item xs={12}>
        <FormControl component="fieldset">
          <FormLabel component="legend" required>Add-ons</FormLabel>
          <Box sx={{ display: "flex", gap: 2 }}>
            <FormControlLabel
              control={<Checkbox checked={checkedYes} onChange={(e) => setCheckedYes(e.target.checked)} />}
              label="Trunk + Rear Glass (interior & exterior) Cleaning $9.99"
            />
            <FormControlLabel
              control={<Checkbox checked={checkedNo} onChange={(e) => setCheckedNo(e.target.checked)} />}
              label="Pet Hair Removal $19.99"
            />
          </Box>
        </FormControl>
      </FormGrid>

      {/* Dropdowns */}
      <FormGrid item xs={12} md={6}>
        <FormLabel htmlFor="tips" required>Tips</FormLabel>
        <FormControl fullWidth>
          <Select
            id="tips"
            value={tips}
            onChange={(e) => setTips(e.target.value)}
            displayEmpty
            input={<OutlinedInput size="small" />}
          >
            <MenuItem value="">
              <em>Select a tip amount</em>
            </MenuItem>
            <MenuItem value="5%">5%</MenuItem>
            <MenuItem value="10%">10%</MenuItem>
            <MenuItem value="15%">15%</MenuItem>
            <MenuItem value="20%">20%</MenuItem>
          </Select>
        </FormControl>
      </FormGrid>

      <FormGrid item xs={12} md={6}>
        <FormLabel htmlFor="mats" required>What kind of mats do you have?</FormLabel>
        <FormControl fullWidth>
          <Select
            id="mats"
            value={mats}
            onChange={(e) => setMats(e.target.value)}
            displayEmpty
            input={<OutlinedInput size="small" />}
          >
            <MenuItem value="">
              <em>Select mat type</em>
            </MenuItem>
            <MenuItem value="Rubber">Rubber Mats</MenuItem>
            <MenuItem value="Carpet">Carpet Mats</MenuItem>
            <MenuItem value="Custom">Custom Mats</MenuItem>
          </Select>
        </FormControl>
      </FormGrid>

      {/* Text Area */}
      <FormGrid item xs={12}>
        <FormLabel htmlFor="additional-info">Additional Information (Optional)</FormLabel>
        <OutlinedInput
          id="additional-info"
          name="additional-info"
          placeholder="Enter additional information here..."
          multiline
          rows={4}
          size="small"
          fullWidth
        />
      </FormGrid>

      {/* Checkbox */}
      <FormGrid item xs={12}>
        <FormControlLabel
          control={<Checkbox name="saveAddress" />}
          label="Use this address for payment details"
        />
      </FormGrid>
    </Grid>
  );
}
