import React from "react";
import { Grid } from "@mui/material";

const AddressInfo = ({ styles, userData, editing, handleInputChange }) => {
  return (
    <Grid container spacing={3}>
      {/* Name Fields */}
      <Grid item xs={12} lg={6}>
        <label htmlFor="country" className={styles["input-label"]}>
          Country
        </label>
        <input
          type="text"
          name="country"
          id="country"
          placeholder={editing ? "Country" : "--------"}
          onChange={handleInputChange}
          value={userData?.country}
          className={styles["country-input"]}
          disabled={!editing}
          style={{
            border: editing ? "1px solid #e5e5e5" : "1px solid transparent",
          }}
          required
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <label htmlFor="city_state" className={styles["input-label"]}>
          City / State
        </label>
        <input
          type="text"
          name="city_state"
          id="city_state"
          placeholder={editing ? "City / State" : "--------"}
          onChange={handleInputChange}
          value={userData?.city_state}
          disabled={!editing}
          style={{
            border: editing ? "1px solid #e5e5e5" : "1px solid transparent",
          }}
          className={styles["text-input"]}
          required
        />
      </Grid>
      {/* <Grid item xs={12} lg={6}>
        <label htmlFor="postal_code" className={styles["input-label"]}>
          Postal Code
        </label>
        <input
          type="text"
          name="postal_code"
          id="postal_code"
          placeholder={editing ? "Postal Code" : "--------"}
          onChange={handleInputChange}
          value={userData?.postal_code}
          disabled={!editing}
          style={{
            border: editing ? "1px solid #e5e5e5" : "1px solid transparent",
          }}
          className={styles["text-input"]}
          required
        />
      </Grid> */}
    </Grid>
  );
};

export default AddressInfo;
