import React, { useState, useEffect } from "react";
import { Grid, Box, Card, Typography, Rating } from "@mui/material";
import { _getVendorReviews } from "src/api/base";
import dayjs from "dayjs";

const ReviewCard = ({ createdDate, ratingStars, ratingContent, user_name }) => {
  const formatDate = (date) => {
    if ((date || "") === "") {
      return "";
    }
    return dayjs(date, "DD-MM-YYYY").format("DD MMM, YYYY");
  };

  return (
    <Card
      elevation={0}
      sx={{
        mb: 4,
        p: 3,
        borderRadius: 2,
        borderColor: "grey.200",
        borderWidth: 1,
        borderStyle: "solid",
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          flexDirection: { xs: "column", sm: "row" }, // Stack on small screens, row on larger
          gap: { xs: 1, sm: 0 }, // Add some gap on smaller screens
        }}
      >
        <Box textAlign={{ xs: "center", sm: "left" }}>
          <Typography variant="h6" fontWeight="bold">
            {user_name || "Anonymous User"} {/* Placeholder for user name */}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {formatDate(createdDate)}
          </Typography>
        </Box>
        <Rating
          value={parseFloat(ratingStars)}
          precision={0.5}
          readOnly
          sx={{ alignSelf: { xs: "center", sm: "flex-start" } }} // Center on small screens
        />
      </Box>
      <Typography variant="body1" mt={2} mb={2}>
        {ratingContent || "No review content available."}
      </Typography>
      {/* <Box display="flex" alignItems="center">
        <IconButton color="primary">
          <ThumbUpAltIcon />
        </IconButton>
        <Typography variant="body2" color="textSecondary" ml={1}>
          Helpful Review
        </Typography>
      </Box> */}
    </Card>
  );
};

const Reviews = ({ vendorId }) => {
  const [vendorList, setVendorList] = useState([]);

  useEffect(() => {
    fetchVendorReviews();
  }, []); // eslint-disable-line

  const fetchVendorReviews = async () => {
    try {
      // Replace with your actual API call
      if (vendorId !== "") {
        const response = await _getVendorReviews(vendorId);
        setVendorList(response.data);
      }
    } catch (error) {
      console.error("Error fetching Vendor Reviews", error);
    }
  };

  return (
    <Box sx={{ maxWidth: 600, mx: "auto", mt: 4, p: 2 }}>
      <Grid item xs={12}>
        <Typography variant="h3" gutterBottom>
          Reviews
        </Typography>
      </Grid>
      {(vendorList || []).map((review) => (
        <ReviewCard
          key={review.id}
          createdDate={review.created_date}
          ratingStars={review.rating_stars}
          ratingContent={review.rating_content}
          user_name={review.user_name}
        />
      ))}
    </Box>
  );
};

export default Reviews;
