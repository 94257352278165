import React from "react";
import { Typography, Box, Link } from "@mui/material";
import ImageHeader from "../../components/image-header";

const TermsCondition = () => {
  return (
    <>
      <ImageHeader heading={"Terms and conditions"} subheading={""} />
      <Box sx={{ p: 4, marginBottom: "20px" }} mb={10}>
        <Typography variant="body1" paragraph>
          Last updated: December 5, 2022
        </Typography>

        <Typography variant="h5" gutterBottom>
          Introduction
        </Typography>
        <Typography variant="body1" paragraph>
          These Terms and Conditions govern the use of the services offered by
          Sayfaya Cleaning Services (“Sayfaya”) on the Sayfaya mobile
          application (the “App“). Such services and App together are
          hereinafter referred to as the “Support.” Please read these Terms and
          Conditions and the Sayfaya Privacy Policy (the “Privacy Policy“)
          carefully before using the Support. Your use of the Support
          constitutes your acceptance of and agreement to all of the terms and
          conditions in these Terms and Conditions and the Privacy Policy, and
          your representation that you are eighteen (18) years of age or older.
          If you object to anything of these Terms and Conditions or the Privacy
          Policy, you are not permitted to use the Support. The Privacy Policy
          is incorporated by reference into these Terms and Conditions, and
          these Terms and Conditions and the Privacy Policy together are
          hereinafter referred to as the “Agreement.”
        </Typography>

        <Typography variant="h5" gutterBottom>
          Sayfaya Is Facilitator between Customer and Cleaners
        </Typography>
        <Typography variant="body1" paragraph>
          Sayfaya is a communications platform for facilitating the connection
          between individuals seeking to hire cleaners or detailers to clean or
          detail their car (“Cleaning”) on-site from their residence
          (“Customer(s)“), and individuals seeking to assist Customers by
          cleaning or detailing Customers’ cars who have authorized the Cleaning
          (“Cleaner(s)“) (such services provided by Cleaners to be hereinafter
          referred to as “Cleaner Services“). The additional fee Customer pays
          that is above the retail price of the Cleaning is for the purpose of
          engaging the Cleaner to perform the Cleaning Services. Customer
          acknowledges and agrees that the Cleaner acts as the agent of the
          Customer in carrying out the Cleaning Services, and that Sayfaya has
          no relationship with Customer other than to provide the Support as set
          forth in this Agreement. Sayfaya does not have control over the
          quality, timing, legality, failure to provide or any other aspect
          whatsoever of the Cleaning Services.
        </Typography>

        <Typography variant="h5" gutterBottom>
          Release
        </Typography>
        <Typography variant="body1" paragraph>
          YOU AGREE THAT NEITHER SAYFAYA NOR ITS AFFILIATES OR LICENSORS IS
          RESPONSIBLE FOR THE FITNESS OR CONDUCT OF ANY CLEANER OR FOR ANY
          CLEANING SERVICES. NEITHER SAYFAYA NOR ITS AFFILIATES OR LICENSORS
          WILL BE LIABLE FOR ANY CLAIM, INJURY OR DAMAGE ARISING IN CONNECTION
          WITH THE ACTS OR OMISSIONS OF ANY CLEANER. IN THE EVENT YOU HAVE A
          DISPUTE WITH ONE OR MORE CLEANERS, YOU HEREBY RELEASE SAYFAYA AND ITS
          OFFICERS, DIRECTORS, EMPLOYEES, SUBSIDIARIES, AFFILIATES, AGENTS AND
          REPRESENTATIVES FROM ANY AND ALL CLAIMS, LIABILITIES, COSTS, INCLUDING
          WITHOUT LIMITATION ATTORNEY’S FEES, LOSS OR DAMAGE OF EVERY KIND AND
          NATURE, KNOWN AND UNKNOWN, ARISING OUT OF OR IN ANY WAY CONNECTED WITH
          SUCH DISPUTES.
        </Typography>

        <Typography variant="h5" gutterBottom>
          Use of Material
        </Typography>
        <Typography variant="body1" paragraph>
          Except as otherwise provided herein, all rights to all materials,
          content, layout, images, text, design (and any underlying software and
          computer codes) of this App are proprietary to Sayfaya and its
          affiliates. Users of this App or of the Sayfaya Support (“you” or
          “User(s)“) may browse the App and may download material displayed on
          the App for non-commercial, lawful, personal use only, provided all
          copyright and other proprietary notices contained on the material so
          downloaded are retained and such information is not modified, copied
          or posted on any networked computer or broadcast in any media. All
          other copying, reproduction, posting on any other website,
          republication, downloading, uploading, encoding, modification,
          translation, public performance or display, distribution or
          transmission of any portion of this App, or the making of any
          derivative works from this App, is prohibited.
        </Typography>

        <Typography variant="h5" gutterBottom>
          User Submissions
        </Typography>
        <Typography variant="body1" paragraph>
          This App may now or in the future permit the submission, sharing or
          publishing of photographs, videos, comments, communications or other
          content submitted by you and other users (“User Submissions“). Other
          than personally identifiable information, which is covered under the
          Sayfaya Privacy Policy available below or at{" "}
          <Link
            href="https://sayfaya.com/privacy-policy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </Link>
          , any User Submission transmitted or posted to this App will be
          considered non-confidential. By submitting any User Submission to
          Sayfaya, you hereby grant Sayfaya a perpetual, worldwide,
          non­exclusive, royalty-free, sublicensable and transferable license to
          use, reproduce, distribute, prepare derivative works of, display and
          perform the User Submission in connection with the App, the Support
          and Sayfaya’s business.
        </Typography>

        <Typography variant="body1" paragraph>
          In connection with User Submissions, you agree that you will not
          submit material that: (i) is copyrighted, subject to privacy or
          publicity rights or otherwise subject to third party proprietary
          rights unless you are the owner of such rights or have permission from
          the owner to submit the material and to grant Sayfaya all of the
          license rights granted herein; (ii) is unlawful, obscene, harassing,
          defamatory, libelous, pornographic, hateful, racially or ethnically
          offensive or is otherwise inappropriate; (iii) could damage the
          reputation of Sayfaya or any third party; or (iv) impersonates another
          person. Sayfaya reserves the right to remove any User Submissions at
          its sole discretion and without notice or liability to you or to any
          other person.
        </Typography>

        <Typography variant="h5" gutterBottom>
          User Account
        </Typography>
        <Typography variant="body1" paragraph>
          You are the sole authorized User of any account you create with
          Sayfaya. You are responsible for maintaining the confidentiality of
          any password or account number provided by you or Sayfaya for
          accessing the Support. You are solely and fully responsible for all
          activities that occur under your password or account. Sayfaya has no
          control over the use of any User’s account and expressly disclaims any
          liability derived therefrom. Should you suspect that any unauthorized
          party may be using your password or account, you will notify Sayfaya
          immediately.
        </Typography>

        <Typography variant="h5" gutterBottom>
          Disclaimer
        </Typography>
        <Typography variant="body1" paragraph>
          USE OF THE SUPPORT IS ENTIRELY AT YOUR OWN RISK. CHANGES ARE
          PERIODICALLY MADE TO THE APP AND MAY BE MADE AT ANY TIME WITHOUT
          NOTICE TO YOU. THE SUPPORT IS PROVIDED ON AN “AS IS” BASIS WITHOUT
          WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT
          LIMITED TO, WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
          PURPOSE AND NON-INFRINGEMENT.
        </Typography>

        <Typography variant="h5" gutterBottom>
          Limitation of Liability
        </Typography>
        <Typography variant="body1" paragraph>
          YOU AGREE THAT SAYFAYA SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT,
          INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES ARISING OUT OF
          OR IN CONNECTION WITH (I) YOUR USE OF THE SUPPORT; (II) THE LIABILITY
          OR FITNESS OF ANY CLEANER OR CLEANING SERVICES; OR (III) IN CONNECTION
          WITH THE PERFORMANCE OF OR BROWSING ON THE APP OR YOUR LINKS TO OTHER
          WEBSITES FROM THIS APP, EVEN IF SAYFAYA HAS BEEN ADVISED OF THE
          POSSIBILITY OF SUCH DAMAGES.
        </Typography>

        <Typography variant="h5" gutterBottom>
          Indemnity
        </Typography>
        <Typography variant="body1" paragraph>
          You agree to defend, indemnify and hold harmless Sayfaya and its
          officers, directors, employees, agents and affiliates (each, an
          “Indemnified Party”), from and against any losses, claims, actions,
          costs, damages, penalties, fines and expenses, including without
          limitation attorneys’ fees and expenses, that may be incurred by an
          Indemnified Party arising out of, relating to or resulting from your
          unauthorized use of the App or from any breach by you of these Terms
          and Conditions.
        </Typography>

        <Typography variant="h5" gutterBottom>
          Termination
        </Typography>
        <Typography variant="body1" paragraph>
          At its sole discretion, Sayfaya may modify or discontinue the App, or
          may modify, suspend or terminate your access to the App or the
          Support, for any reason, with or without notice to you and without
          liability to you or any third party. Even after your right to use the
          Support is terminated, this Agreement will remain enforceable against
          you.
        </Typography>

        <Typography variant="h5" gutterBottom>
          Dispute Resolution
        </Typography>
        <Typography variant="body1" paragraph>
          The parties shall first attempt to resolve any dispute related to this
          Agreement in an amicable manner by mediation with a mutually
          acceptable mediator. If unable to resolve the dispute within 30 days
          of notice of the dispute, the dispute shall be resolved by binding
          arbitration. Any arbitration shall take place in accordance with the
          rules of the American Arbitration Association, and shall be conducted
          in the English language. The parties further agree that any
          arbitration shall be conducted in the city of Boston, Massachusetts.
        </Typography>

        <Typography variant="h5" gutterBottom>
          Governing Law
        </Typography>
        <Typography variant="body1" paragraph>
          These Terms and Conditions shall be governed by and construed in
          accordance with the laws of the Commonwealth of Massachusetts, without
          giving effect to any principles of conflicts of law. You agree that
          any legal action or proceeding arising out of or related to these
          Terms and Conditions shall be brought exclusively in the courts
          located in Boston, Massachusetts.
        </Typography>

        <Typography variant="h5" gutterBottom>
          Contact Us
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions about these Terms and Conditions, please
          contact us at{" "}
          <Link href="mailto:info@sayfaya.com">info@sayfaya.com</Link>.
        </Typography>
      </Box>
    </>
  );
};

export default TermsCondition;
