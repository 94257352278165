import React from "react";
import Carousel from "react-material-ui-carousel";
import { Paper } from "@mui/material";
import {
  Box,
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
  CardMedia,
} from "@mui/material";
import PackageTabs from "./package-tabs";

export default function CarousalCar(props) {
  var items = [
    {
      name: "Tata Nexon, SUV",
      description: "25th July, 2024, Texas USA",
    },
    {
      name: "BMW, 7 Series",
      description: "25th July, 2024, Texas USA",
    },
  ];

  return (
    <Carousel
      indicators={false}
      autoPlay={false}
      cycleNavigation={false}
      navButtonsAlwaysVisible={true}
      swipe={false}
      height={550}
    >
      {items.map((item, i) => (
        <Item key={i} item={item} />
      ))}
    </Carousel>
  );
}

// eslint-disable-next-line
function ElevatedCardDemo() {
  return (
    <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
      <Card sx={{ maxWidth: 345, boxShadow: 10 }}>
        <CardMedia
          component="img"
          height="140"
          image="https://source.unsplash.com/random"
          alt="Unsplash image"
        />
        <Box
          sx={{
            height: "100px",
            background: "linear-gradient(to right, #4CAF50, #FFC107)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="h5" color="white" fontWeight="bold">
            Elevated Card
          </Typography>
        </Box>
        <CardContent>
          <Typography variant="body2" color="text.secondary">
            This is an elevated card with a gradient background and a centered
            title.
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small" color="primary">
            Share
          </Button>
          <Button size="small" color="secondary">
            Learn More
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
}

function Item(props) {
  return (
    <Paper>
      <h2>{props.item.name}</h2>
      <p>{props.item.description}</p>
      <PackageTabs />
      {/* <ElevatedCardDemo/> */}
    </Paper>
  );
}
