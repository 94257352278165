import PropTypes from "prop-types";
import { Box, Drawer } from "@mui/material";
import useResponsive from "../../../hooks/useResponsive";
import Logo from "../../../components/logo";
import Scrollbar from "../../../components/scrollbar";
import NavSection from "../../../components/nav-section";
import navConfig from "./config";
import { NavItem } from "src/components/nav-section/NavSection"; // eslint-disable-line
import SvgColor from "../../../components/svg-color";

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const NAV_WIDTH = 240;

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
  const isDesktop = useResponsive("up", "lg");
  // eslint-disable-next-line no-unused-vars
  const user = {
    title: "User Management",
    path: "/dashboard/user",
    icon: icon("user_svg"),
  };

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box
        sx={{
          height: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Box sx={{ px: 2.5, py: 3, display: "inline-flex" }}>
            <Logo openNav={openNav} />
          </Box>
          <NavSection data={navConfig} />
        </Box>

        <Box sx={{ flexGrow: 1 }} />
        {/* <Box sx={{ px: 1, py: 3, display: "inline-flex" }}>
          <NavItem item={user} />
        </Box> */}
      </Box>
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        backgroundColor: "#1C2536",
        flexShrink: { lg: 0 },
        width: { lg: openNav ? NAV_WIDTH : 0 },
      }}
    >
      {isDesktop ? (
        <Drawer
          open={openNav}
          variant="permanent"
          PaperProps={{
            sx: {
              width: openNav ? NAV_WIDTH : 0,
              bgcolor: "background.default",
              backgroundColor: "#1C2536",
              borderRightStyle: "dashed",
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
