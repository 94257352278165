import React from "react";
import ContactLanding from "./component/header";
import styles from "./style.module.css";
import ContactPage from "./component/contact-page";
import FooterSection from "../homepage/components/footer";

const ContactUs = () => {
  return (
    <>
      <ContactLanding styles={styles} />
      <ContactPage styles={styles} />
      <FooterSection styles={styles} />
    </>
  );
};

export default ContactUs;
