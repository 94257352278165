import ReactDOM from 'react-dom/client';
// import { ReactQueryDevtools } from 'react-query/devtools';
import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
import "./index.css"

import {
    QueryClient,
    QueryClientProvider,
} from 'react-query'

const queryClient = new QueryClient()

const root = ReactDOM.createRoot(document.getElementById('root'));

// root.render(<QueryClientProvider client={queryClient}><App /><ReactQueryDevtools /></QueryClientProvider>);
root.render(<QueryClientProvider client={queryClient}><App /></QueryClientProvider>);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
