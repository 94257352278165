import React from "react";
import styles from "./style.module.css";
import FooterSection from "../homepage/components/footer";
// import AboutFaq from "./component/faq";
import AboutUs from "./component/about-us";
import AboutLanding from "./component/about-landing";
import Testimonials from "../services/rapid-auto-care/components/bookingsection/reviews";
import FAQNew from "./component/faq_new";

const About = () => {
  return (
    <>
      <AboutLanding styles={styles} />
      <AboutUs styles={styles} />
      <FAQNew styles={styles} />
      <Testimonials styles={styles} />
      <FooterSection styles={styles} />
    </>
  );
};

export default About;
