import { convertToISOFormat } from "src/utils/formatTime";

export const transformFormData = (
  formData,
  user_id,
  selectedDates,
  selectedCleaner,
  selectedTimeSlot
) => {
  return {
    order_title: `${formData.firstName} ${formData.lastName}`,
    slot_date: convertToISOFormat(selectedDates?.[selectedCleaner]),
    slot_time: selectedTimeSlot?.time,
    bill_name: `${formData.firstName} ${formData.lastName}`,
    bill_address_1: formData.address1,
    bill_address_2:
      formData?.address2 !== "" ? formData.address2 : formData.address1,
    bill_city: formData.city,
    bill_country: formData.country,
    bill_province: formData.province,
    order_latitude: formData.order_latitude,
    order_longitude: formData.order_longitude,
    bill_phone: formData.phone,
    bill_email: formData.email,
    order_mate_type: formData.mats,
    car_type: formData.car_type,
    car_model: formData.carMakeModel,
    power_is_available: formData.powerSource,
    car_unsanitary_state: formData.carCondition,
    total_amount: formData.total_amount,
    payment_method: formData.paymentMethod,
    order_type: "1",
    hst_charges: 0,
    parking_place: formData.parkingPlace,
    user_id,
    vendor_id: selectedCleaner,
    order_tip: formData?.tips,
    order_additional_info: formData?.additionalInfo,
    add_on_trunk: formData?.addOns?.trunkCleaning,
    add_on_pet: formData?.addOns?.petHairRemoval,
    service_location: formData?.parkingPlace,
  };
};

export const validateForm = (formData) => {
  const newErrors = {};
  const phoneRegex = /^[0-9]{10}$/;

  // Validate name fields
  if (!formData.firstName) newErrors.firstName = "First name is required.";
  if (!formData.lastName) newErrors.lastName = "Last name is required.";

  // Validate address fields
  if (!formData.address1) newErrors.address1 = "Address Line 1 is required.";
  if (!formData.city) newErrors.city = "City is required.";
  if (!formData.country) newErrors.country = "Country is required.";
  if (!formData.province) newErrors.province = "Province is required.";

  // Validate contact details
  if (!formData.phone) {
    newErrors.phone = "Phone number is required.";
  } else if (!phoneRegex.test(formData.phone)) {
    newErrors.phone = "Please enter a valid 10-digit phone number.";
  }
  if (!formData.email) newErrors.email = "Email is required.";

  // Validate car details
  if (!formData.selectedCar)
    newErrors.selectedCar = "Car selection is required.";
  if (!formData.carMakeModel) newErrors.carMakeModel = "Car model is required.";

  // Validate radio button selections
  if (!formData.powerSource)
    newErrors.powerSource = "Please select if power source is available.";
  if (!formData.carCondition)
    newErrors.carCondition = "Please select if the car is unsanitary.";

  if (!formData.mats) newErrors.mats = "Please select a mat type.";
  if (!formData.parkingPlace)
    newErrors.parkingPlace = "Please select Where your car will be parked.";

  return newErrors;
};

export const cardNumberElementOptions = {
  hidePostalCode: true,
  style: {
    complete: {
      outline: "10px solid red !important",
      backgroundColor: "#F9FAFB",
    },
    base: {
      fontSize: "14px",
      lineHeight: "45px",
      iconColor: "#000",
      color: "#000",
      fontWeight: "500",
      border: "10px solid red",
      padding: "2.5px 10px !important",
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": {
        color: "#fce883",
      },
      "::placeholder": {
        color: "grey",
      },
      backgroundColor: "#F9FAFB",
    },
    invalid: {
      iconColor: "red",
      color: "red",
    },
  },
  placeholder: "Card Number",
};

export const steps = [
  "Professional Selection",
  "Booking Details",
  "Confirmation",
];

export const getCarDataByType = (carType) => {
  return (
    CAR_TYPE_DATA.find((option) => option.carType === carType) ?? {
      carPrice: 0,
    }
  );
};

export const calculateTotalPrice = (data) => {
  const carPrice = data?.selectedCar
    ? parseFloat(getCarDataByType(data?.selectedCar).carPrice)
    : 0;
  const trunkCleaningPrice = data?.addOns.trunkCleaning
    ? parseFloat(getAddOnDataByLabel("trunkCleaning").price)
    : 0;
  const petHairRemovalPrice = data?.addOns.petHairRemoval
    ? parseFloat(getAddOnDataByLabel("petHairRemoval").price)
    : 0;
  const matPrice = data?.mats && data?.mats === "both" ? 7.99 : 0;
  const subtotal =
    carPrice + trunkCleaningPrice + petHairRemovalPrice + matPrice;

  var totalTip = 0;

  if (data?.tips && data?.tips === "custom") {
    totalTip = parseFloat(data?.custom_tip);
  } else if (!isNaN(parseFloat(data?.tips?.replace("%", "")))) {
    const tipPercentage = parseFloat(data?.tips?.replace("%", "")) / 100;
    totalTip = subtotal * tipPercentage;
  } else {
    totalTip = 0;
  }

  const totalWithTips = subtotal + totalTip;

  return {
    subtotal,
    totalPrice: totalWithTips?.toFixed(2),
    totalTip: totalTip?.toFixed(2),
  };
};

// Updated Data
export const ADD_ON_DATA = [
  {
    displayName: "Trunk Cleaning",
    price: "7.99",
    label: "trunkCleaning",
  },
  {
    displayName: "Pet Hair Removal",
    price: "19.99",
    label: "petHairRemoval",
  },
];

export const getAddOnDataByLabel = (label) => {
  return ADD_ON_DATA.find((addOnData) => addOnData.label === label);
};

export const MAT_TYPE_DATA = [
  {
    displayName: "Fiber Mats",
    value: "Fiber",
    price: "0",
  },
  {
    displayName: "Plastic Mats",
    value: "Plastic",
    price: "0",
  },
  {
    displayName: "Both Mats",
    value: "Both",
    price: "7.99",
  },
];

export const getMatTypeDataByValue = (value) => {
  return MAT_TYPE_DATA.find((item) => item?.value === value);
};

export const CAR_TYPE_DATA = [
  {
    carType: "Sedan / hatchback",
    originalPrice: "54.99",
    carPrice: "49.99",
    carImage:
      "/assets/images/cars/sedan.png",
    designIcon: "/assets/icons/ic_pie_chart.png",
    examples: [],
  },
  {
    carType: "SUV - 5 Seater",
    originalPrice: "59.99",
    carPrice: "54.99",
    carImage:
      "/assets/images/cars/5_seater.png",
    designIcon: "/assets/icons/ic_flash.png",
    examples: [],
  },
  {
    carType: "Pickup Truck",
    originalPrice: "59.99",
    carPrice: "54.99",
    carImage:
      "/assets/images/cars/pickup_truck.png",
    designIcon: "/assets/icons/ic_warrior.png",
    examples: ["Ram", "Silverado"],
  },
  {
    carType: "SUV - 7 Seater",
    originalPrice: "64.99",
    carPrice: "59.99",
    carImage:
      "/assets/images/cars/7_seater.png",
    designIcon: "/assets/icons/ic_family.png",
    examples: [],
  },
];

export const PARKING_PLACE = [
  {
    value: "street-parking",
    label: "Street Parking (ensure street is traffic free)",
  },
  {
    value: "driveway",
    label: "Driveway",
  },
  {
    value: "open-parking",
    label: "Open Parking",
  },
  {
    value: "covered-parking",
    label: "Covered parking (in case of bad weather)",
  },
];

export const getTipPercentageValue = (data, percentage) => {
  const { subtotal } = calculateTotalPrice(data);
  return ((subtotal * parseInt(percentage)) / 100).toFixed(2);
};

export const customizedTipOption = (data) => {
  return [
    { value: "custom", label: "Custom tip amount" },
    { value: "0", label: "No Tips " },
    { value: "10%", label: `10% ($${getTipPercentageValue(data, 10)})` },
    { value: "15%", label: `15% ($${getTipPercentageValue(data, 15)})` },
    { value: "20%", label: `20% ($${getTipPercentageValue(data, 20)})` },
  ];
};
