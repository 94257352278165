import React from "react";
import {
  Drawer,
  Container,
  Link,
  List,
  ListItem,
  ListItemText,
  Divider,
  Box,
  Typography,
  Grid,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";

const AppDrawer = ({ pages, open, onClose }) => {
  return (
    <>
      <Drawer
        open={open}
        onClose={onClose}
        sx={{
          width: "100%",
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: "100%",
            backgroundColor: "#222",
            color: "white",
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <Grid
          container
          style={{ backgroundColor: "#1b1b1b",height:'100px' }}
          justifyContent="space-between"
          alignItems="center"
          p="20px"
        >
          <Grid>
            <img
              style={{ width: "140px",height:"158px",position:"absolute",marginTop:'-80px',marginLeft:"-30px" }}
              src="/assets/final_logo_white.png"
              alt="logo"
            />
          </Grid>
          <Grid>
            <Box mr={1} onClick={() => onClose(false)}>
              {<CloseIcon sx={{ fontSize: "1.5rem" }} />}
            </Box>
          </Grid>
        </Grid>

        <List p={20}>
          {pages.map((page) => (
            <Link href={page.link} sx={{color:"#fafafa", fontWeight:800,textDecoration:"none"}}>
              <ListItem button key={page.link}>
                <ListItemText style={{ opacity: 0.6 }} primary={page.name} />
              </ListItem>
            </Link>
          ))}
        </List>
        <Divider
          sx={{
            borderColor: "white",
            marginLeft: "20px",
            marginRight: "20px",
            opacity: 0.6,
          }}
        />
        <Grid container style={{ bottom: 0 }}>
          <Box sx={{ py: 5, opacity: 0.6 }}>
            <Container maxWidth="sm" sx={{ textAlign: "left" }}>
              <Typography
                variant="h2"
                component="h1"
                gutterBottom
                sx={{ fontWeight: 300 }}
              >
                Our Office
              </Typography>
              <Typography variant="body1" gutterBottom>
              1881 Brunswick street, PHI, Halifax, B3J 3L8
              </Typography>
              <Typography variant="body1" gutterBottom>
                Phone: (902) 989-4724
              </Typography>
              <Typography variant="body1">
                E-Mail:{" "}
                <Link href="mailto:support@sayfaya.com">support@sayfaya.com</Link>
              </Typography>
            </Container>
          </Box>
        </Grid>
      </Drawer>
    </>
  );
};

export default AppDrawer;
