import * as React from "react";
import PropTypes from "prop-types";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";

const products = [
  {
    name: "Cart SubTotal",
    desc: "Monthly subscription",
    price: "$15.00",
  },
  {
    name: "Discount",
    // desc: 'Included in the Professional plan',
    price: "$1.00",
  },

  {
    name: "GST",
    // desc: 'License',
    price: "$49.99",
  },
];

function Info({ totalPrice }) {
  return (
    <Box sx={{ backgroundColor: "#ffff" ,padding:"20px",borderRadius:"6px",border:"1px solid #90b1ee",boxShadow: "rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset"}}>
      <Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
        Total
      </Typography>
      <Typography variant="h4" gutterBottom>
        {totalPrice}
      </Typography>
      <List disablePadding>
        {products.map((product) => (
          <ListItem key={product.name} sx={{ py: 1, px: 0 }}>
            <ListItemText
              sx={{ mr: 2 }}
              primary={product.name}
              secondary={product.desc}
            />
            <Typography variant="body1" sx={{ fontWeight: "medium" }}>
              {product.price}
            </Typography>
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

Info.propTypes = {
  totalPrice: PropTypes.string.isRequired,
};

export default Info;
