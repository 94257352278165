import React from "react";
import { Box } from "@mui/material";
import styles from "./style.module.css";
import BottomButtons from "./components/bottom-buttons";
import Header from "src/components/header";

const Landing = () => {
  return (
    <Box className={styles["landing-section"]}>
      <Header styles={styles} fromLanding={true}  darkBackground={true}/>
      <BottomButtons styles={styles} />
    </Box>
  );
};

export default Landing;
