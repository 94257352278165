import axios from "axios";

export const HOST =
  process.env.REACT_APP_API_URL ??"https://sayfaya.com/mobile-app/api"?? "http://65.0.181.141/sayfaya/mobile-app/api";

export const AUTH_KEY =
  process.env.AUTH_KEY ?? "589e73379b3db27db8ddb6b1b33fb87a";

export async function buildHeaders() {
  return { "Content-Type": "multipart/form-data" };
}

export async function buildHeadersGet() {
  return {};
}
// Function to convert object data to FormData
function toFormData(body, authenticated = false) {
  const formData = new FormData();
  for (const key in body) {
    if (body.hasOwnProperty(key)) {
      formData.append(key, body[key]);
    }
  }
  if (authenticated) {
    formData.append("auth_key", AUTH_KEY);
  }
  return formData;
}

export async function get(uri, params = {}, options) {
  const opt = { method: "GET", params };
  const response = await axios.get(`${HOST}${uri}`, { ...opt, ...options });
  return response.data;
}

export async function post(uri, body = {}) {
  const formData = toFormData(body);
  return axios
    .post(`${HOST}${uri}`, formData, { headers: buildHeaders() })
    .then((response) => response.data);
}

export async function authorizedGet(uri, params = {}, options) {
  const opt = {
    params,
    headers: buildHeaders(),
  };
  if (options && options.responseType === "blob") {
    opt.headers = {
      ...opt.headers,
      "Content-Type":
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    };
  }
  if (options && opt) {
    return axios
      .get(`${HOST}${uri}`, { ...options, ...opt })
      .then((response) => response.data);
  } else if (options) {
    return axios
      .get(`${HOST}${uri}`, options)
      .then((response) => response.data);
  } else if (opt) {
    return axios.get(`${HOST}${uri}`, opt).then((response) => response.data);
  } else {
    return axios.get(`${HOST}${uri}`).then((response) => response.data);
  }
}

export async function authorizedPost(uri, body = {}, params) {
  const formData = toFormData(body, true);
  const options = {
    params,
    headers: buildHeaders(),
  };
  return axios
    .post(`${HOST}${uri}`, formData, options)
    .then((response) => response.data);
}

export async function authorizedUploadFile({ uri, params, body = {} }) {
  const formData = toFormData(body);
  const options = {
    params,
    headers: { ...buildHeaders(), "Content-Type": "multipart/form-data" },
  };
  return axios
    .post(`${HOST}${uri}`, formData, options)
    .then((response) => response.data);
}

export async function authorizedDelete(uri, params = {}) {
  const options = {
    params,
    headers: buildHeaders(),
  };
  return axios
    .delete(`${HOST}${uri}`, options)
    .then((response) => response.data);
}

export async function authorizedPatch({ uri, params, body = {} }) {
  const formData = toFormData(body);
  const options = {
    params,
    headers: buildHeaders(),
  };
  return axios
    .patch(`${HOST}${uri}`, formData, options)
    .then((response) => response.data);
}

export async function authorizedPostUpload({ uri, body = {}, params }) {
  const formData = toFormData(body);
  const options = {
    params,
    headers: { ...buildHeaders(), "Content-Type": "multipart/form-data" },
  };
  return axios
    .post(`${HOST}${uri}`, formData, options)
    .then((response) => response.data);
}

export async function authorizedPatchUpload({ uri, body = {}, params }) {
  const formData = toFormData(body);
  const options = {
    params,
    headers: buildHeaders(),
  };
  return axios
    .patch(`${HOST}${uri}`, formData, options)
    .then((response) => response.data);
}

export const normalGet = ({ uri, params = {}, options }) => {
  const opt = {
    params,
    headers: buildHeadersGet(),
  };
  return axios
    .get(`${HOST}/${uri}`, { ...options, ...opt })
    .then((response) => response.data);
};

export const formatDate = (dateStr) => {
  const [year, month, day] = dateStr.split("-");
  return `${parseInt(day)}-${parseInt(month)}-${year}`;
};

export const _getCleanerList = (filters) => {
  // Destructure the necessary fields
  const { order_latitude, order_longitude, date } = filters;

  // Only add non-empty values to params
  const params = {};
  if (order_latitude) params.latitude = order_latitude;
  if (order_longitude) params.longitude = order_longitude;
  if (date) params.date = formatDate(date);

  return normalGet({ uri: "cleaner-listing",params });
};

export const _getCarMetaData = () => {
  return normalGet({ uri: "get-metadata" });
};


export const _getVendorReviews = (vendorId) => {
  const params = {};
  params.vendor_id = vendorId;
  return normalGet({ uri: "get-review-list" , params});
};
