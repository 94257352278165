import React, { useEffect, useState } from "react";
import { Box, Grid, Paper } from "@mui/material";
import styles from "./style.module.css";
import { showNotification } from "src/components/notification";
import { authorizedPost } from "src/api/base";
import { styled } from "@mui/material/styles";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  ...theme.applyStyles("dark", {
    backgroundColor: "#1A2027",
  }),
}));

const CustomerHistory = () => {
  const [userData, setUserData] = useState(); // eslint-disable-line

  useEffect(() => {
    const user = JSON.parse(window.localStorage.getItem("user"));
    setUserData(user);
    getBookingList(user?.user_id);
  }, []);

  // useEffect(() => {}, []);
  const getBookingList = async (id) => {
    try {
      await authorizedPost("/customer-booking-list", {
        user_id: id,
      });

      showNotification("Success", "Service Booking Successfully Registered.");
    } catch (error) {
      showNotification(
        "Error",
        error?.response?.data?.message ?? "Service Booking unsuccessful!"
      );
      console.log(error);
    }
  };
  return (
    <Box className={styles["main-box"]}>
      <Box className={styles["page-title"]}>Order History</Box>
      <Box className={"page-content"}>
        {/* <Grid container spacing={2} boxSizing={"border-box"} justify="space-around">
          <Grid item xs={12} md={8} className={styles["left-content"]}></Grid>
          <Grid item xs={12} md={4} className={styles["right-content"]}></Grid>
        </Grid> */}
        <Grid container columnGap={3}>
          <Grid xs={8}>
            <Item>xs=8</Item>
          </Grid>
          <Grid xs={4}>
            <Item>xs=4</Item>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default CustomerHistory;
