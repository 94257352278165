import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import {
  Grid,
  Box,
  OutlinedInput,
  Select,
  MenuItem,
  Radio,
  RadioGroup,
  FormControl,
  Tooltip,
  Typography,
  Link,
} from "@mui/material";
import { styled } from "@mui/system";
import { Info } from "@mui/icons-material";
import {
  ADD_ON_DATA,
  CAR_TYPE_DATA,
  customizedTipOption,
  MAT_TYPE_DATA,
  PARKING_PLACE,
} from "../helpers";

const FormGrid = styled(Grid)(({ theme }) => ({
  margin: theme.spacing(0, 0),
  padding: theme.spacing(0, 0, 0, 0),
}));

export default function BookingDetails({ formData, setFormData, errors }) {
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // eslint-disable-next-line
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  const handleAddOnChange = (e) => {
    const { name, checked } = e.target;
    setFormData({
      ...formData,
      addOns: { ...formData.addOns, [name]: checked },
    });
  };

  return (
    <Grid container spacing={3} mb={5}>
      {/* Name Fields */}
      <FormGrid item xs={12} md={6}>
        <FormLabel htmlFor="firstName" required>
          First Name
        </FormLabel>
        <OutlinedInput
          id="firstName"
          name="firstName"
          placeholder="John"
          size="small"
          fullWidth
          value={formData.firstName}
          error={errors.firstName}
          onChange={handleInputChange}
        />
      </FormGrid>
      <FormGrid item xs={12} md={6}>
        <FormLabel htmlFor="lastName" required>
          Last Name
        </FormLabel>
        <OutlinedInput
          id="lastName"
          name="lastName"
          placeholder="Snow"
          size="small"
          fullWidth
          value={formData.lastName}
          error={errors.lastName}
          onChange={handleInputChange}
        />
      </FormGrid>

      {/* Address Fields */}
      <FormGrid item xs={12}>
        <FormLabel htmlFor="address1" sx={{ fontWeight: "bold" }} required>
          Where will cleaning happen
        </FormLabel>
        <OutlinedInput
          id="address1"
          name="address1"
          placeholder="Street name and number"
          size="small"
          fullWidth
          value={formData.address1}
          error={errors.address1}
          onChange={handleInputChange}
          disabled
        />
      </FormGrid>
      {/* <FormGrid item xs={12}>
        <FormLabel htmlFor="address2">Address Line 2</FormLabel>
        <OutlinedInput
          id="address2"
          name="address2"
          placeholder="Apartment, suite, unit, etc."
          size="small"
          fullWidth
          value={formData.address2}
          error={errors.address2}
          onChange={handleInputChange}
        />
      </FormGrid> */}

      {/* Contact Information */}
      <FormGrid item xs={12} md={6}>
        <FormLabel htmlFor="phone" required>
          Phone
        </FormLabel>
        <OutlinedInput
          id="phone"
          name="phone"
          type="tel"
          placeholder="9999999999"
          size="small"
          fullWidth
          value={formData.phone}
          error={errors.phone}
          onChange={handleInputChange}
        />
      </FormGrid>
      <FormGrid item xs={12} md={6}>
        <FormLabel htmlFor="email" required>
          Email
        </FormLabel>
        <OutlinedInput
          id="email"
          name="email"
          type="email"
          placeholder="abc@gmail.com"
          size="small"
          fullWidth
          value={formData.email}
          error={errors.email}
          onChange={handleInputChange}
        />
      </FormGrid>

      {/* Location Details */}
      <FormGrid item xs={12} md={6}>
        <FormLabel htmlFor="city" required>
          City
        </FormLabel>
        <OutlinedInput
          id="city"
          name="city"
          placeholder="New York"
          size="small"
          fullWidth
          value={formData.city}
          error={errors.city}
          onChange={handleInputChange}
        />
      </FormGrid>
      {/* Province */}
      <FormGrid item xs={12} md={6}>
        <FormLabel htmlFor="province" required>
          Province
        </FormLabel>
        <OutlinedInput
          id="province"
          name="province"
          placeholder="Ontario"
          size="small"
          fullWidth
          value={formData.province}
          error={errors.province}
          onChange={handleInputChange}
        />
      </FormGrid>
      {/* Country */}
      <FormGrid item xs={12} md={6}>
        <FormLabel htmlFor="country" required>
          Country
        </FormLabel>
        <OutlinedInput
          id="country"
          name="country"
          placeholder="United States"
          size="small"
          fullWidth
          value={formData.country}
          error={errors.country}
          onChange={handleInputChange}
        />
      </FormGrid>

      {/* Place Of Parking */}
      <FormGrid item xs={12} md={6}>
        <FormLabel htmlFor="carMakeModel" required>
        Where will we be doing the service?
        </FormLabel>
        <FormControl fullWidth>
          <Select
            id="parkingPlace"
            name="parkingPlace"
            value={formData.parkingPlace}
            onChange={handleInputChange}
            input={<OutlinedInput />}
            size="small"
            error={errors.parkingPlace}
          >
            {PARKING_PLACE.map((option, index) => (
              <MenuItem key={index} value={option.value}>
                <Box display="flex" justifyContent="space-between" width="100%">
                  <span>{option.label}</span>
                </Box>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </FormGrid>

      {/* Car & Model */}
      <FormGrid item xs={12} md={6}>
        <FormLabel htmlFor="carMakeModel" required>
          Car Make Model
        </FormLabel>
        <OutlinedInput
          id="carMakeModel"
          name="carMakeModel"
          readOnly={true}
          placeholder="Car Model"
          size="small"
          fullWidth
          value={formData.carMakeModel}
          onChange={handleInputChange}
          error={errors.carMakeModel}
        />
      </FormGrid>
      {/* Car Type & Price */}
      <FormGrid item xs={12} md={6}>
        <FormLabel htmlFor="selectedCar" required>
          Car & Price
        </FormLabel>
        <FormControl fullWidth>
          <Select
            id="selectedCar"
            name="selectedCar"
            value={formData.selectedCar}
            onChange={handleInputChange}
            input={<OutlinedInput />}
            size="small"
            error={errors.selectedCar}
          >
            {CAR_TYPE_DATA.map((option, index) => (
              <MenuItem key={index} value={option.carType}>
                <Box display="flex" justifyContent="space-between" width="100%">
                  <span>{option.carType}</span>
                  <span>
                    (
                    <span style={{ textDecoration: "line-through" }}>
                      {/* ${(parseFloat(option.carPrice) + 10).toFixed(2)} */}
                      {option.originalPrice}
                    </span>
                    ){"  "}${option.carPrice}
                  </span>
                </Box>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </FormGrid>

      {/* Radio Buttons */}
      {/* Power */}
      <FormGrid item xs={12} md={6}>
        <FormControl component="fieldset" error={!!errors.powerSource}>
          <FormLabel component="legend">
            Is there a power source available at the location? (Optional)
          </FormLabel>
          <RadioGroup
            aria-label="powerSource"
            name="powerSource"
            value={formData.powerSource}
            onChange={handleInputChange}
            row
          >
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
      </FormGrid>

      {/* Unsanitary */}
      <FormGrid item xs={12} md={6}>
        <FormControl component="fieldset" error={!!errors.carCondition}>
          <Box sx={{ display: "flex" }}>
            <FormLabel component="legend">
              Is the car in an unsanitary state? (Required)
            </FormLabel>
            <Tooltip title="(Example pukes, feces, condoms and filthy garbage)">
              <Info sx={{ fontSize: "16px", marginLeft: "10px" }} />
            </Tooltip>
          </Box>
          <RadioGroup
            aria-label="carCondition"
            name="carCondition"
            value={formData.carCondition}
            onChange={handleInputChange}
            row
          >
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
      </FormGrid>

      {/* Add Ons Checkboxes */}
      <FormGrid item xs={12}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Add-ons</FormLabel>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "column", lg: "row" },
              gap: 2,
            }}
          >
            {ADD_ON_DATA.map((item) =>
              item?.label === "trunkCleaning" &&
              formData?.selectedCar === "Pickup Truck" ? (
                <></>
              ) : (
                <FormControlLabel
                  key={item?.label}
                  control={
                    <Checkbox
                      name={item?.label}
                      checked={formData.addOns[item?.label]}
                      onChange={handleAddOnChange}
                    />
                  }
                  label={`${item?.displayName} $${item?.price}`}
                />
              )
            )}
          </Box>
        </FormControl>
      </FormGrid>

      {/* Tips */}
      <FormGrid item xs={12} md={6}>
        <FormLabel htmlFor="tips">Tips</FormLabel>
        <FormControl fullWidth>
          <Select
            id="tips"
            name="tips"
            value={formData.tips}
            onChange={handleInputChange}
            input={<OutlinedInput size="small" />}
            inputProps={{ "aria-label": "Without label" }}
            displayEmpty
            renderValue={(value) => {
              if (!value) {
                return (
                  <Typography color="gray">Select a tip amount</Typography>
                );
              }
              return value;
            }}
          >
            {customizedTipOption(formData).map((option) => (
              <MenuItem value={option.value}>{option.label}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </FormGrid>

      {/* Tips Value */}
      {formData?.tips === "custom" && (
        <FormGrid item xs={12} md={6}>
          <FormLabel htmlFor="country" required>
            Tip Amount in $
          </FormLabel>
          <OutlinedInput
            id="tips"
            name="custom_tip"
            placeholder="45"
            size="small"
            fullWidth
            value={formData.custom_tip}
            error={errors.custom_tip}
            onChange={handleInputChange}
          />
        </FormGrid>
      )}

      {/* Mats */}
      <FormGrid item xs={12} md={6}>
        <FormLabel htmlFor="mats" required>
          What kind of mats do you have?
        </FormLabel>
        <FormControl fullWidth>
          <Select
            id="mats"
            name="mats"
            value={formData.mats}
            onChange={handleInputChange}
            displayEmpty
            error={errors.mats}
            input={<OutlinedInput size="small" />}
          >
            <MenuItem value="">
              <em>Select mat type</em>
            </MenuItem>
            {MAT_TYPE_DATA.map((item) => (
              <MenuItem value={item?.value} key={item?.displayName}>
                {item?.displayName}{" "}
                {item?.price !== "0" && `${item?.price}$ Extra Charges`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </FormGrid>

      {/* Additional Info Text area */}
      <FormGrid item xs={12}>
        <FormLabel htmlFor="additionalInfo">
          Additional Information (Optional)
        </FormLabel>
        <OutlinedInput
          id="additionalInfo"
          name="additionalInfo"
          placeholder="Enter additional information here..."
          multiline
          rows={4}
          size="small"
          fullWidth
          value={formData.additionalInfo}
          onChange={handleInputChange}
        />
      </FormGrid>

      {/* Privacy Policy, TnCs Checkbox */}
      <FormGrid item xs={12} mb={5}>
        <FormControlLabel
          control={
            <Checkbox
              name="saveAcceptTerms"
              checked={formData.saveAcceptTerms}
              onChange={handleCheckboxChange}
            />
          }
          label={
            <Typography>
              I have read and agree to the{" "}
              <Link href="/privacy-policy" target="_blank" rel="noopener">
                privacy policy
              </Link>
              {" - "}
              <Link href="/terms-and-conditions" target="_blank" rel="noopener">
                terms & conditions
              </Link>
              <Typography component="span" color="error">
                {" *"}
              </Typography>
            </Typography>
          }
        />
      </FormGrid>
    </Grid>
  );
}
