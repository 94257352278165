import React from "react";
import Carousel from "react-material-ui-carousel";
import { Typography, Box } from "@mui/material";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";

const testimonials = [
  {
    quote:
      "This was my first time using a mobile detailing service and I was so impressed! Sayfaya did an amazing job getting the pet hair out of my car, which I have tried to do many times with no luck. Maria, my detailer was very polite and did an excellent job! I have been recommending Sayfaya to family/friends and will be using this service again! The process to book is also very easy and the cost for services is more than affordable! Thank you again Sayfaya!",
    name: "Jane D",
  },
  {
    quote:
      "Very detailed oriented. Your attention to detail and dedication shine through in every corner of the car. Sayfaya 🙂 Thank you for making it look and feel brand new again. Your work is truly top-notch! 👍",
    name: "Abhi",
  },
  {
    quote:
      "Excellent first time experience, professional treatment. Sayfaya reached out and communicated clearly in advance. Did a great job, very thorough, and got everything Sparkling without an overwhelming smell of chemicals after.",
    name: "Anas",
  },
  {
    quote:
      "Very highly recommended! My car was transformed from disgustingly dirty to spotless. Very professional and top notch service 👌",
    name: "Treena Niemi",
  },
  {
    quote: "Sayfaya was really efficient, friendly, and thorough!",
    name: "Sushant Sutrave",
  },
];

function TestimonialCarousel() {
  return (
    <Carousel
      //   navButtonsAlwaysVisible
      indicators={true}
      autoPlay={true}
      interval={5000}
      sx={{ mt: "40px", mb: "60px" }}
    >
      {testimonials.map((item, i) => (
        <Box
          key={i}
          p={4}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Typography
            variant="h2"
            align="center"
            color="textColor"
            gutterBottom
          >
            What people say!
          </Typography>

          <FormatQuoteIcon
            sx={{ fontSize: 50, color: "primary.main", mb: 2 }}
          />

          <Typography
            variant="body1"
            align="center"
            sx={{ fontStyle: "italic", color: "text.secondary", maxWidth: 800 }}
          >
            {item.quote}
          </Typography>

          <Typography
            variant="body2"
            align="center"
            sx={{ mt: 2, fontWeight: "bold", color: "text.secondary" }}
          >
            {item.name}
          </Typography>
        </Box>
      ))}
    </Carousel>
  );
}

export default TestimonialCarousel;
