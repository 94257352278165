import React from "react";
import ContactForm from "./contact-form";

const ContactInfo = ({ styles }) => {
  return (
    <div className={styles["infoBox"]}>
      <div className={styles["infoItem"]}>
        <img
          src="/assets/cleaner/location.svg"
          alt="Address"
          className={styles["infoImage"]}
        />
        <p className={styles["infoText"]}>
          1881 Brunswick street
          <br />
          Halifax, B3J 3L8
        </p>
      </div>
      <div className={styles["infoItem"]}>
        <img
          src="/assets/cleaner/online-world.svg"
          alt="Phone"
          className={styles["infoImage"]}
        />
        <p className={styles["infoText"]}>
          <span className={styles["redText"]}>
            +1 (902) 989-4724
          </span>
          <br />
          <span className={styles["redText"]}>
            +1 (902) 402-1121
          </span>
        </p>
      </div>
      <div className={styles["infoItem"]}>
        <img
          src="/assets/cleaner/email.svg"
          alt="Email"
          className={styles["infoImage"]}
        />
        <p className={styles["redText"]}>
          support@sayfaya.com
        </p>
      </div>
    </div>
  );
};

const ContactPage = ({ styles }) => {
  return (
    <div className={styles["contactContainer"]}>
      <h1 className={styles["headerText"]}>Want to Contact With Us?</h1>
      <div className={styles["divider"]}></div>
      <p className={styles["description"]}>
        We'd love to hear from you! Whether you have a question, need
        assistance, or want to share your feedback,
        <br /> feel free to get in touch with us. Our team is here to help you
        with anything you need.{" "}
      </p>
      <ContactInfo styles={styles} />
      <ContactForm styles={styles} />
    </div>
  );
};

export default ContactPage;
