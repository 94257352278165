import React from "react";
import { Box, Typography, Grid, Link } from "@mui/material";
import HowWeWork from "./how-we-work";
import WhyChooseUs from "./why-chooseus";
import TestimonialCarousel from "./reviews";

// eslint-disable-next-line
const DownloadSection = () => {
  return (
    <>
      <Box
        sx={{
          backgroundImage: "url(/assets/cleaner/application.png)",
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "100%",
          height: { xs: "auto", sm: "80vh", md: "85vh" },
          mb: 2,
          p: { xs: 1, sm: 2 },
          display: "flex",
          alignItems: "center",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={7} mt={2}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              padding={{ xs: 1, sm: 3 }}
            >
              <Box
                sx={{
                  margin: "auto",
                  width: { xs: "90%", sm: "70%" },
                  padding: "20px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: { xs: "24px", sm: "32px", md: "39px" },
                    fontWeight: "700",
                    marginBottom: "20px",
                    color: "#171717",
                  }}
                >
                  Download Our App
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: "13px", sm: "15px" },
                    fontWeight: "500",
                    color: "#171717",
                    marginBottom: "20px",
                  }}
                >
                  Best app that will help you to wash your car by professionals,
                  and many more features for your safety.
                </Typography>

                {/* Submenu */}
                <Box
                  sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "grey" }}
                >
                  <Typography sx={{ marginBottom: "10px", fontSize: "13px" }}>
                    — Get your car washed by professionals with ease.
                  </Typography>
                  <Typography sx={{ marginBottom: "10px", fontSize: "13px" }}>
                    — Safe and reliable services at your doorstep.
                  </Typography>
                </Box>
                <Grid
                  container
                  spacing={2}
                  mb={2}
                  mt={2}
                  justifyContent={{ xs: "center", sm: "start" }}
                >
                  <Grid item>
                    <Link
                      href="https://apps.apple.com/in/app/sayfaya/id6504701638"
                      target="_blank"
                      sx={{ mb: 1 }}
                    >
                      <img
                        src="https://sayfaya.com/wp-content/uploads/2024/06/apple-1024x304.png"
                        alt="App Store"
                        style={{ width: "150px", maxWidth: "100%" }}
                      />
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link
                      href="https://play.google.com/store/apps/details?id=com.sayfaya.app"
                      target="_blank"
                      sx={{ mb: 1 }}
                    >
                      <img
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/78/Google_Play_Store_badge_EN.svg/1280px-Google_Play_Store_badge_EN.svg.png"
                        alt="Google Play"
                        style={{ width: "150px", maxWidth: "100%" }}
                      />
                    </Link>
                  </Grid>
                </Grid>
                <Link
                  href="https://www.bbb.org/ca/ns/halifax/profile/auto-detailing/sayfaya-0087-89879"
                  target="_blank"
                >
                  <Box
                    component="img"
                    src="https://sayfaya.com/wp/wp-content/uploads/2024/07/bbb-accredited-business-seeklogo-2-cropped.svg"
                    alt="BBB Accredited Business"
                    width={{ xs: 120, sm: 200 }}
                    mb={2}
                    ml={{ xs: 0, sm: 10 }}
                  />
                </Link>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={5}></Grid>
        </Grid>
      </Box>
    </>
  );
};

const MainComponent = ({ styles }) => {
  return (
    <Box>
      <TestimonialCarousel />
      <HowWeWork />
      <WhyChooseUs styles={styles} />
    </Box>
  );
};

export default MainComponent;
