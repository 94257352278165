import React from "react";
import {
  Chip,
  Tooltip,
  Box,
  Grid,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  Container,
  Divider,
} from "@mui/material";
import image from "../../../assets/img/rapid_auto.jpeg";
import { useNavigate } from "react-router-dom";
import AutorenewIcon from "@mui/icons-material/Autorenew"; // For "Regular upkeep"
import LocalTaxiIcon from "@mui/icons-material/LocalTaxi"; // For "Rideshare driver"
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { CAR_TYPE_DATA } from "src/pages/rapid-auto-care-landing/helpers";

const ChipExample = () => {
  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12} sm="auto">
        <Typography variant="h6" gutterBottom fontWeight={600}>
          Best For:
        </Typography>
      </Grid>
      <Grid item xs={12} sm="auto">
        <Chip
          icon={<AutorenewIcon sx={{ color: "#ADD8E6 !important" }} />}
          label="Regular upkeep"
          variant="outlined"
          style={{ padding: "4px 8px", fontWeight: 500 }}
        />
      </Grid>
      <Grid item xs={12} sm="auto">
        <Chip
          icon={<LocalTaxiIcon sx={{ color: "#800000 !important" }} />}
          label="Rideshare driver"
          variant="outlined"
          style={{ padding: "4px 8px", fontWeight: 500 }}
        />
      </Grid>
    </Grid>
  );
};

const RapidAutoCare = ({ fromInfo }) => {
  const navigate = useNavigate();
  return (
    <Container>
      {/* "What is it?" Section */}
      <Grid container alignItems="center">
        {/* Image Section */}
        <Grid item xs={12}>
          {fromInfo ? (
            <></>
          ) : (
            <>
              <Typography variant="h3" gutterBottom>
                Rapid Auto Care
              </Typography>
              <Divider gutterBottom />
            </>
          )}

          <Typography mt={2} variant="h5" gutterBottom>
            What is Rapid Auto Care?
          </Typography>

          <Typography variant="body1" gutterBottom>
            Rapid Auto Care is the perfect interior cleaning service for regular
            maintenance & upkeep. It includes the basics such as:
          </Typography>
          <List>
            <ListItem>
              <ListItemText primary="• Thorough interior dry vacuum" />
            </ListItem>
            <ListItem>
              <ListItemText primary="• Dashboard, console, and door wipe-down" />
            </ListItem>
            <ListItem>
              <ListItemText primary="• Window cleaning (interior + exterior)" />
            </ListItem>
            <ListItem>
              <ListItemText primary="• Cleaning of mats" />
            </ListItem>
            <ListItem>
              <ListItemText primary="• Trash Removal" />
            </ListItem>
          </List>
          <Box mb={3}>
            <ChipExample />
          </Box>
          <Typography variant="h6" gutterBottom>
            Mobile car detailing made affordable, starting from{" "}
            <b>
              <strike>{CAR_TYPE_DATA[0].originalPrice}</strike>
            </b>{" "}
            → <b>{CAR_TYPE_DATA[0].carPrice}</b>.
          </Typography>
          <Typography variant="body1">
            Expected Service Time: 70-90 minutes.
          </Typography>
        </Grid>
      </Grid>

      <Grid container mt={3} justifyContent="center" style={{ height: 250 }}>
        <Grid item xs={12} sm={12} md={6}>
          <Box>
            <img
              src={image}
              alt="Car Cleaning"
              style={{ maxWidth: "100%", height: 250, borderRadius: "20px" }}
            />
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} md="auto">
          <Box
            sx={{
              display: "flex",
              justifyContent: { xs: "flex-start" },
              marginTop: { xs: 2, sm: 2, md: 0 },
              marginLeft: { xs: 0, sm: 0, md: 2 },
            }}
          >
            <Tooltip
              sx={{ textAlign: "center", padding: "20px" }}
              title={
                "This vendor has undergone a thorough criminal record check and has been verified as a safe and trustworthy partner."
              }
            >
              <Chip
                icon={<CheckCircleIcon style={{ color: "white" }} />}
                label="Verified"
                sx={{
                  backgroundColor: "rgb(16, 57, 149)", // Blue background
                  color: "white", // White text color
                  fontWeight: "bold",
                }}
              />
            </Tooltip>
            <Chip
              icon={<CheckCircleIcon style={{ color: "white" }} />}
              label="Insured"
              sx={{
                backgroundColor: "#008000", // Blue background
                color: "white", // White text color
                fontWeight: "bold",
                marginLeft: 2,
              }}
            />
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ margin: { xs: "80px 0", sm: "80px 0", md: "32px 0" } }} />

      {fromInfo ? (
        <>
          <Typography variant="h5" gutterBottom>
            Rapid Auto Care Details
          </Typography>
          <Typography variant="body1" gutterBottom>
            Rapid Auto Care is the flagship service of SayFaya. This is the
            service that every busy car owner dreams of.
          </Typography>
          <Typography variant="body1" gutterBottom>
            A SayFaya vendor comes to your home or work and cleans your vehicle
            in 70-90 minutes. It's a long but efficient process, leaving you
            with a fresh and clean car, ready to be taken out.
          </Typography>

          <Typography variant="h6" gutterBottom style={{ marginTop: "16px" }}>
            What's Included:
          </Typography>
          <List>
            <ListItem>
              <ListItemText primary="• Trash Removal – All trash is gathered from floorboards, cup holders, door pockets, and seat pockets and is disposed of." />
            </ListItem>
            <ListItem>
              <ListItemText primary="• Full Vacuum – Vacuuming of the legroom area, mats, and seats. Removal of dust/debris from mats/seats." />
            </ListItem>
            <ListItem>
              <ListItemText primary="• Interior/Exterior Glass Cleaning – Cleaning of the interior and exterior glass and windows using a commercial glass cleaner." />
            </ListItem>
            <ListItem>
              <ListItemText primary="• All Panel Wipe Down – Cleaning and wiping down of door panels, center console, dashboard, and steering wheel." />
            </ListItem>
          </List>

          <Typography variant="body2">
            Additional services: Trunk + Rear Glass (interior & exterior)
            Cleaning and Pet Hair Removal.
          </Typography>
          <Typography variant="body2">
            (Exterior Cleaning, Car Shampooing, Stain Removal, Odor Removal, and
            Steaming are not included*)
          </Typography>

          {/* Pricing Section */}
          <Typography variant="h5" gutterBottom style={{ marginTop: "16px" }}>
            Pricing
          </Typography>
          <table style={{ marginLeft: 16, borderCollapse: "collapse" }}>
            <tbody>
              {CAR_TYPE_DATA.map((i) => (
                <tr key={i.carType}>
                  <td style={{ marginRight: 30, width: 150, border: "none" }}>
                    {i.carType}
                  </td>
                  <td style={{ border: "none" }}>
                    (
                    <span style={{ textDecoration: "line-through" }}>
                      {/* ${(parseFloat(i.carPrice) + 10).toFixed(2)} */}
                      {i.originalPrice}
                    </span>
                    ) ${i.carPrice}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <Typography variant="h6" gutterBottom>
            Add-ons:
          </Typography>
          <List>
            <ListItem>
              <ListItemText primary="• Pet Hair Removal – $19.99" />
            </ListItem>
            <ListItem>
              <ListItemText primary="• Trunk Cleaning – $9.99" />
            </ListItem>
          </List>

          {/* Book Service Button */}
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                size="medium"
                onClick={() => navigate("/services/rapid-auto-care")}
              >
                Book your service now!
              </Button>
            </Grid>
          </Grid>

          {/* Disclaimer */}
          <Box mt={2} mb={5}>
            <Typography variant="body2" color="textSecondary">
              *Disclaimer – Results may vary depending upon the condition of the
              vehicle.
            </Typography>
            <Typography variant="body2" color="textSecondary">
              *Disclaimer – Rapid Auto Care is not a deep cleaning service.
            </Typography>
          </Box>
        </>
      ) : (
        <></>
      )}
    </Container>
  );
};

export default RapidAutoCare;
