import { Navigate, Routes, Route } from "react-router-dom";
import DashboardLayout from "./layouts/dashboard";
import SimpleLayout from "./layouts/simple";
import Page404 from "./pages/404";
import PrivateRoutes from "./providers/privateRoutes";
import SearchPackages from "./pages/search-packages";
import Checkout from "./pages/checkout";
import About from "./pages/about";
import Landing from "./pages/landing";
import Detailing from "./pages/services/detailing";
import RapidAutoCare from "./pages/services/rapid-auto-care";
import BecomeCleaner from "./pages/become-cleaner";
import RegisterCustomer from "./pages/auth/register-customer";
import CustomerLogin from "./pages/auth/customer-login";
import RapidAutoLanding from "./pages/rapid-auto-care-landing";
import CustomerProfile from "./pages/dashboard/customer/profile";
import CustomerSettings from "./pages/dashboard/customer/settings";
import CustomerOrders from "./pages/dashboard/customer/orders";
import VendorDashboard from "./pages/dashboard/vendor/dashboard";
import VendorOrders from "./pages/dashboard/vendor/orders";
import VendorSettings from "./pages/dashboard/vendor/settings";
import ContactUs from "./pages/contact-us";
import PrivacyPolicy from "./pages/privacy-policy.js";
import TermsCondition from "./pages/terms-condition.js"
import RapidAutoCareInfo from "./pages/rapid-auto-care-info";

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<Landing />} index={true} />
      <Route path="/about" element={<About />} index={true} />
      <Route path="/contact-us" element={<ContactUs />} index={true} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} index={true} />
      <Route path="/terms-and-conditions" element={<TermsCondition />} index={true} />
      <Route path="/rapid-auto-care-info" element={<RapidAutoCareInfo />} index={true} />
      <Route
        path="/search-cleaners"
        element={<RapidAutoLanding />}
        index={true}
      />
      <Route
        path="/search-packages"
        element={<SearchPackages />}
        index={true}
      />
      <Route path="/become-cleaner" element={<BecomeCleaner />} index={true} />
      {/* Services */}
      <Route path="/services">
        <Route element={<Navigate to="/services/detailing" />} index={true} />
        <Route path="detailing" element={<Detailing />} />
        <Route path="rapid-auto-care" element={<RapidAutoCare />} />
      </Route>

      {/* Auth */}
      <Route path="/auth">
        <Route element={<Navigate to="/auth/customer-login" />} index={true} />
        <Route path="customer-login" element={<CustomerLogin />} />
        <Route path="register-customer" element={<RegisterCustomer />} />
      </Route>

      <Route path="/checkout" element={<Checkout />} index={true} />
      {/* Private Authenticated Routes */}
      <Route element={<PrivateRoutes />}>
        {/* Customer Routes */}
        <Route path="/dashboard/customer" element={<DashboardLayout />}>
          <Route
            element={<Navigate to="/dashboard/customer/profile" />}
            index={true}
          />
          <Route path="profile" element={<CustomerProfile />} />
          <Route path="orders" element={<CustomerOrders />} />
          <Route path="settings" element={<CustomerSettings />} />
        </Route>
      </Route>
      {/* Vendor Routes */}
      <Route path="/dashboard/vendor" element={<DashboardLayout />}>
        <Route
          element={<Navigate to="/dashboard/vendor/dashboard" />}
          index={true}
        />
        <Route path="dashboard" element={<VendorDashboard />} />
        <Route path="orders" element={<VendorOrders />} />
        <Route path="settings" element={<VendorSettings />} />
      </Route>

      <Route element={<SimpleLayout />}>
        <Route element={<Navigate to="/dashboard/app" />} />
        <Route path="404" element={<Page404 />} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Route>

      <Route path="*" element={<Navigate to="/404" replace />} />
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};
export default Router;
