import PropTypes from "prop-types";
import { forwardRef } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, Link } from "@mui/material";

const Logo = forwardRef(
  ({ disabledLink = false, sx, openNav, ...other }, ref) => {
    const logo = (
      <Box
        ref={ref}
        component="div"
        sx={{
          width: 40,
          height: 40,
          display: "inline-flex",
          ...sx,
        }}
        {...other}
      ></Box>
    );

    if (disabledLink) {
      return <>{logo}</>;
    }

    return (
      <Link
        to="/dashboard/customer"
        component={RouterLink}
        sx={{ display: "contents" }}
      >
        <img
          src={openNav ? "/assets/final_logo.png" : "/assets/final_logo.png"}
          alt="Logo"
          height={85}
        />
      </Link>
    );
  }
);

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
  openNav: PropTypes.bool,
};

export default Logo;
