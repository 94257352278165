import { Box, Grid, Typography } from "@mui/material";
import React from "react";

const AboutUs = ({ styles }) => {
  return (
    <Box className={styles["main-section"]}>
      <Grid container spacing={5}>
        <Grid item xs={12} lg={6}>
          <Box className={styles["written-content"]}>
            <Typography className={styles["about-title"]}>
              Making{" "}
              <span
                style={{
                  color: "#016ABC",
                }}
              >
                Car Cleaning
              </span> easy for our Customers!
            </Typography>

            <Typography
              variant="body1" // Use variant="body1" for better typography consistency
              sx={{
                fontSize: { xs: "16px", md: "17px" }, // Responsive font size
                color: "black",
              }}
            >
              Welcome to Sayfaya, your go-to online platform for convenient and
              reliable interior car cleaning services, delivered right to your
              doorstep. We understand that life can be busy, and maintaining
              your vehicle's cleanliness can be a hassle. That's why we connect
              you with skilled and vetted car cleaners, allowing you to book an
              appointment with a local vendor who will come to you.
              <br />
              <br />
              Simply choose a vendor near you, book your appointment, and let us
              take care of the rest. Experience the convenience of professional
              car cleaning services tailored to fit your schedule and lifestyle.
              <br />
              <br />
              With Sayfaya, your car's cleanliness is just a click away!
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Box className={styles["car-wash-img"]}>
            <img
              src="https://dtcarwash.wpengine.com/wp-content/uploads/2017/05/boy-washing-car.jpg?id=9798"
              alt="Car Wash"
              style={{
                width: "100%", // Make image responsive
                height: "auto", // Maintain aspect ratio
                objectFit: "cover",
                borderRadius: "10px", // Optional: Add some rounding to the corners
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AboutUs;
