import React from "react";
import { Box, Typography, Divider } from "@mui/material";
import {
  CalendarMonthOutlined,
  HourglassEmptyOutlined,
  Info,
  MinorCrashOutlined,
  PlaceOutlined,
  SupportOutlined,
} from "@mui/icons-material";
import PaySummary from "./pay-summary";

const ConfirmPay = ({ styles }) => {
  return (
    <>
      <Box
        sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}
      >
        <Box className={styles.containerConfirm}>
          <Box>
            {/* Horizontal Divider */}
            <Divider className={styles.divider} />

            {/* Service Information Section */}
            <Box className={styles.infoSection}>
              <Typography className={styles.serviceTitle}>
                Service Information
              </Typography>
              <Typography className={styles.editText}>Edit</Typography>
            </Box>

            {/* Car Information */}
            <Box className={styles.carInfo}>
              <SupportOutlined className={styles.carIcon} />
              <Typography className={styles.carText}>
                2017, BMW 2 Series
              </Typography>
            </Box>

            <Box className={styles.carInfo}>
              <MinorCrashOutlined className={styles.carIcon} />
              <Typography className={styles.carText}>Panda X</Typography>
            </Box>

            <Box className={styles.carInfo}>
              <HourglassEmptyOutlined className={styles.carIcon} />
              <Typography className={styles.carText}>1.4 hours</Typography>
            </Box>

            {/* Margin and Horizontal Divider */}
            <Box className={styles.bottomSection}>
              <Divider className={styles.bottomDivider} />
            </Box>
          </Box>

          {/* Second Card */}
          <Box>
            <Box className={styles.infoSection}>
              <Typography className={styles.serviceTitle}>Address</Typography>
              <Typography className={styles.editText}>Edit</Typography>
            </Box>

            {/* Car Information */}

            <Box className={styles.carInfo}>
              <PlaceOutlined className={styles.carIcon} />
              <Typography className={styles.carText}>
                $770 Frantage Rd suite 100, 78526, Brownsvilla, Tx Us
              </Typography>
            </Box>

            {/* Margin and Horizontal Divider */}
            <Box className={styles.bottomSection}>
              <Divider className={styles.bottomDivider} />
            </Box>
          </Box>
          {/* Third Card */}
          <Box>
            <Box className={styles.infoSection}>
              <Typography className={styles.serviceTitle}>
                Date and Time
              </Typography>
              <Typography className={styles.editText}>Edit</Typography>
            </Box>

            {/* Car Information */}

            <Box className={styles.carInfo}>
              <PlaceOutlined className={styles.carIcon} />
              <Typography className={styles.carText}>Sat, Sep 21 </Typography>
            </Box>

            <Box className={styles.carInfo}>
              <CalendarMonthOutlined className={styles.carIcon} />
              <Typography className={styles.carText}>7:00 PM(ETA) </Typography>
              <Info className={styles.carIcon} />
            </Box>

            {/* Margin and Horizontal Divider */}
            <Box className={styles.bottomSection}>
              <Divider className={styles.bottomDivider} />
            </Box>
          </Box>
          {/* Fourth Card */}
          <Box>
            <Box className={styles.infoSection}>
              <Typography className={styles.serviceTitle}>Your Pro</Typography>
              <Typography className={styles.editText}>Edit</Typography>
            </Box>

            {/* Car Information */}

            <Box className={styles.carInfo}>
              <PlaceOutlined className={styles.carIcon} />
              <Typography className={styles.carText}>Sat, Sep 21 </Typography>
            </Box>

            <Box className={styles.carInfo}>
              <CalendarMonthOutlined className={styles.carIcon} />
              <Typography className={styles.carText}>7:00 PM(ETA) </Typography>
              <Info className={styles.carIcon} />
            </Box>

            {/* Margin and Horizontal Divider */}
            <Box className={styles.bottomSection}>
              <Divider className={styles.bottomDivider} />
            </Box>
          </Box>
        </Box>
        <Box sx={{ width: "30%" }}>
          <PaySummary styles={styles} />
        </Box>
      </Box>
    </>
  );
};

export default ConfirmPay;
