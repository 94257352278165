import React, { useState } from "react";
import {
  Grid,
  Card,
  Typography,
  Box,
  Button,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import CarousalCar from "./car-carousal";
import ComparePackages from "./package-compare";
import FAQ from "./faq";
import AddressSearch from "./adress/search-adress";
import Adress2 from "./adress/adress2";
import SelectionCard from "./pro-selection/selection-card";
import ConfirmPay from "./confirm&pay/confirm-pay";

const PackageSelection = ({ styles }) => {
  const steps = [
    "Detailing Package",
    "Address",
    "Date and Time",
    "Pro Selection",
    "Confirmation",
  ];
  const [activeStep, setActiveStep] = useState(0); // Stepper state
  const [showAddressDetails, setShowAddressDetails] = useState(false); // New state for Address step details

  // Handle Continue Button Click
  const handleContinue = () => {
    if (activeStep === 1 && !showAddressDetails) {
      // Show the second window for Address Search step
      setShowAddressDetails(true);
    } else if (activeStep < steps.length - 1) {
      setShowAddressDetails(false); // Reset the address details state for other steps
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  // Handle Back Button Click
  const handleBack = () => {
    if (showAddressDetails) {
      // If we're showing address details, close that first before going to the previous step
      setShowAddressDetails(false);
    } else if (activeStep > 0) {
      setActiveStep((prevStep) => prevStep - 1);
    }
  };

  // Dummy Component for each step
  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Grid item xs={12} md={12}>
            {/* Car Details */}
            <Typography variant="h4" align="left">
              Choose a package
            </Typography>

            <Box elevation={3} style={{ marginBottom: "20px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <CarousalCar />
                  <ComparePackages />
                  <FAQ />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        );
      case 1:
        return showAddressDetails ? (
          <Adress2 styles={styles} />
        ) : (
          <Typography variant="h6">
            <AddressSearch styles={styles} />
          </Typography>
        );
      case 2:
        return <Typography variant="h6">Step 3: Date and Time</Typography>;
      case 3:
        return <SelectionCard styles={styles} />;
      case 4:
        return <ConfirmPay styles={styles} />;
      default:
        return <Typography variant="h6">Unknown Step</Typography>;
    }
  };

  return (
    <Grid container spacing={3} p={"25px"}>
      {/* Left Section */}
      <Grid item xs={12} md={9}>
        {/* Scrollable content */}
        <Card
          elevation={2}
          spacing={4}
          style={{
            padding: "36px",
            marginTop: "16px",
            maxHeight: "75vh",
            overflowY: "auto",
          }}
        >
          {renderStepContent(activeStep)}
        </Card>

        {/* Back and Continue Buttons */}
        <Grid
          container
          justifyContent="space-between"
          style={{ marginTop: "16px" }}
        >
          <Grid item>
            <Button
              disabled={activeStep === 0}
              onClick={handleBack}
              variant="outlined"
              color="primary"
            >
              Back
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={handleContinue}
              variant="contained"
              color="primary"
            >
              {showAddressDetails
                ? "Continue"
                : activeStep === steps.length - 1
                ? "Finish"
                : "Continue"}
            </Button>
          </Grid>
        </Grid>
      </Grid>

      {/* Right Section: Stepper */}
      <Grid item xs={12} md={3}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Grid>
    </Grid>
  );
};

export default PackageSelection;
