import React from "react";
import { Box, Typography, Card } from "@mui/material";
import { Star, ThumbUp, Verified } from "@mui/icons-material";

const SelectionCard = ({ styles }) => {
  return (
    <>
      <Box className={styles.header}>
        <Typography className={styles.title} sx={{marginBottom:"30px"}}>
        Pick from Brownsville's best pro
        </Typography>
      </Box>

      <Card className={styles.card}>
        <Box className={styles.box1}>
          {/* Box 1 */}
          <Box className={styles.avatarBox}>
            <Box className={styles.avatarWrapper}>
              <img
                src="https://img.freepik.com/free-psd/3d-illustration-person-with-sunglasses_23-2149436188.jpg?w=740&t=st=1726035484~exp=1726036084~hmac=3a577675e8ef9afe4435fe7e39f3f9cdcc8e179b216905bf21b16cf451947db7" // Replace with your avatar image URL
                alt="Avatar"
                className={styles.avatar}
              />
              <Box className={styles.ratingBox}>
                <Star className={styles.starIcon} />
                <Typography className={styles.ratingText}>4.5</Typography>
              </Box>
            </Box>
          </Box>
          <Box className={styles.textBox}>
            <Typography className={styles.textBottom}>
              See full profile
            </Typography>
          </Box>
        </Box>
        <Box className={styles.box2}>
          {/* Box 2 */}
          <Typography className={styles.name}>Logan S.</Typography>
          <Box className={styles.recommendedBox}>
            <ThumbUp className={styles.recommendIcon} />
            <Typography className={styles.recommendText}>
              Recommended
            </Typography>
          </Box>
          <Box className={styles.certifiedBox}>
            <Verified className={styles.certifiedIcon} />
            <Typography className={styles.certifiedText}>Certified</Typography>
          </Box>
          <Box className={styles.experienceBox}>
            <Typography className={styles.experienceYears}>5+</Typography>
            <Typography className={styles.experienceText}>
              years of experience
            </Typography>
          </Box>
          <Box className={styles.experienceBox}>
            <Typography className={styles.experienceYears}>220</Typography>
            <Typography className={styles.experienceText}>
              Service provided
            </Typography>
          </Box>
        </Box>
      </Card>
    </>
  );
};

export default SelectionCard;
