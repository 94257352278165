import React, { useState } from 'react';
import { Box, TextField, IconButton, Typography, Chip, InputAdornment } from '@mui/material';
import { Search, Close, Info, Home, Work } from '@mui/icons-material';

const AddressSearch = ({styles}) => {
  const [searchValue, setSearchValue] = useState('');
  const [selectedChip, setSelectedChip] = useState(null);

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleChipClick = (chip) => {
    setSelectedChip(chip);
  };

  const clearSearch = () => {
    setSearchValue('');
  };

  return (
    <Box className={styles.addressContainer}>
      {/* Title */}
      <Box className={styles.header}>
        <Typography className={styles.title}>Where do you want to detail?</Typography>
        <Info className={styles.infoIcon} />
      </Box>

      {/* Subtitle */}
      <Typography className={styles.subtitle}>
        We come to you, no travel fee!
      </Typography>

      {/* Search Field */}
      <Box className={styles.searchWrapper}>
        <TextField
          placeholder="Type your address"
          value={searchValue}
          onChange={handleSearchChange}
          variant="outlined"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search className={styles.searchIcon} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={clearSearch}>
                  <Close className={styles.clearIcon} />
                </IconButton>
              </InputAdornment>
            ),
            classes: {
              notchedOutline: styles.outlineBorder, // Customize outline border
            },
          }}
          className={styles.searchField}
          sx={{ paddingLeft:"0 !important",paddingRight:"0 !important" }}  
        />
      </Box>

      {/* Label */}
      <Typography className={styles.label}>Choose a label</Typography>

      {/* Chips with Icons */}
      <Box className={styles.chipWrapper}>
        <Chip
          icon={<Home className={styles.chipIcon} />}
          label="Home"
          clickable
          onClick={() => handleChipClick('Home')}
          className={`${styles.chip} ${selectedChip === 'Home' ? styles.selectedChip : ''}`}
        />
        <Chip
          icon={<Work className={styles.chipIcon} />}
          label="Work"
          clickable
          onClick={() => handleChipClick('Work')}
          className={`${styles.chip} ${selectedChip === 'Work' ? styles.selectedChip : ''}`}
        />
      </Box>
    </Box>
  );
};

export default AddressSearch;
