import React from "react";
import styles from "./style.module.css";

const CustomInput = ({
  label,
  type = "text",
  placeholder,
  value,
  onChange,
  minLines,
  error,
  name,
}) => {
  return (
    <div className={styles.container}>
      <label className={styles.label} htmlFor={label}>
        {label}
      </label>
      <input
        id={label}
        type={type}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        minLines={minLines}
        className={`${styles.input} ${error ? styles.error : ""}`}
      />
      {error && <p className={styles.errorText}>{error}</p>}
    </div>
  );
};

export default CustomInput;
