import React, { useEffect, useContext } from "react";
import { Grid, Box, Divider, Button } from "@mui/material";
import { Search, PeopleOutline, CalendarMonth } from "@mui/icons-material";
import MapLoader from "../mapinput/MapLoader";
import ServiceTypeInput from "../servicetypeinput";
import styles from "./style.module.css";
import { useNavigate } from "react-router-dom";
import { showNotification } from "../notification";
import "react-toastify/dist/ReactToastify.css";
import { RapidCareBookingContext } from "src/contexts/RapidCareBookingContext";
import {getMinDate} from 'src/utils/helpers';

const serviceOptions = [
{ value: "rapid_auto_care", label: "Rapid Auto Care" },
];

const SearchBar = ({ selectedService }) => {
  const navigate = useNavigate();
  const { rapidCareBookingData, setRapidCareBookingData } = useContext(
    RapidCareBookingContext
  );

  useEffect(() => {
    
    setRapidCareBookingData({
      ...rapidCareBookingData,
      serviceType: serviceOptions.find(
        (item) => item.value === selectedService
      ),
    });
  }, []); // eslint-disable-line

  const handleLocationChange = (location) => {
    setRapidCareBookingData({
      ...rapidCareBookingData,
      location: location.address,
      order_latitude: location.latitude,
      order_longitude: location.longitude,
      bill_city: location.city,
      bill_country: location.country,
      bill_province: location.province,
    });
  };

  const handleChange = (e, v) => {
    const { name, value } = e.target || {};
    setRapidCareBookingData((prevState) => ({
      ...prevState,
      [name]: value || v,
    }));
  };

  const handleSearch = () => {
    const {
      location,
      date,
      serviceType,
      numberOfCars,
      carMakeModel,
    } = rapidCareBookingData;

    const today = new Date().setHours(0, 0, 0, 0);
    const selectedDate = new Date(date).setHours(0, 0, 1, 1);

    if (!location || location.trim() === "") {
      showNotification("Error", "Please enter a valid location.");
      return;
    }

    if (!date || selectedDate <= today) {
      showNotification(
        "Error",
        "Please select a valid date that is not in the past."
      );
      return;
    }

    if (selectedService === "detailing") {
      if (
        !numberOfCars ||
        isNaN(numberOfCars) ||
        !(parseInt(numberOfCars) > 0 && parseInt(numberOfCars) < 11)
      ) {
        showNotification(
          "Error",
          "Please Enter the valid number of cars you want to take service for."
        );
        return;
      }
    } else {
      if (!carMakeModel) {
        showNotification("Error", "Please select a valid car make and model.");
        return;
      }
    }

    if (!serviceType || !serviceType.value) {
      showNotification("Error", "Please select a valid service type.");
      return;
    }

    if (serviceType.value === "rapid_auto_care") {
      navigate(`/search-cleaners`);
    } else {
      navigate(`/search-packages`);
    }
  };


  return (
    <Box className={styles["container"]}>
      <Grid container spacing={2} alignItems="center">
        {/* First Grid: Search */}
        <Grid item xs={12} md={6} lg={2.5}>
          <Box className={styles["searchBox"]}>
            <Search className={styles["searchIcon"]} />
            <Box sx={{ width: "100%" }}>
              <Box className={styles["locationText"]}>Choose Location</Box>
              <MapLoader
                className={styles["textField"]}
                style={{ border: "none" }}
                placeholder="Enter Location"
                onChange={handleLocationChange}
              />
            </Box>
          </Box>
        </Grid>

        {/* Second Grid: Date Picker */}
        <Grid item sm={12} xs={12} md={6} lg={2.5}>
          <Box className={styles["datePickerBox"]}>
            <Divider orientation="vertical" flexItem />
            <Box className={styles["peopleBox"]} sx={{ width: "95%" }}>
              <CalendarMonth className={styles["peopleIcon"]} />
              <Box sx={{ width: "100%" }}>
                <Box className={styles["peopleText"]}>Choose Date </Box>
                <input
                  type="date"
                  name="date"
                  onClick={(e) => e.currentTarget.showPicker()}
                  value={rapidCareBookingData.date}
                  style={{
                    border: "none",
                    outline: "none",
                    padding: "0px",
                    width: "100%",
                  }}
                  onChange={handleChange}
                  min={getMinDate()}
                />
              </Box>
            </Box>
          </Box>
        </Grid>

        {/* Third Grid: Number of Cars and Car Make And Model */}
        {selectedService === "detailing" ? (
          <Grid item xs={12} md={6} lg={3}>
            <Box className={styles["peopleBox"]}>
              <Divider orientation="vertical" flexItem />
              <PeopleOutline className={styles["peopleIcon"]} />
              <Box
                style={{
                  width: "100%",
                  textAlign: "left",
                  outline: "none",
                  fontFamily: "Public Sans !important",
                }}
              >
                <Box className={styles["peopleText"]}>Number of Cars</Box>
                <input
                  type="number"
                  className={styles["number-of-cars"]}
                  name="numberOfCars"
                  value={rapidCareBookingData?.numberOfCars}
                  onChange={(e) => handleChange({ target: e.target })}
                  placeholder="Number of Cars"
                />
              </Box>
            </Box>
          </Grid>
        ) : (
          <Grid item xs={12} md={6} lg={3}>
            <Box className={styles["peopleBox"]}>
              <Divider orientation="vertical" flexItem />
              <PeopleOutline className={styles["peopleIcon"]} />
              <Box
                style={{
                  width: "100%",
                  textAlign: "left",
                  outline: "none",
                  fontFamily: "Public Sans !important",
                }}
              >
                <Box className={styles["peopleText"]}>Car Make And Model</Box>
                <input
                  type="text"
                  className={styles["number-of-cars"]}
                  name="carMakeModel"
                  value={rapidCareBookingData?.carMakeModel}
                  onChange={(e) => handleChange({ target: e.target })}
                  placeholder="Eg: Honda Civic"
                />
              </Box>
            </Box>
          </Grid>
        )}

        {/* Fourth Grid: Service Type */}
        <Grid item xs={12} md={6} lg={2.5}>
          <Box className={styles["peopleBox"]}>
            <Divider orientation="vertical" flexItem />
            <PeopleOutline className={styles["peopleIcon"]} />
            <Box style={{ width: "95%" }}>
              <Box className={styles["peopleText"]}>Service Type</Box>
              <ServiceTypeInput
                options={serviceOptions}
                value={rapidCareBookingData.serviceType}
                onChange={(serviceType) =>
                  handleChange({
                    target: { name: "serviceType", value: serviceType },
                  })
                }
              />
            </Box>
          </Box>
        </Grid>

        <Grid
          item
          xs={0}
          sm={4}
          md={4.5}
          lg={0}
          className={styles["hide"]}
        ></Grid>

        {/* Fourth Grid: Button */}
        <Grid item xs={12} sm={4} md={3} lg={1.5} sx={{ float: "right" }}>
          <Box className={styles["buttonBox"]}>
            <Button
              variant="contained"
              className={styles["button"]}
              onClick={handleSearch}
            >
              Search
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SearchBar;
