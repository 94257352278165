import React, { useEffect, useState } from "react";
import styles from "../style.module.css";
import CustomInput from "src/components/inputs/custom-input";
import { showNotification } from "src/components/notification";
import { Box, Grid, Typography, Button } from "@mui/material";
import { ToastContainer } from "react-toastify";
import Header from "src/components/header";
import { Link, useNavigate } from "react-router-dom";
import { post } from "src/api/base";

const RegisterCustomer = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (window.localStorage.getItem("user") !== null) {
      showNotification("warning", "You're already logged in. Please Sign out first!!");
      setTimeout(() => {
        navigate("/dashboard/customer/profile");
      }, 2000);
    }
  }, []); // eslint-disable-line

  const validateForm = () => {

    const phoneRegex = /^[0-9]{10}$/;

    const newErrors = {};
    if (!formData.firstName) newErrors.firstName = "First name is required.";
    if (!formData.lastName) newErrors.lastName = "Last name is required.";
    if (!formData.email) newErrors.email = "Email is required.";
    if (!formData.phone) {
        newErrors.phone = "Phone number is required.";
    } else if (!phoneRegex.test(formData.phone)) {
        newErrors.phone = "Please enter a valid 10-digit phone number.";
    }
    if (!formData.password) newErrors.password = "Password is required.";
    if (formData.password !== formData.confirmPassword)
      newErrors.confirmPassword = "Passwords do not match.";

    return newErrors;
  };

  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    setFormData({
      ...formData,
      [name]:
        type === "checkbox" ? checked : type === "file" ? files[0] : value,
    });
  };

  const handleClick = async () => {
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      showNotification("Error", "Please enter valid data in all fields.");
      return;
    }

    try {
      await post("/user-register", {
        ...formData,
        name: formData?.firstName + formData?.lastName,
      });
      showNotification(
        "Success",
        "User Registered successfully. Please Login!"
      );
      setTimeout(() => {
        navigate("/auth/customer-login");
      }, 1000);
    } catch (error) {
      showNotification(
        "Error",
        error?.response?.data?.message ?? "User Registration unsuccessful!"
      );
      console.log(error);
    }
  };

  const handleReset = () => {
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      password: "",
      confirmPassword: "",
    });
    setErrors({});
    showNotification("Success", "Form successfully cleared!");
  };

  return (
    <>
      <ToastContainer />
      <Box className={styles["customer-register-section"]}>
        <Box className={styles["image-overlay"]}>
          <Header />
          <Box className={styles["become-cleaner-form"]}>
            <Typography variant="h4" className={styles["form-title"]}>
              Customer User
            </Typography>

            <Typography className={styles["form-sub-title"]}>
              Just a step away to get our best services on your finger !
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <CustomInput
                  label="First Name"
                  placeholder={"John"}
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  error={errors.firstName}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <CustomInput
                  label="Last Name"
                  placeholder={"Doe"}
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  error={errors.lastName}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <CustomInput
                  label="Email"
                  placeholder={"your.email@example.com"}
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  error={errors.email}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <CustomInput
                  label="Phone"
                  placeholder={"+91 XXXXXXXXXX"}
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  error={errors.phone}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <CustomInput
                  label="Password"
                  placeholder={"••••••"}
                  name="password"
                  type="password"
                  value={formData.password}
                  onChange={handleChange}
                  error={errors.password}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <CustomInput
                  label="Confirm Password"
                  placeholder={"••••••"}
                  name="confirmPassword"
                  type="password"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  error={errors.confirmPassword}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "space-around" }}
              >
                <Button
                  className={styles["button-outlined"]}
                  onClick={handleReset}
                >
                  Reset
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className={styles["button"]}
                  onClick={handleClick}
                >
                  Submit
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                {/* Bottom text */}
                <Typography
                  variant="p"
                  sx={{
                    textAlign: "center",
                    marginTop: 2,
                    color: "#016ABC !important",
                  }}
                >
                  Already have an account?{" "}
                  <Link href="/auth/customer-login">Log In</Link>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default RegisterCustomer;
