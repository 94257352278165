import React, { useState } from 'react';
import { Box, Typography, Radio, RadioGroup, FormControlLabel } from '@mui/material';
import { Info, Water,ElectricBolt } from '@mui/icons-material';

// Define the data
const questionData = [
  {
    icon: Water,
    text: "Is there a water supply",
    valueYes: "yes",
    valueNo: "no"
  },
  {
    icon: ElectricBolt ,
    text: "Is there any Electricity Supply?",
    valueYes: "yes",
    valueNo: "no"
  },
  // Add more items here if needed
];

const Adress2 = ({ styles }) => {
  // State to manage the selected value for each question
  const [selectedValues, setSelectedValues] = useState({});

  const handleChange = (event, index) => {
    setSelectedValues({
      ...selectedValues,
      [index]: event.target.value,
    });
  };

  return (
    <Box className={styles.addressContainer}>
      {/* Title */}
      <Box className={styles.header}>
        <Typography className={styles.title}>Address detail?</Typography>
        <Info className={styles.infoIcon} />
      </Box>

      {/* Subtitle */}
      <Typography className={styles.subtitle}>
        This info enables us to match you with the best pro!
      </Typography>

      {questionData.map((item, index) => (
        <Box key={index} className={styles['container2']}>
          {/* Icon and Text */}
          <Box className={styles['iconTextWrapper']}>
            <item.icon className={styles['icon']} />
            <Typography className={styles['text']}>
              {item.text}
            </Typography>
          </Box>

          {/* Radio Buttons */}
          <RadioGroup
            value={selectedValues[index] || ''}
            onChange={(event) => handleChange(event, index)}
            className={styles['radioGroup']}
          >
            <FormControlLabel
              value={item.valueYes}
              control={
                <Radio
                  sx={{
                    '&.Mui-checked': {
                      color: 'skyblue',
                    },
                  }}
                />
              }
              label="Yes"
              className={`${styles['radioButton']} ${selectedValues[index] === item.valueYes ? styles['radioButtonChecked'] : ''}`}
              classes={{ label: styles['radioLabel'] }}
            />
            <FormControlLabel
              value={item.valueNo}
              control={
                <Radio
                  sx={{
                    '&.Mui-checked': {
                      color: 'skyblue',
                    },
                  }}
                />
              }
              label="No"
              className={`${styles['radioButton']} ${selectedValues[index] === item.valueNo ? styles['radioButtonChecked'] : ''}`}
              classes={{ label: styles['radioLabel'] }}
            />
          </RadioGroup>
        </Box>
      ))}
    </Box>
  );
};

export default Adress2;
