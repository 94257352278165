import React from 'react';
import { Box, Typography } from '@mui/material';
import { DirectionsCar, Info } from '@mui/icons-material';

const PaySummary = ({styles}) => {
  return (
    <Box className={styles.cardPaySummary}>
      {/* Car Icon */}
      <Box className={styles.iconContainer}>
        <DirectionsCar className={styles.carIcon2} />
      </Box>

      {/* Title */}
      <Typography className={styles.titlePay}>Service Summary</Typography>

      {/* Service Fee Section */}
      <Box className={styles.feeRow}>
        <Typography className={styles.feeText}>Service fee</Typography>
        <Typography className={styles.amountText}>$63.11</Typography>
      </Box>

      {/* Platform Fee Section */}
      <Box className={styles.feeRow}>
        <Box className={styles.platformFeeText}>
          <Typography className={styles.feeText}>
            Platform fee
          </Typography>
          <Info className={styles.infoIcon} />
        </Box>
        <Typography className={styles.amountText}>$2.50</Typography>
      </Box>
    </Box>
  );
};

export default PaySummary;
