import React from "react";
import {
  Typography,
  Box,
  Link,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import ImageHeader from "../../components/image-header";

const PrivacyPolicy = () => {
  const mt = 4;
  return (
    <>
      <ImageHeader heading={"Privacy Policy"} subheading={""} />
      <Box sx={{ p: 4, marginBottom: "20px" }} mb={10}>
        {/* Title */}

        <Typography variant="body1">
          <strong>Last Updated:</strong> December 5, 2022
        </Typography>

        {/* Introduction */}
        <Typography mt={mt} variant="body1">
          Sayfaya Cleaning Services (“<strong>Sayfaya</strong>”, “
          <strong>we</strong>”, “<strong>us</strong>”, “<strong>our</strong>”)
          respects the privacy of our users and is committed to protecting it by
          complying with this policy. This privacy policy (the “
          <strong>Privacy Policy</strong>”) describes:
        </Typography>

        <List>
          <ListItem>
            <ListItemText primary="• The types of information we may collect from you, when you download, install, register with, access or use the mobile application (the 'App'), https://sayfaya.com/ (the 'Site') (together, the 'Services'); and" />
          </ListItem>
          <ListItem>
            <ListItemText primary="• Our practices for collecting, using, maintaining, protecting, and disclosing that information." />
          </ListItem>
        </List>

        <Typography variant="body1">
          By accessing or using our Services, you agree that you have read,
          understood, and agreed to be bound by this Privacy Policy. If you do
          not accept the terms of this Privacy Policy, you may not access or use
          the Services. We may change this Privacy Policy from time to time
          without notice to you. The date on which this Privacy Policy was last
          updated will appear at the top of this page. You should periodically
          review this Privacy Policy so that you are aware of any changes which
          might affect you. Your continued use of the Services after we make
          changes indicates that you accept and consent to those changes.
        </Typography>

        <Typography variant="body1">
          Please also carefully read our Terms of Service found at{" "}
          <Link href="https://sayfaya.com/terms-conditions/">
            https://sayfaya.com/terms-conditions/
          </Link>{" "}
          prior to using our Services.
        </Typography>

        {/* Collection of Your Information */}
        <Typography variant="h5" mt={mt} gutterBottom>
          Collection of Your Information
        </Typography>
        <Typography variant="body1">
          For the purposes of this Privacy Policy, personal information (“
          <strong>Personal Information</strong>”) means any information about an
          identifiable individual, as may be defined or limited under applicable
          privacy legislation. Personal Information excludes anonymous or
          de-identified data that is not associated with a particular individual
          (herein referred to as “<strong>Non-identifiable Information</strong>
          ”). Personal Information and Non-identifiable Information are
          collectively referred to in this Privacy Policy as “
          <strong>Information</strong>”.
        </Typography>

        {/* What Information Do We Collect? */}
        <Typography variant="h6" mt={mt} gutterBottom>
          What Information Do We Collect?
        </Typography>
        <Typography variant="body1">
          When you use our Services, we collect and use several types of
          Information from and about you, including:
        </Typography>

        <List>
          <ListItem>
            <ListItemText
              primary={
                "• Personal Information that we can reasonably use to directly or indirectly identify you, such as your name, mailing address, e-mail address, telephone number, IP address, credit card, and billing information."
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText primary="• Non-identifiable Information, such as demographic information, or statistical or aggregated information." />
          </ListItem>
          <ListItem>
            <ListItemText primary="• Technical information, including your login information, browser type and version, time zone setting, etc." />
          </ListItem>
          <ListItem>
            <ListItemText primary="• Information about your Site and App interactions, including the full URLs, clickstream, page response times, etc." />
          </ListItem>
        </List>

        {/* How Do We Collect Information? */}
        <Typography variant="h6" mt={mt} gutterBottom>
          How Do We Collect Information?
        </Typography>
        <Typography variant="body1">
          We only collect Information that we believe is reasonably necessary to
          provide the Services to you, and as otherwise described in this
          Policy. We use different methods to collect your Information,
          including through:
        </Typography>

        <List>
          <ListItem>
            <ListItemText primary="• Direct interactions with you when you provide it to us, such as when you book services, ask to receive emails or text messages, or when you correspond with us." />
          </ListItem>
          <ListItem>
            <ListItemText primary="• Automated technologies or interactions as you navigate our Site and/or App." />
          </ListItem>
          <ListItem>
            <ListItemText primary="• Third parties or publicly available sources, such as our business partners and service providers." />
          </ListItem>
        </List>
        <Typography variant="body1">
          We may collect Information that you, or another, provides directly to
          us by filling in forms, and Non-identifiable Information that we
          collect from cookies or tracking software we utilize. In addition to
          Information collected through your use of our Services, we may also
          collect Information in other ways such as over the phone, via email
          and through location tracking devices as further described below.
        </Typography>

        <Typography variant="body1">
          The choice to provide us with Personal Information is yours, and you
          can withdraw your consent with respect to our collection and use of
          your Personal Information at any time. In certain cases, your decision
          to withhold Information may limit the services that we can provide to
          you through the Site or App.
        </Typography>

        {/* Cookies */}
        <Typography variant="h5" mt={mt} gutterBottom>
          Cookies
        </Typography>
        <Typography variant="body1" gutterBottom>
          A cookie is a small file placed on your computer or smartphone. It may
          be possible to refuse to accept cookies by activating the appropriate
          setting on your device. However, if you select this setting, you may
          not be access all parts of our Services. We use cookies as means of
          providing personalization features to our users, to assist in
          completing an incomplete purchase for items left in a shopping cart,
          and for marketing purposes.
        </Typography>

        <Typography variant="body1" gutterBottom>
          Some of the cookies used by us are essential for the functioning and
          performance of our Site and App, such as by making our Site and App
          work more efficiently and facilitating your use of the functionalities
          and services provided; these functionality and/or performance cookies
          will be deleted from your device once your browser-session has ended
          (session cookies). We will not use the information stored in essential
          cookies for any other purpose than as strictly necessary to provide
          you with the services and functionalities requested.
        </Typography>
        <Typography variant="body1" gutterBottom>
          We also use cookies that are not essential for the functioning and
          performance of our Site and App, but that help us to customize your
          experience, improve Site and App functionality, and have marketing
          functions.
        </Typography>

        <Typography variant="body1" gutterBottom>
          Our Site or App may contain links to other websites operated by third
          parties such as media sites and app stores and may include social
          media features such as Facebook and Twitter buttons (such as “Like,”
          “Tweet” or “Pin”). These third-party sites may collect information
          about you if you click on a link and the social media sites may
          automatically record information about your browsing behavior every
          time you visit a site that has a social media button. Your
          interactions with these features are governed by the privacy policy of
          the company providing the feature, not by our Privacy Policy. We do
          not control what information these third parties collect. Please
          review your privacy settings on your social media sites and think
          carefully before clicking on links which may take you to a third-party
          website.
        </Typography>

        <Typography variant="body1" gutterBottom>
          You can opt-out of several third-party ad servers' and networks'
          cookies simultaneously by using an opt-out tool created by the{" "}
          <Link
            href="https://youradchoices.ca/choices"
            target="_blank"
            rel="noopener"
          >
            Digital Advertising Alliance of Canada
          </Link>{" "}
          or an opt-out tool created by the{" "}
          <Link
            href="http://optout.networkadvertising.org/?c=1"
            target="_blank"
            rel="noopener"
          >
            Network Advertising Initiative
          </Link>
          . You can also access these websites to learn more about online
          behavioral advertising and how to stop websites from placing cookies
          on your device. Opting out of a network does not mean you will no
          longer receive online advertising. It does mean that the network from
          which you opted out will no longer deliver ads tailored to your web
          preferences and usage patterns.
        </Typography>
        <Typography variant="body1" gutterBottom>
          We use a third party, Google Analytics, to understand how visitors
          find and use our Site and App. Google Analytics uses cookies to help
          us analyze how users use our Site and App, including the number of
          visitors, the websites our visitors have come from, and the pages they
          visit. Any information generated by the cookie about your use of our
          Site and/or App will be transmitted to and stored by Google. You may
          prevent the collection of data generated by the cookie about your use
          of the Site and App (including your IP address) and opt-out of being
          tracked by Google Analytics across all websites by downloading and
          installing a browser-plugin which is available{" "}
          <Link
            href="https://tools.google.com/dlpage/gaoptout?hl=en"
            target="_blank"
            rel="noopener"
          >
            here
          </Link>
          .
        </Typography>

        {/* Google Analytics */}
        <Typography variant="h5" mt={mt} gutterBottom>
          Use of your Information
        </Typography>
        <Typography variant="body1" paragraph>
          We will only use your Information for purposes identified in this
          Privacy Policy, or as required by law. The Information that we collect
          and store is primarily used for the following purposes:
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="i. to provide you with access to the Services;" />
          </ListItem>
          <ListItem>
            <ListItemText primary="ii. to provide you with information relating to our Services;" />
          </ListItem>
          <ListItem>
            <ListItemText primary="iii. to provide you with information or services that you request from us;" />
          </ListItem>
          <ListItem>
            <ListItemText primary="iv. to follow up on your comments and suggestions;" />
          </ListItem>
          <ListItem>
            <ListItemText primary="v. to fulfil the purposes for which you provided the Information or that were described when it was collected, or any other purpose for which you provide it;" />
          </ListItem>
          <ListItem>
            <ListItemText primary="vi. to carry out our obligations and enforce our rights arising from any contracts with you, including for billing and collection or to comply with legal requirements;" />
          </ListItem>
          <ListItem>
            <ListItemText primary="vii. to notify you about changes to our Services, or any products or services we offer or provide through our Site or App;" />
          </ListItem>
          <ListItem>
            <ListItemText primary="viii. to improve our Site, App, products or services, marketing, or customer relationships and experiences;" />
          </ListItem>
          <ListItem>
            <ListItemText primary="ix. to manage and develop our business systems and operations;" />
          </ListItem>
          <ListItem>
            <ListItemText primary="x. to monitor and conduct an analysis of our Site and App traffic and usage patterns;" />
          </ListItem>
          <ListItem>
            <ListItemText primary="xi. to post customer testimonials, comments or reviews;" />
          </ListItem>
          <ListItem>
            <ListItemText primary="xii. to protect our rights and property; or" />
          </ListItem>
          <ListItem>
            <ListItemText primary="xiii. to lessen a serious threat to personal safety, health or security." />
          </ListItem>
        </List>
        <Typography variant="body1" paragraph>
          Recordings, which may include Personal Information such as your image
          or voice, may also be distributed, published, displayed, performed,
          transmitted, streamed, broadcasted, or made available to Providers,
          other customers, third parties, or the public in order to provide such
          persons with access to the content of the recordings in connection
          with our business.
        </Typography>

        <Typography variant="h5" mt={mt} gutterBottom>
          Disclosure of your Information
        </Typography>
        <Typography variant="body1" paragraph>
          We will not sell, share, trade or otherwise distribute your Personal
          Information to third parties, except in accordance with this Privacy
          Policy. However, we reserve the right to use Non-identifiable
          Information for any purpose, including commercial purposes, in our
          sole discretion. Such commercial purpose may include, but is not
          limited to, sale to third parties to allow them to send targeted
          advertising messages.
        </Typography>
        <Typography variant="body1" paragraph>
          We reserve the right to share any Information collected from you with
          our affiliates, or to transfer such Information to a successor entity
          or to an entity which purchases substantially all of the assets of
          Sayfaya.
        </Typography>
        <Typography variant="body1" paragraph>
          We sometimes contract with outside organizations to perform
          specialized services such as hosting the Site and App. Our suppliers
          may at times receive, process or handle some of your Information. Only
          the Information necessary to perform the services under the contract
          is given to the supplier. Under their contracts with us, our suppliers
          may use such Information only to carry out the contracted service and
          must not store or use the Information for any other purpose. Our
          contracts with suppliers require that they provide a level of
          protection for your Information that is comparable to our own.
        </Typography>
        <Typography variant="body1" paragraph>
          We may also disclose your Information:
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="To comply with any court order, law, or legal process, including to respond to any government or regulatory request, in accordance with applicable law." />
          </ListItem>
          <ListItem>
            <ListItemText primary="To enforce or apply our Terms of Service and other agreements, including for billing and collection purposes." />
          </ListItem>
          <ListItem>
            <ListItemText primary="If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of Sayfaya, our customers, or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction." />
          </ListItem>
        </List>

        <Typography variant="h5" mt={mt} gutterBottom>
          Data Transfers
        </Typography>
        <Typography variant="body1" paragraph>
          We may process, store, and transfer your Personal Information in and
          to a foreign country, with different privacy laws that may or may not
          be as comprehensive as Canadian law. In these circumstances, the
          governments, courts, law enforcement, or regulatory agencies of that
          country may be able to obtain access to your Personal Information
          through the laws of the foreign country. Whenever we engage a service
          provider, we require that its privacy and security standards adhere to
          this policy and applicable Canadian privacy legislation.
        </Typography>

        <Typography variant="h5" mt={mt} gutterBottom>
          Security
        </Typography>
        <Typography variant="body1" paragraph>
          The security of your Personal Information is important to us. To
          ensure that your Personal Information is secure, we have put in place
          commercially suitable procedures to safeguard the Information we
          collect against unauthorized use, disclosure, or modification,
          including but not limited to:
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="The data stored by Sayfaya is encrypted at rest and in transit;" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Sayfaya does not store user passwords;" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Access to Sayfaya’s API endpoints requires the requester to be authenticated and authorized (except for some endpoints that do not give access to any sensitive data)." />
          </ListItem>
        </List>
        <Typography variant="body1" paragraph>
          Nonetheless, transmission of information on the internet is not
          completely secure. Therefore, we cannot guarantee the security of data
          sent to us electronically on our Site, through our App, by email or
          otherwise, and transmission of such data is therefore entirely at your
          own risk. Likewise, the storage of information is not completely
          secure, and we cannot guarantee the security of data stored on our
          system and storage of such data is therefore entirely at your own
          risk.
        </Typography>
        <Typography variant="body1" paragraph>
          We comply with all applicable laws related to the security standards
          we must apply to our services, including data collection,
          transmission, and storage, to be and remain compliant with those laws
          when we collect, use, handle, or store your Personal Information.
        </Typography>

        <Typography variant="h5" mt={mt} gutterBottom>
          Data Retention
        </Typography>
        <Typography variant="body1" paragraph>
          Except as otherwise permitted or required by applicable law or
          regulation, we will only retain your Personal Information for as long
          as necessary to fulfill the purposes we collected it for, including
          for the purposes of satisfying any legal, accounting, or reporting
          requirements. Under some circumstances we may anonymize or aggregate
          your Personal Information so that it can no longer be associated with
          you. We reserve the right to use such anonymous and de-identified data
          for any legitimate business purpose without further notice to you or
          your consent.
        </Typography>

        <Typography variant="h5" gutterBottom>
          Minors
        </Typography>
        <Typography variant="body1" paragraph>
          Our Services are not intended for persons under the age of majority,
          and we do not knowingly collect Personal Information from minors. If
          you believe we might have any information from or about a minor,
          please contact us at the address provided below.
        </Typography>

        <Typography variant="h5" mt={mt} gutterBottom>
          Updating your Information and Correcting Errors
        </Typography>
        <Typography variant="body1" paragraph>
          It is important that your Personal Information be accurate and
          up-to-date. If any of your Personal Information changes, is
          inaccurate, or is incomplete, please inform us by contacting us at the
          address provided below so that we can make any necessary changes. You
          can also log into your account and update your Personal Information at
          any time. By law, you have the right to access, verify, and amend all
          of your Personal Information that we hold about you.
        </Typography>
        <Typography variant="body1" paragraph>
          We may request specific information from you to help us confirm your
          identity and your right to access, and to provide you with the
          Personal Information that we hold about you or make your requested
          changes. Applicable law may allow or require us to refuse to provide
          you with access to some or all of the Personal Information that we
          hold about you, or we may have destroyed, erased, or made your
          Personal Information anonymous in accordance with our record retention
          obligations and practices. If we cannot provide you with access to
          your Personal Information, we will inform you of the reasons why,
          subject to any legal or regulatory restrictions.
        </Typography>

        <Typography variant="h5" mt={mt} gutterBottom>
          Withdrawing your Consent
        </Typography>
        <Typography variant="body1" paragraph>
          Where you have provided your consent to the collection, use, and
          transfer of your Personal Information, you may have the legal right to
          withdraw your consent under certain circumstances. To withdraw your
          consent, if applicable, contact us at the address provided below.
          Please note that if you withdraw your consent, we may not be able to
          provide you with a particular product or service. We will explain the
          impact to you at the time to help you with your decision.
        </Typography>

        <Typography variant="h5" mt={mt} gutterBottom>
          Contact Us
        </Typography>
        <Typography variant="body1">
          If you have any questions, concerns, or suggestions regarding this
          Privacy Policy, please contact us at{" "}
          <Link href="mailto:support@sayfaya.com">support@sayfaya.com</Link>.
        </Typography>
      </Box>
    </>
  );
};

export default PrivacyPolicy;
