import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { QueryClient, QueryClientProvider } from "react-query";
import Router from "./routes";
import ThemeProvider from "./theme";
import { StyledChart } from "./components/chart";
import ScrollToTop from "./components/scroll-to-top";
import { AuthProvider } from "./providers/auth";
import { SecretProvider } from "./contexts/useSecrets";
import { RapidCareBookingProvider } from "./contexts/RapidCareBookingContext";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useEffect } from "react";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY);

const paymentOptions = {
  mode: "payment",
  amount: 1099,
  currency: "cad",
  paymentMethodType: ["card", "upi"],
};

const queryClient = new QueryClient();

const App = () => {
  const currentVersion = process.env.REACT_APP_VERSION;

  // Function to check version and force cache reset
  const checkVersion = () => {
    const storedVersion = localStorage.getItem("appVersion");

    // If stored version doesn't exist or doesn't match, force reload
    if (!storedVersion || storedVersion !== currentVersion) {
      localStorage.setItem("appVersion", currentVersion);
      window.location.reload(true); // This forces a hard reload and clears the cache
    }
  };

  // Run the checkVersion function when the component mounts
  useEffect(() => {
    checkVersion();
  }, []); // eslint-disable-line

  return (
    <SecretProvider>
      <HelmetProvider>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <BrowserRouter>
              <Elements stripe={stripePromise} options={paymentOptions}>
                <RapidCareBookingProvider>
                  <ThemeProvider>
                    <ScrollToTop />
                    <StyledChart />
                    <Router />
                  </ThemeProvider>
                </RapidCareBookingProvider>
              </Elements>
            </BrowserRouter>
          </AuthProvider>
        </QueryClientProvider>
      </HelmetProvider>
    </SecretProvider>
  );
};

export default App;
