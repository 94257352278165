import React from "react";
import { Box, Typography } from "@mui/material";
// import FAQ from "src/pages/search-packages/component/faq";
import FAQDrawerInfo from "./faq-drawer-info";

const FAQNew = ({ styles }) => {
  return (
    <Box className={styles["content"]}>
      <Box sx={{ textAlign: "center" }}>
        <Typography variant="h3"> FAQs</Typography>
      </Box>
      <Box className={styles["testimonial-container"]}>
        <FAQDrawerInfo />
      </Box>
    </Box>
  );
};

export default FAQNew;
