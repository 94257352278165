import React from "react";
import { Box, Typography } from "@mui/material";
import Header from "src/components/header";

const AboutLanding = ({ styles }) => {
  return (
    <Box className={styles["landing-section"]}>
      <Box>
        <img
          className={styles["about-landing-image"]}
          src="https://dtcarwash.wpengine.com/wp-content/uploads/2017/05/about1.jpg"
          alt="About Landing"
          style={{
            objectFit: "cover",
          }}
        />
      </Box>
      <Box className={styles["image-overlay"]}>
        <Header transparent={true} darkBackground={true} />
        <Box className={styles["importance"]}>
          <Typography variant="h2" sx={{ color: "white", textAlign: "center" }}>
            Give your Car
          </Typography>
          <Typography
            variant="h2"
            sx={{ color: "#FFCB0F", marginBottom: "20px", textAlign: "center" }}
          >
            The Best Treatment
          </Typography>
          <Typography
            variant="h6"
            sx={{ color: "white", marginBottom: "20px", textAlign: "center" }}
          >
            FAQs
          </Typography>
        </Box>
        <Box></Box>
      </Box>
    </Box>
  );
};

export default AboutLanding;
