import React, { useState } from "react";
import {
  Tabs,
  Tab,
  Box,
  Typography,
  Card,
  CardContent,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Divider,
  Button,
  Drawer,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/system";
import { PRIMARY } from "../../../theme/palette";
import CloseIcon from "@mui/icons-material/Close";

const StyledTabs = styled(Tabs)({
  borderRadius: "30px",
  minHeight: "40px",
  padding: "5px",
  "& .MuiTabs-indicator": {
    display: "none",
  },
});

const StyledTab = styled(Tab)(({ theme }) => ({
  minWidth: "100px",
  borderRadius: "30px",
  minHeight: "40px",
  padding: "5px 15px",
  backgroundColor: "transparent",
  color: "textColor",
  transition: "0.3s",
  "&.Mui-selected": {
    backgroundColor: PRIMARY.main,
    color: PRIMARY.contrastText,
  },
}));

const ServiceCard = styled(Card)({
  minWidth: "300px",
  marginRight: "20px",
  border: "1px solid #e0e0e0",
  borderRadius: "10px",
});

const ScrollContainer = styled(Box)({
  display: "flex",
  overflowX: "auto",
  zIndex: 999999,
  padding: "10px 0",
  "&::-webkit-scrollbar": {
    height: "8px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#888",
    borderRadius: "10px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#555",
  },
});

const SwitchTabsWithSelectablePackages = () => {
  const packagesData = {
    fullDetailPackages: [
      {
        value: "pandaX",
        title: "Panda X - Full Detail",
        duration: "2 hours",
        description: [
          "An Express Service",
          "Good for maintenance or when your car needs a light clean",
        ],
        bestFor: ["🧽 Light clean", "🧼 Regular upkeep", "🚗 Rideshare driver"],
        price: "$65.82",
        details: "Panda X Full Detail provides a comprehensive service..."
      },
      {
        value: "pandaXLuxury",
        title: "Panda X Luxury",
        duration: "3 hours",
        description: [
          "A Premium Full Detail Service",
          "Includes deep cleaning of both exterior and interior",
        ],
        bestFor: ["🛥️ Luxury cars", "🎉 Special events"],
        price: "$120.00",
        details: "Panda X Luxury is the ultimate full detail service..."
      },
    ],
    interiorOnlyPackages: [
      {
        value: "interiorBasic",
        title: "Interior Only - Basic Clean",
        duration: "1 hour",
        description: [
          "Focuses on interior surfaces, vacuuming, and wiping down seats",
        ],
        bestFor: ["👨‍👩‍👧‍👦 Family cars", "🐕 Pet owners"],
        price: "$45.50",
        details: "Interior Basic Clean offers an affordable way to refresh..."
      },
      {
        value: "interiorDeluxe",
        title: "Interior Only - Deluxe Clean",
        duration: "2 hours",
        description: [
          "Includes deep cleaning of seats, carpets, and all interior surfaces",
        ],
        bestFor: ["🐾 Pet owners", "💼 Luxury interior"],
        price: "$75.00",
        details: "Interior Deluxe Clean goes beyond regular cleaning..."
      },
    ],
  };

  const [tabIndex, setTabIndex] = useState(0);
  const [selectedPackage, setSelectedPackage] = useState("pandaX");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerContent, setDrawerContent] = useState(null);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
    setSelectedPackage(
      newValue === 0
        ? packagesData.fullDetailPackages[0].value
        : packagesData.interiorOnlyPackages[0].value
    );
  };

  const handlePackageChange = (event) => {
    setSelectedPackage(event.target.value);
  };

  const openDrawer = (pkg) => {
    setDrawerContent(pkg);
    setDrawerOpen(true);
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  return (
    <Box mt={2}>
      <Divider mb={2} />
      <Box mt={2}>
        <StyledTabs value={tabIndex} onChange={handleTabChange}>
          <StyledTab label="Full Detail" />
          <StyledTab label="Interior Only" />
        </StyledTabs>
      </Box>

      {/* Tab content */}
      {tabIndex === 0 && (
        <Box>
          <Typography variant="h6" sx={{ mt: 2 }}>
            Select a package:
          </Typography>
          <FormControl component="fieldset">
            <RadioGroup value={selectedPackage} onChange={handlePackageChange}>
              <ScrollContainer>
                {packagesData.fullDetailPackages.map((pkg) => (
                  <ServiceCard key={pkg.value}>
                    <CardContent>
                      <FormControlLabel
                        value={pkg.value}
                        control={<Radio />}
                        label={
                          <Box>
                            <Typography variant="h6">{pkg.title}</Typography>
                            <Typography variant="body2" color="textSecondary">
                              <span role="img" aria-label="clock">
                                ⏰
                              </span>{" "}
                              {pkg.duration}
                            </Typography>
                            {pkg.description.map((desc, index) => (
                              <Typography
                                key={index}
                                variant="body2"
                                color="textSecondary"
                              >
                                ✓ {desc}
                              </Typography>
                            ))}
                            <Typography
                              variant="subtitle2"
                              style={{ marginTop: "10px" }}
                            >
                              Best for:
                            </Typography>
                            <Box>
                              {pkg.bestFor.join(", ")}
                            </Box>
                            <Typography
                              variant="h6"
                              style={{ marginTop: "10px" }}
                            >
                              {pkg.price}
                            </Typography>
                          </Box>
                        }
                      />
                      <Button
                        variant="outlined"
                        onClick={() => openDrawer(pkg)}
                        sx={{ mt: 2 }}
                      >
                        More Details
                      </Button>
                    </CardContent>
                  </ServiceCard>
                ))}
              </ScrollContainer>
            </RadioGroup>
          </FormControl>
        </Box>
      )}

      {tabIndex === 1 && (
        <Box>
          <Typography variant="h6" sx={{ mt: 2 }}>
            Select a package:
          </Typography>
          <FormControl component="fieldset">
            <RadioGroup value={selectedPackage} onChange={handlePackageChange}>
              <ScrollContainer>
                {packagesData.interiorOnlyPackages.map((pkg) => (
                  <ServiceCard key={pkg.value}>
                    <CardContent>
                      <FormControlLabel
                        value={pkg.value}
                        control={<Radio />}
                        label={
                          <Box>
                            <Typography variant="h6">{pkg.title}</Typography>
                            <Typography variant="body2" color="textSecondary">
                              <span role="img" aria-label="clock">
                                ⏰
                              </span>{" "}
                              {pkg.duration}
                            </Typography>
                            {pkg.description.map((desc, index) => (
                              <Typography
                                key={index}
                                variant="body2"
                                color="textSecondary"
                              >
                                ✓ {desc}
                              </Typography>
                            ))}
                            <Typography
                              variant="subtitle2"
                              style={{ marginTop: "10px" }}
                            >
                              Best for:
                            </Typography>
                            <Box>{pkg.bestFor.join(", ")}</Box>
                            <Typography
                              variant="h6"
                              style={{ marginTop: "10px" }}
                            >
                              {pkg.price}
                            </Typography>
                          </Box>
                        }
                      />
                      <Button
                        variant="outlined"
                        onClick={() => openDrawer(pkg)}
                        sx={{ mt: 2 }}
                      >
                        More Details
                      </Button>
                    </CardContent>
                  </ServiceCard>
                ))}
              </ScrollContainer>
            </RadioGroup>
          </FormControl>
        </Box>
      )}

      {/* Drawer for displaying additional details */}
      <Drawer anchor="right" open={drawerOpen} onClose={closeDrawer}>
        <Box sx={{ width: 300, p: 2 }}>
          <IconButton onClick={closeDrawer} sx={{ float: "right" }}>
            <CloseIcon />
          </IconButton>
          {drawerContent && (
            <Box mt={2}>
              <Typography variant="h6">{drawerContent.title}</Typography>
              <Typography variant="body1" sx={{ mt: 2 }}>
                {drawerContent.details}
              </Typography>
              <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
                <span role="img" aria-label="clock">
                  ⏰
                </span>{" "}
                {drawerContent.duration}
              </Typography>
              <Typography variant="h6" sx={{ mt: 2 }}>
                {drawerContent.price}
              </Typography>
            </Box>
          )}
        </Box>
      </Drawer>
    </Box>
  );
};

export default SwitchTabsWithSelectablePackages;
