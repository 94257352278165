import React, { useContext, useState, useEffect, useRef } from "react";
import {
  Grid,
  Typography,
  Box,
  Button,
  Stepper,
  Step,
  Divider,
  StepLabel,
  Drawer,
  IconButton,
} from "@mui/material";
import CleanerListCard from "./cleaner-list-card";
import BookingDetails from "./booking-details";
import { showNotification } from "../../../components/notification";
import ConfirmationScreen from "./confirmation-page";
import { RapidCareBookingContext } from "src/contexts/RapidCareBookingContext";
import { authorizedPost, _getCleanerList } from "src/api/base";
import FilterHeader from "./filter";
import CloseIcon from "@mui/icons-material/Close";
import Reviews from "src/components/reviews";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { processStripePayment } from "src/utils/firebase-functions";
import {
  CAR_TYPE_DATA,
  cardNumberElementOptions,
  PARKING_PLACE,
  steps,
  transformFormData,
  validateForm,
} from "../helpers";
import BookingSuccessful from "./booking-successful";
import { ToastContainer } from "react-toastify";
import Loader from "src/components/loader";
import "./stripe-style.css"

const CleanerStepper = ({ styles }) => {
  const labelRef = useRef(null);
  const stripe = useStripe();
  const elements = useElements();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [cleanerLoading, setCleanerLoading] = useState(false);
  const [vendorId, setVendorId] = useState("");

  const { rapidCareBookingData, setRapidCareBookingData } = useContext(
    RapidCareBookingContext
  );

  const openReviewSection = (vendorId) => {
    setVendorId(vendorId);
    setOpenDrawer(true);
  };

  const handleClose = () => {
    setOpenDrawer(false);
  };

  const [selectedDates, setSelectedDates] = useState({});
  const [selectedTimeSlot, setSelectedTimeSlot] = useState({});
  const [selectedCleaner, setSelectedCleaner] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null); // eslint-disable-line
  const [isProcessing, setIsProcessing] = useState(false);
  const [isPaymentDone, setIsPaymentDone] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const carServiceData = {
    location: rapidCareBookingData?.location || "",
    order_latitude: rapidCareBookingData.order_latitude,
    order_longitude: rapidCareBookingData.order_longitude,
    bill_city: rapidCareBookingData.bill_city,
    bill_country: rapidCareBookingData.bill_country,
    bill_province: rapidCareBookingData.bill_province,
    date: rapidCareBookingData?.date || "",
    numberOfCars: rapidCareBookingData?.numberOfCars || "",
    carMakeModel: rapidCareBookingData?.carMakeModel || "",
    serviceType: rapidCareBookingData?.serviceType || "",
  };

  const [errors, setErrors] = useState({});
  const [cleanerList, setCleanerList] = useState([]);
  const [filters, setFilters] = useState({
    filterLocation: carServiceData?.location,
    filterDate: carServiceData?.date,
  });

  // Handle Location Change
  const handleLocationChange = (location) => {
    let prevState = { ...filters };

    if ((location || "") !== "") {
      prevState["filterLocation"] = location;
    }
    setFilters(prevState);

    // Update booking data state
    setRapidCareBookingData({
      ...rapidCareBookingData,
      location: location.address,
      order_latitude: location.latitude,
      order_longitude: location.longitude,
      bill_city: location.city,
      bill_country: location.country,
      bill_province: location.province,
    });
  };

  // Handle Date Change
  const handleDateChange = (e, v) => {
    const { value } = e.target || {};
    let prevState = { ...filters };

    if ((value || "") !== "") {
      prevState["filterDate"] = value;
    }
    setFilters(prevState);

    // Update booking data state
    setRapidCareBookingData({
      ...rapidCareBookingData,
      date: value,
    });
  };

  const [formData, setFormData] = React.useState({
    firstName: "",
    lastName: "",
    address1: carServiceData?.location || "",
    address2: "",
    phone: "",
    email: "",
    parkingPlace: PARKING_PLACE[0]?.value,
    selectedCar: CAR_TYPE_DATA[0]?.carType,
    carMakeModel: rapidCareBookingData?.carMakeModel || "",
    powerSource: "no",
    carCondition: "no",
    addOns: {
      trunkCleaning: false,
      petHairRemoval: false,
    },
    tips: "",
    mats: "",
    additionalInfo: "",
    saveAddress: false,
    order_latitude: rapidCareBookingData?.order_latitude,
    order_longitude: rapidCareBookingData?.order_longitude,
    city: rapidCareBookingData?.bill_city,
    country: rapidCareBookingData?.bill_country,
    province: rapidCareBookingData?.bill_province,
    paymentMethod: "pay_later",
  });

  useEffect(() => {
    // Function to fetch car metadata
    fetchCleanerList(rapidCareBookingData);
  }, []); // eslint-disable-line

  // Effect hook to call fetchCleanerList after rapidCareBookingData changes
  useEffect(() => {
    if (rapidCareBookingData.location || rapidCareBookingData.date) {
      fetchCleanerList(rapidCareBookingData);
    }
  }, [rapidCareBookingData]);

  const fetchCleanerList = async (filters) => {
    try {
      setCleanerLoading(true);
      // Replace with your actual API call
      const response = await _getCleanerList(filters);
      // modifyCarOptions(response || []); // Update state with the API response data
      setCleanerList(response.data);
      setCleanerLoading(false);
    } catch (error) {
      setCleanerLoading(false);
      console.error("Error fetching Cleaner List", error);
    }
  };

  const [activeStep, setActiveStep] = useState(0); // Stepper state

  const saveBookingData = async (online_paid, paymentId) => {
    try {
      // Get or Create the User
      const userGetOrCreateResponse = await authorizedPost(
        "/user-get-or-create",
        { email: formData?.email, phone: formData?.phone }
      );

      // Create a Booking
      const { user_id } = userGetOrCreateResponse?.data;
      setFormData({ ...formData, user_id });
      const data = transformFormData(
        formData,
        user_id,
        selectedDates,
        selectedCleaner,
        selectedTimeSlot
      );

      const response = await authorizedPost("/customer-booking-save", data);

      if (online_paid) {
        await authorizedPost("/customer-booking-payment-update", {
          user_id,
          booking_id: response["data"]["booking_id"],
          payment_id: paymentId,
          payment_status: "1",
          previous_booking_id: "",
        });
      }
      await authorizedPost("/cleaner-booking-approval", {
        user_id: data?.vendor_id,
        booking_id: response["data"]["booking_id"],
        booking_approval_status: "1",
      });

      showNotification("Success", "Service Booking Successfully Registered.");
      setShowConfirmation(true);
    } catch (error) {
      showNotification(
        "Error",
        error?.response?.data?.message ?? "Service Booking unsuccessful!"
      );
      console.log(error);
    }
  };

  // const confirmPayment = async (clientSecret) => {
  //   if (!stripe || !elements) {
  //     console.log(
  //       "Stripe.js has not initialized yet, make sure to handle this state appropriately"
  //     );
  //     return;
  //   }

  //   const result = await stripe.confirmCardPayment(clientSecret, {
  //     payment_method: {
  //       card: elements.getElement(CardElement),
  //     },
  //   });

  //   if (result.error) {
  //     console.log("Payment confirmation error:", result.error.message);
  //     setIsProcessing(false);
  //   } else {
  //     if (result.paymentIntent && result.paymentIntent.status === "succeeded") {
  //       console.log("Payment succeeded!");
  //       saveBookingData(true, result.paymentIntent);
  //       setIsPaymentDone(true);
  //       setIsProcessing(false);
  //     } else {
  //       console.log("Payment status:", result.paymentIntent.status);
  //       setIsProcessing(false);
  //     }
  //   }
  // };

  const handlePayOnline = async () => {
    // event.preventDefault();
    setIsProcessing(true);

    if (!stripe || !elements) {
      console.log(
        "Stripe.js has not initialized yet, make sure to handle this state appropriately"
      );
      return;
    }

    const cardElement = elements.getElement(CardElement);

    // Create a payment method
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        name: `${formData.firstName} ${formData.lastName}`,
        email: formData.email,
        address: {
          line1: formData.address1,
          city: formData.city,
          country: "CA",
        },
      },
    });

    if (error) {
      setErrorMessage(error.message);
      console.log(errorMessage);
    } else {
      setErrorMessage(null);
      const paymentData = {
        amount: formData.total_amount * 100,
        currency: "CAD",
        paymentMethodId: paymentMethod.id,
        description: "Car Cleaning Service",
        name: `${formData.firstName} ${formData.lastName}`,
        email: formData.email,
        address: {
          line1: formData.address1,
          city: formData.city,
          postal_code: formData.postalCode,
          state: formData.province,
          country: "CA",
        },
      };
      const response = await processStripePayment(paymentData);
      if (response.success) {
        console.log("Payment succeeded!");
        saveBookingData(true, response.paymentId);
        setIsPaymentDone(true);
        setIsProcessing(false);
      } else {
        console.log("Payment status:", response);
        setIsProcessing(false);
      }
      // confirmPayment(response["paymentIntent"]);
    }
  };

  // Handle Continue Button Click
  const handleContinue = () => {
    if (activeStep < steps.length - 1) {
      if (activeStep === 0) {
        if (Object.keys(selectedTimeSlot || {}) < 1) {
          showNotification("Error", "Please Select Time Slot");
          return;
        }
      } else if (activeStep === 1) {
        const errors = validateForm(formData);
        setErrors(errors);
        if (Object.keys(errors).length > 0) {
          return;
        }
        if (!formData?.saveAcceptTerms) {
          showNotification("Error", "Please Accept Terms & Conditions!");
          return;
        }
        // Check for Custom Tip Value Entered or not
        if (formData?.tips === "custom") {
          const cusotm_top = parseFloat(formData?.custom_tip);
          if (isNaN(cusotm_top)) {
            showNotification("Error", "Please Enter Valid Tip Amount");
            return;
          }
        }
      }
      setActiveStep((prevStep) => prevStep + 1);
      if (labelRef.current) {
        labelRef.current.scrollIntoView();
      }
    } else {
      if (formData?.paymentMethod === "pay_later") {
        saveBookingData();
      } else {
        handlePayOnline();
        console.log("Booking Successful!");
      }
    }
  };

  // Handle Back Button Click
  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prevStep) => prevStep - 1);
      if (labelRef.current) {
        labelRef.current.scrollIntoView();
      }
    }
  };

  // Component for each step
  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Grid
            item
            xs={12}
            md={12}
            sx={{ backgroundColor: "#ffffff", marginTop: "-4px" }}
          >
            {/* Car Details */}
            <Typography variant="h4" align="left" ref={labelRef}>
              Select a Cleaning Professional
            </Typography>
            <h2>{carServiceData.carMakeModel}</h2>
            <FilterHeader
              styles={styles}
              handleDateChange={handleDateChange}
              handleLocationChange={handleLocationChange}
              filters={filters}
            />
            <Divider sx={{ borderColor: "#888", opacity: 0.2 }} />

            <Box elevation={3} style={{ marginBottom: "20px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  {cleanerLoading ? (
                    <Loader />
                  ) : cleanerList.length > 0 ? (
                    <CleanerListCard
                      styles={styles}
                      formData={formData}
                      selectedCleaner={selectedCleaner}
                      setSelectedCleaner={setSelectedCleaner}
                      selectedTimeSlot={selectedTimeSlot}
                      setSelectedTimeSlot={setSelectedTimeSlot}
                      setSelectedDates={setSelectedDates}
                      selectedDates={selectedDates}
                      cleaner_list={cleanerList}
                      rapidCareBookingData={rapidCareBookingData}
                      openReviewSection={openReviewSection}
                    />
                  ) : (
                    <Grid item mt={4} xs={12} md={12}>
                      <Box elevation={3}>
                        No Cleaner Found for above search criteria
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Grid>
        );
      case 1:
        return (
          <Grid item xs={12} md={12}>
            <Typography
              variant="h4"
              ref={labelRef}
              align="left"
              sx={{ marginBottom: "0px" }}
            >
              Enter Service Details
            </Typography>
            <Typography variant="p" align="left" sx={{ marginBottom: "0px" }}>
              Put all the details to get the cleaner's foot right away to your
              door.
            </Typography>
            <h4>
              Booking Date - {selectedDates?.[selectedCleaner]}, Slot -{" "}
              {selectedTimeSlot?.time}
            </h4>
            <BookingDetails
              errors={errors}
              formData={formData}
              setFormData={setFormData}
            />
          </Grid>
        );
      case 2:
        return (
          <Grid item xs={12} md={12}>
            <Typography
              variant="h4"
              ref={labelRef}
              align="left"
              sx={{ marginBottom: "0px" }}
            >
              Summary
            </Typography>
            <h4 style={{ marginTop: "0px" }}>
              Booking Date - {selectedDates?.[selectedCleaner]}, Slot -{" "}
              {selectedTimeSlot?.time}
            </h4>
            <ConfirmationScreen
              data={formData}
              setData={setFormData}
              setIsPaymentDone={setIsPaymentDone}
              paymentInput={
                <React.Fragment>
                  {activeStep === 2 && formData?.paymentMethod !== "pay_later" && (<CardElement style={{ width: "100% !important" }} options={cardNumberElementOptions} />)}
                </React.Fragment>
              }
            />
          </Grid>
        );
      default:
        return <Typography variant="h6">Unknown Step</Typography>;
    }
  };

  return (
    <>
      {/* <form id="payment-form" onSubmit={handlePayOnline}> */}
      <ToastContainer />
      <Grid
        container={true}
        spacing={3}
        p={{ xs: "0px", sm: "0px", md: "20px" }}
      >
        {/* Left Section */}
        <Grid item xs={12} md={10}>
          {/* Scrollable content */}
          <Grid
            // elevation={2}
            spacing={4}
            sx={{
              padding: { xs: "15px", sm: "15px", md: "15px" },
              marginTop: { xs: "-8px", sm: "-8px", md: "16px" },
              height: { xs: "100vh", sm: "100vh", md: "75vh" },
              overflowY: "auto",
              borderRadius: { xs: "0", sm: "0", md: "5px" },
            }}
          >
            {renderStepContent(activeStep)}

          </Grid>

          {/* Back and Continue Buttons */}
          <Grid
            container
            justifyContent="space-between"
            xs={12}
            sm={12}
            md={9.5}
            sx={{
              marginTop: "16px",
              position: "fixed",
              bottom: 0,
              padding: "15px",
              borderTop: "1px solid #dadada",
              backgroundColor: "#ffffff",
            }}
          >
            <Grid item>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                variant="outlined"
                color="primary"
              >
                Back
              </Button>
            </Grid>
            <Grid item>
              {formData.paymentMethod === "pay_later" ? (
                <Button
                  onClick={handleContinue}
                  variant="contained"
                  color="primary"
                  sx={{ float: "right" }}
                  disabled={
                    activeStep === steps.length - 1 ? !isPaymentDone : false
                  }
                >
                  {activeStep === steps.length - 1 ? "Finish" : "Continue"}
                </Button>
              ) : (
                <Button
                  type="submit"
                  disabled={!stripe}
                  className={styles["pay-btn"]}
                  onClick={handleContinue}
                >
                  {isProcessing
                    ? "Processing..."
                    : `Pay $ ${formData.total_amount}`}
                </Button>

              )}
            </Grid>
          </Grid>
        </Grid>

        {/* Right Section: Stepper */}
        <Grid item xs={12} md={2} mt={2}>
          <Box
            sx={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "8px",
              boxShadow: 1,
              display: { xs: "none", sm: "none", md: "block" },
            }}
          >
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
        </Grid>
      </Grid>
      <BookingSuccessful
        bookingData={transformFormData(
          formData,
          formData?.user_id,
          selectedDates,
          selectedCleaner,
          selectedTimeSlot
        )}
        setShowConfirmation={setShowConfirmation}
        open={showConfirmation}
      />
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={handleClose}
        sx={{ "& .MuiDrawer-paper": { width: { md: "40%", xs: "80%" } } }} // Adjust width for responsiveness
      >
        <IconButton
          onClick={handleClose}
          sx={{ position: "absolute", top: 8, right: 8 }}
        >
          <CloseIcon />
        </IconButton>
        <Box mt={3} p={1}>
          <Reviews vendorId={vendorId} />
        </Box>
      </Drawer>
      {/* </form> */}
    </>
  );
};

export default CleanerStepper;
