import { Box, Grid, Typography } from "@mui/material";
import React from "react";

const AboutUs = ({ styles }) => {
  return (
    <Box className={styles["main-section"]}>
      <Grid container spacing={5}>
        <Grid
          item
          xs={12}
          lg={6}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Box className={styles["written-content"]}>
            <Typography className={styles["about-title"]}>
              How did{" "}
              <span
                style={{
                  color: "#016ABC",
                }}
              >
                Sayfaya
              </span>{" "}
              Start?
            </Typography>

            <Typography
              variant="body1" // Use variant="body1" for better typography consistency
              sx={{
                fontSize: { xs: "16px", md: "17px" }, // Responsive font size
                color: "black",
                textAlign: "justify",
              }}
            >
              One fine night, one of our co-founders was decked up for a
              drive-in date. As he sat in his car, he realized his car wasn't as
              decked up as him. This is when he regretted not having a clean
              car, all because he couldn't find any time to get it detailed, and
              an idea came into place.
              <br />
              <br />
              We realized the need for a convenient and affordable car cleaning
              service where you don't have to pay a great amount or set aside
              time.
              <br />
              <br />
              And so, with some brainstorming and a co-founder, Sayfaya became
              real.
              <br />
              #sayfaya
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Box className={styles["car-wash-img"]}>
            <img
              src="https://dtcarwash.wpengine.com/wp-content/uploads/2017/05/boy-washing-car.jpg?id=9798"
              alt="Car Wash"
              style={{
                width: "100%", // Make image responsive
                height: "auto", // Maintain aspect ratio
                objectFit: "cover",
                borderRadius: "10px", // Optional: Add some rounding to the corners
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AboutUs;
