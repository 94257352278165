import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Table, TableBody, TableCell, TableRow, TableHead } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Data for the services and packages
const exteriorServices = [
  { service: 'Full exterior wash', X: true, Revive: true, Black: true },
  { service: 'Tire shine', X: true, Revive: true, Black: true },
  { service: 'High-Gloss Wax', X: false, Revive: true, Black: true },
  { service: 'Car paint iron decontamination', X: false, Revive: false, Black: true },
  { service: 'Wheel iron decontamination', X: false, Revive: false, Black: true },
  { service: 'Hybrid ceramic sealant', X: false, Revive: false, Black: true }
];

const interiorServices = [
  { service: 'Thorough interior vacuum', X: true, Revive: true, Black: true },
  { service: 'Dashboard, console, and door wipe-down', X: true, Revive: true, Black: true },
  { service: 'Mirror and window cleaning', X: true, Revive: true, Black: true },
  { service: 'Door jamb cleaning', X: true, Revive: true, Black: true },
  { service: 'Dashboard, console, cup holders & doors meticulous detailing', X: false, Revive: false, Black: true }
];

const ComparePackages = () => {
  const renderRows = (services) => {
    return services.map((row, index) => (
      <TableRow key={index}>
        <TableCell>{row.service}</TableCell>
        <TableCell>{row.X ? '✔️' : '—'}</TableCell>
        <TableCell>{row.Revive ? '✔️' : '—'}</TableCell>
        <TableCell>{row.Black ? '✔️' : '—'}</TableCell>
      </TableRow>
    ));
  };

  return (
    <div>
      <Typography variant="h4" align="left" mb = {2}>Compare Packages</Typography>

      {/* Accordion for Exterior Services */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="body1">Exterior Service</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Service</TableCell>
                <TableCell>X</TableCell>
                <TableCell>Revive</TableCell>
                <TableCell>Black</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {renderRows(exteriorServices)}
            </TableBody>
          </Table>
        </AccordionDetails>
      </Accordion>

      {/* Accordion for Interior Services */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="body1">Interior Service</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Service</TableCell>
                <TableCell>X</TableCell>
                <TableCell>Revive</TableCell>
                <TableCell>Black</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {renderRows(interiorServices)}
            </TableBody>
          </Table>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default ComparePackages;
